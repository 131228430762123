import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PinkGradientButton from './PinkGradientButton';
import { useNavigate } from 'react-router-dom';

const CTAUpgrade = ({ 
  handleClose, 
  onConfirm, 
  title = "Are you sure?", 
  message = "Are you sure you want to do something?",
  buttonText = "Do something",
  imageUrl = "path_to_your_image.jpg",
  isMobile = false
}) => {
  const navigate = useNavigate()

  return (
    <Box 
      sx={{ 
        backgroundColor: '#000453',
        minHeight: isMobile ? 550 : 375,
        width: '100%',
        borderRadius: 4,
        overflow: 'hidden', // Ensure content doesn't overflow
        position: 'relative', // To position the close button
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton> */}
      <Box 
        sx={{ 
          height: isMobile ? 350 : 151,
          backgroundImage: `url(${imageUrl})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center',
        }} 
      />
      <Box 
        sx={{ 
          minHeight: 224, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'flex-start',
          textAlign: 'left',
          borderTop: '3px solid #E86E7B',
          padding: 3
        }}
      >
        <Box sx={{ color: 'white', fontSize: 28, lineHeight: '36px', mb: 2, fontWeight: 600}}>{title}</Box>
        <Box sx={{ color: 'white', mb: 2, fontSize: 20 }}>{message}</Box>
        <PinkGradientButton sx={{m:0}} onClick={() => navigate('./manage-subscription')}>
          {buttonText}
        </PinkGradientButton>
      </Box>
    </Box>
  );
};

export default CTAUpgrade;
