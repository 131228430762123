import React, { useState } from 'react';
import { Box, Typography, TextField, Divider, IconButton, MenuItem, Select, FormControl } from '@mui/material';
import EditIcon from '../../../assets/icons/Edit.png';
import CloseIcon from '../../../assets/icons/Close.png';
import PinkGradientButton from '../../UIControls/PinkGradientButton';

const SettingsUser = ({
  clinicianData,
  handleChangeInput,
  newEmail,
  setNewEmail,
  updateEmail,
  updateClinicData,
  updateClinicDataMultiuser,
  isMultiUser = false,
}) => {
  const [editorActive, setEditorActive] = useState(false);

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleSaveChanges = async () => {
    if (newEmail !== clinicianData.userData.emailAddress) {
      await updateEmail(newEmail); 
    }

    if (isMultiUser) {
      await updateClinicDataMultiuser();
    } else {
      await updateClinicData();
    }
  };

  const renderTextField = (placeholder, name, value, onChange, isLast = false, readOnly = false) => (
    <Box key={name} sx={{ marginBottom: isLast ? 0 : 2, position: 'relative' }}>
      <TextField
        name={name}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={readOnly || !editorActive ? null : onChange}
        size="small"
        fullWidth
        InputProps={{
          readOnly: readOnly || !editorActive,
          style: {
            border: 'none',
            background: editorActive && !readOnly ? '#F7F7F7' : 'transparent',
            marginBottom: isLast ? 0 : 16,
          },
          disableUnderline: true,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
          },
          input: {
            height: 56,
            color: 'rgb(33, 33, 33)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            flex: 'none',
            order: 0,
            alignSelf: 'stretch',
            flexGrow: 0,
            p: 0,
            pl: editorActive && !readOnly ? 2 : 0,
          },
        }}
      />
      {!isLast && (
        <Divider
          sx={{
            width: 'calc(100% + 48px)',
            position: 'absolute',
            bottom: 0,
            left: '-24px',
            backgroundColor: 'rgba(229, 229, 229, 1)',
            display: editorActive ? 'none' : 'block',
          }}
        />
      )}
    </Box>
  );

  const renderExperienceField = () => (
    <Box key="yearsExperience" sx={{ marginBottom: '16px', position: 'relative', borderColor: '#F7F7F7' }}>
      {editorActive ? (
       <FormControl fullWidth>
        <Select
          name="userData.yearsExperience"
          value={clinicianData.userData.yearsExperience}
          onChange={handleChangeInput}
          fullWidth
          variant="outlined"
          displayEmpty
          inputProps={{ 'aria-label': 'Please select your years of experience' }}
          renderValue={(selected) => {
            if (!selected) {
              return <div>Please select your years of experience</div>;
            }
            return selected;
          }}
          sx={{
            mb: 2,
            borderRadius: 4,
            background: '#F7F7F7',
            height: 56,
            pl: editorActive ? 2 : 0,
            '& .MuiOutlinedInput-input': {
              padding: 0,
            },
          }}
        >
          <MenuItem value="" disabled>
            Please select your years of experience
          </MenuItem>
          <MenuItem value="0-3 years">0-3 years</MenuItem>
          <MenuItem value="4-10 years">4-10 years</MenuItem>
          <MenuItem value="10+ years">10+ years</MenuItem>
        </Select>
       </FormControl>
      ) : (
        <TextField
          name="userData.yearsExperience"
          variant="outlined"
          value={clinicianData.userData.yearsExperience}
          placeholder="Years of experience"
          fullWidth
          InputProps={{
            readOnly: true,
            style: {
              border: 'none',
              background: 'transparent',
              marginBottom: 16,
            },
            disableUnderline: true,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
            input: {
              height: 56,
              color: 'rgb(33, 33, 33)',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
              flex: 'none',
              order: 0,
              alignSelf: 'stretch',
              flexGrow: 0,
              p: 0,
              pl: editorActive ? 2 : 0,
            },
          }}
        />
      )}
      <Divider
        sx={{
          width: 'calc(100% + 48px)',
          position: 'absolute',
          bottom: 0,
          left: '-24px',
          backgroundColor: 'rgba(229, 229, 229, 1)',
          display: editorActive ? 'none' : 'block',
        }}
      />
    </Box>
  );

  return (
    <Box 
      className="settings-block"
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '16px 24px',
        my: 3,
      }}
    >
      <Box sx={{ position: 'relative', mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Typography variant="h5" sx={{ fontWeight: 600, height: 48 }}>
          Personal details
        </Typography>
        <IconButton onClick={() => setEditorActive(!editorActive)} sx={{ p: 0 }}>
          {editorActive ? <img src={CloseIcon} alt="Close" /> : <img src={EditIcon} alt="Edit" />}
        </IconButton>
        <Divider
          sx={{
            width: 'calc(100% + 48px)',
            position: 'absolute',
            bottom: 0,
            left: '-24px',
            backgroundColor: 'rgba(229, 229, 229, 1)',
            mt: 2,
          }}
        />
      </Box>

      {renderTextField('First name', 'userData.firstName', clinicianData.userData.firstName, handleChangeInput)}
      {renderTextField('Last name', 'userData.lastName', clinicianData.userData.lastName, handleChangeInput)}
      {renderTextField('Job title', 'userData.jobTitle', clinicianData.userData.jobTitle, handleChangeInput)}
      {renderTextField('Email address', 'userData.emailAddress', newEmail ? newEmail : clinicianData.userData.emailAddress, handleEmailChange, false, isMultiUser)}
      {renderExperienceField()}
      {renderTextField('Mobile number', 'userData.mobile', clinicianData.userData.mobile, handleChangeInput, true)}

      {editorActive &&
        <Box display="flex" flexDirection="column" gap={2}>
          <PinkGradientButton className='black-btn' onClick={handleSaveChanges} sx={{ marginTop: 3 }}>
            Save changes
          </PinkGradientButton>
        </Box>
      }
    </Box>
  );
};

export default SettingsUser;
