import React, { useState, useRef } from 'react';
import { Box, Typography, TextField, Divider, FormControl, Select, MenuItem, Button, IconButton, Dialog, DialogTitle, DialogContent, InputLabel } from '@mui/material';
import EditIcon from '../../../assets/icons/Edit.png';
import CloseIcon from '../../../assets/icons/Close.png';
import AntSwitch from '../../UIControls/AntSwitch';
import profilePlaceholder from '../../../assets/logos/profile_placeholder.svg';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import BlueButton from '../../UIControls/BlueButton';
import { practiceOrClinicText } from '../../../config';

const SettingsClinic = ({ clinicianData, handleChangeInput, clinicImage, handleFileChange, setIsDeleteLogoDialogOpen, setClinicianData, updateClinicData, readOnly = false }) => {
  const [editorActive, setEditorActive] = useState(false);
  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  const renderTextField = (placeholder, name, value, onChange, isLast = false) => (
    <Box key={name} sx={{ marginBottom: isLast ? 0 : 2, position: 'relative' }}>
      <TextField
        name={name}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={editorActive && !readOnly ? onChange : null}
        size="small"
        fullWidth
        InputProps={{
          readOnly: !editorActive || readOnly,
          style: {
            border: 'none',
            background: editorActive && !readOnly ? '#F7F7F7' : 'transparent',
            marginBottom: 16
          },
          disableUnderline: true,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
          },
          input: {
            height: 56,
            color: 'rgb(33, 33, 33)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            flex: 'none',
            order: 0,
            alignSelf: 'stretch',
            flexGrow: 0,
            p: 0,
            pl: editorActive && !readOnly ? 2 : 0,
          },
        }}
      />
      {!isLast && (
        <Divider
          sx={{
            width: 'calc(100% + 48px)', 
            position: 'absolute',
            bottom: 0,
            left: '-24px',
            backgroundColor: 'rgba(229, 229, 229, 1)',
            display: editorActive ? 'none': 'block'
          }}
        />
      )}
    </Box>
  );

  const renderSelectField = (placeholder, name, value, onChange, options, isLast = false) => (
    <Box key={name} sx={{ marginBottom: isLast ? 0 : 2, position: 'relative' }}>
      {editorActive && !readOnly ? (
        <FormControl fullWidth>
          <Select
            name={name}
            value={value}
            onChange={onChange}
            displayEmpty
            inputProps={{ 'aria-label': placeholder }}
            sx={{
              borderRadius: 4,
              background: '#F7F7F7',
              height: 56,
              pl: editorActive ? 2 : 0,
              '& .MuiOutlinedInput-input': {
                padding: 0,
              },
              mb: 2
            }}
            InputProps={{
              readOnly: !editorActive,
              disableUnderline: true,
            }}
          >
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          name={name}
          variant="outlined"
          value={value}
          placeholder={placeholder}
          fullWidth
          InputProps={{
            readOnly: true,
            style: {
              border: 'none',
              background: 'transparent',
              marginBottom: 16,
            },
            disableUnderline: true,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
            input: {
              height: 56,
              color: 'rgb(33, 33, 33)',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px',
              flex: 'none',
              order: 0,
              alignSelf: 'stretch',
              flexGrow: 0,
              p: 0,
              pl: editorActive ? 2 : 0,
            },
          }}
        />
      )}
      {!isLast && (
        <Divider
          sx={{
            width: 'calc(100% + 48px)', 
            position: 'absolute',
            bottom: 0,
            left: '-24px',
            backgroundColor: 'rgba(229, 229, 229, 1)',
            display: editorActive ? 'none': 'block'
          }}
        />
      )}
    </Box>
  );

  return (
    <Box
      className="settings-block"
      component={"section"}
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '16px 24px',
        my: 3,
      }}
    >
      {practiceOrClinicText && (
        <>
          {readOnly && 
            <Typography sx={{background: '#000453', color: 'white', fontWeight: 600, textAlign: 'center', marginX: '-24px', mt: '-16px', mb: 2, padding: 2, borderTopLeftRadius: 16, borderTopRightRadius: 16}}>
              You're part of the {clinicianData.clinicData.clinicName} workspace
            </Typography>
          }
        
          <Box sx={{ position: 'relative', mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}> 
            <Typography variant="h5" sx={{ fontWeight: 600, height: 48 }}>
              {practiceOrClinicText[0].toUpperCase() + practiceOrClinicText.slice(1)} details
            </Typography>
            {!readOnly && (
              <IconButton onClick={() => setEditorActive(!editorActive)} sx={{ p: 0 }}>
                {editorActive ? <img src={CloseIcon} alt='Close' /> : <img src={EditIcon} alt='Edit' />}
              </IconButton>
            )}
            <Divider
              sx={{
                width: 'calc(100% + 48px)', 
                position: 'absolute',
                bottom: 0,
                left: '-24px',
                backgroundColor: 'rgba(229, 229, 229, 1)',
                mt: 2,
              }}
            />
          </Box>
  
          {renderTextField(`${practiceOrClinicText[0].toUpperCase() + practiceOrClinicText.slice(1)} name`, 'clinicData.clinicName', clinicianData.clinicData.clinicName, handleChangeInput)}
          {renderTextField(`${practiceOrClinicText[0].toUpperCase() + practiceOrClinicText.slice(1)} phone number`, 'clinicData.clinicNumber', clinicianData.clinicData.clinicNumber, (e) => {
            const newValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            handleChangeInput({ target: { name: 'clinicData.clinicNumber', value: newValue } });
          })}
  
          {practiceOrClinicText === 'practice' && renderSelectField(
            'Type of animals your practice treats',
            'clinicData.clinicType',
            clinicianData.clinicData.clinicType || '',
            handleChangeInput,
            [
              { value: 'Small animals', label: 'Small animals' },
              { value: 'Large animals', label: 'Large animals' },
              { value: 'Equine', label: 'Equine' },
              { value: 'All animals', label: 'All animals' },
            ]
          )}
  
          {renderSelectField(
            `${practiceOrClinicText[0].toUpperCase() + practiceOrClinicText.slice(1)} size`,
            'clinicData.clinicSize',
            clinicianData.clinicData.clinicSize || '',
            handleChangeInput,
            [
              { value: '0-5', label: '0-5 staff' },
              { value: '6-10', label: '6-10 staff' },
              { value: '11-20', label: '11-20 staff' },
              { value: '20+', label: '20+ staff' },
            ],
            true
          )}
  
          <Box>
            {clinicianData.subscriptionData.accountType.includes("Clinic") && (
              <>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>{practiceOrClinicText[0].toUpperCase() + practiceOrClinicText.slice(1)} logo</Typography>
                <Box display="flex" flexDirection='column'>
                  <Typography variant="caption" sx={{ fontSize: 16, maxWidth: 560, marginBottom: 2 }}>
                    Upload your {practiceOrClinicText} logo to personalise every handout with your branding. Please ensure the
                    image dimensions do not exceed 700x700 pixels.
                  </Typography>
                  {clinicImage ? (
                    <img src={clinicImage} alt={`${practiceOrClinicText} logo`} style={{ width: '115px' }} />
                  ) : (
                    <img src={profilePlaceholder} alt='Logo Placeholder' style={{ width: '115px' }} />
                  )}
  
                  {editorActive && !readOnly && (
                    <Typography
                      variant="caption"
                      sx={{ textDecoration: 'underline', cursor: 'pointer', mt: 1, color: '#000453', fontWeight: 600 }}
                      onClick={() => setLogoModalOpen(true)}
                    >
                      Manage logo
                    </Typography>
                  )}
                </Box>
  
                <Dialog open={logoModalOpen} onClose={() => setLogoModalOpen(false)} fullWidth maxWidth="sm" PaperProps={{
                  sx: {
                    height: 300,
                    width: 252,
                    borderRadius: 3,
                    overflow: 'hidden',
                    position: 'relative'
                  },
                }}>
                  <DialogTitle sx={{ textAlign: 'center', p: 0, pt: 3, fontWeight: 600 }}>Manage {practiceOrClinicText} logo</DialogTitle>
                  <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box display="flex" flexDirection='column' justifyContent="space-between" alignItems="flex-start" sx={{ mt: 2 }}>
                      <input
                        id="file"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none', width: 188 }}
                        ref={fileInputRef}
                      />
                      <InputLabel htmlFor="file" onClick={() => fileInputRef.current.click()}>
                        <PinkGradientButton sx={{ width: 188, mb: 0, fontWeight: 600 }}>
                          Update logo
                        </PinkGradientButton>
                      </InputLabel>
  
                      <BlueButton sx={{ width: 188, mt: 2, fontWeight: 600 }} onClick={() => setIsDeleteLogoDialogOpen(true)}>
                        Delete logo
                      </BlueButton>
                    </Box>
                    <Button onClick={() => setLogoModalOpen(false)} sx={{ color: '#000453', mt: 2, textDecoration: 'underline' }}>Cancel</Button>
                  </DialogContent>
                </Dialog>
  
                {!readOnly && (
                  <Typography variant="subtitle2" sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row', sm: 'row' },
                    alignItems: { xs: 'center', sm: 'center' },
                    justifyContent: 'space-between',
                    margin: '8px 0',
                    cursor: 'pointer',
                  }}>
                    {editorActive && 
                      <>
                      <Box>Show logo on all the handouts?</Box>
                      <AntSwitch
                        checked={clinicianData?.showLogoInHandouts || false}
                        onChange={(event) => {
                          setClinicianData({
                            ...clinicianData,
                            showLogoInHandouts: event.target.checked,
                          });
                        }}/>
                      </>
                    }
                  </Typography>
                )}
              </>
            )}
  
            {editorActive && !readOnly && (
              <PinkGradientButton className='black-btn' onClick={updateClinicData}>
                Save changes
              </PinkGradientButton>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SettingsClinic;
