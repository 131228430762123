import { Auth } from 'aws-amplify';
import { makeAutoObservable, runInAction } from 'mobx';

export const AuthState = Object.freeze({
  UNINITIALIZED: 'uninitialized',
  UNVERIFIED: 'unverified',
  AUTHENTICATED: 'authenticated',
  UNAUTHENTICATED: 'unauthenticated',
  PENDING_SUBSCRIPTION: 'pending_subscription',
  CANCELLED_SUBSCRIPTION: 'cancelled_subscription'
});

// There are 3 initialisation cases to handle
// 1. User is already authenticated when entering
// 2. User signs in successfully
// 3. User confirms code and signs in successfully
// In all cases, the asyncAppInitializer is called with the Cognito User ID
// The asyncAppInitializer is a function that takes the Cognito User ID as a parameter and returns a promise
// The asyncAppInitializer is called before the AuthStore is updated with the authenticated state
// This way we don't render any components that depend on the authenticated state until the asyncAppInitializer has completed

class AuthStore {
  idToken = '';
  email = '';
  tempPassword = '';
  authState = AuthState.UNINITIALIZED;

  constructor(appStateStore, asyncAppInitializer) {
    console.log('AuthStore constructor');
    makeAutoObservable(this);
    this.appStateStore = appStateStore;
    this.initAuth(asyncAppInitializer);
  }

  setTempPassword(password) {
    this.tempPassword = password;
  }

  async refreshToken() {
    console.log('Refreshing token');
    try {
      const session = await Auth.currentSession();
      if (session && session.isValid()) {
        const tokenExpiryDate = new Date(session.getIdToken().payload.exp * 1000);
        console.log('Token will expire at:', tokenExpiryDate.toLocaleString());
        const refreshInterval = this.calculateRefreshInterval(session);
        console.log(`Scheduling next token refresh at:`, new Date(Date.now() + refreshInterval).toLocaleString());

        runInAction(() => {
          this.idToken = session.getIdToken().getJwtToken();
        });

        // Clear any existing interval and set a new one
        if (this.tokenRefreshInterval) clearInterval(this.tokenRefreshInterval);
        this.tokenRefreshInterval = setInterval(() => {
          this.refreshToken();
        }, refreshInterval);
      } else {
        console.log('Token is not valid or expired. You should handle this case.');
      }
    } catch (error) {
      console.error('An error occurred during token refresh:', error);
    }
  }

  calculateRefreshInterval(session) {
    const expiryTime = session.getIdToken().payload.exp * 1000; // Token expiry time in ms
    const currentTime = Date.now();
    const offsetTime = 5 * 60 * 1000; // Offset time of 5 minutes in ms
    let refreshInterval = expiryTime - currentTime - offsetTime;

    // Ensure that we always have at least a 1-minute buffer, regardless of the token's expiry time
    const minimumBuffer = 1 * 60 * 1000; // 1 minute in ms
    if (refreshInterval < minimumBuffer) {
      refreshInterval = minimumBuffer;
    }

    return refreshInterval > 0 ? refreshInterval : 0;
  }

  startTokenRefreshInterval() {
    console.log('startTokenRefreshInterval');
    this.refreshToken(); // Initial call to refresh the token and set up the interval
  }

  async initAuth(asyncAppInitializer) {
    try {
      console.log('initAuth');
      const session = await Auth.currentSession();
      if (session && session.isValid()) {
        console.log('session found and isValid');
        const cognitoUserId = session.idToken.payload.sub;
        console.log(`Cognito User ID: ${cognitoUserId}`);
        const idToken = session.getIdToken().getJwtToken();
        await asyncAppInitializer(cognitoUserId, idToken);
        
        await this.checkAndSetAuthState(session.idToken.payload.email, idToken);

        this.startTokenRefreshInterval();
      } else {
        runInAction(() => {
          this.authState = AuthState.UNAUTHENTICATED;
        });
      }
    } catch (error) {
      console.error('An error occurred while initializing auth:', error);
      runInAction(() => {
        this.authState = AuthState.UNAUTHENTICATED;
      });
    }
  }

  async hasActiveSubscription() {
    // Retrieve user data from the existing AppStateStore instance and log it
    const userData = await this.appStateStore.getUserDataStoreData();
    console.warn('User data retrieved:', userData);

    const { accountType, trialActive, trialEndDate, subscriptionEndDate, cancelled } = userData.subscriptionData;
    console.warn('User data sub:', userData.subscriptionData);

    const currentTime = Date.now();

    if (accountType === 'Clinic-multiuser' && subscriptionEndDate > currentTime) {
      return true;
    }

    // Check if any of the subscription fields are undefined or null
    if (accountType === undefined || trialActive === undefined || trialEndDate === undefined || subscriptionEndDate === undefined) {
        console.error('Subscription data is incomplete or malformed:', userData.subscriptionData);
        return false; // Treat incomplete or malformed data as no active subscription
    }

    // Log values for debugging
    console.warn('Current time:', currentTime);
    console.warn('Trial end date:', trialEndDate);
    console.warn('Subscription end date:', subscriptionEndDate);

    // Check validity of the dates
    if (isNaN(trialEndDate) || isNaN(subscriptionEndDate)) {
        console.error('Invalid date format in subscription data:', { trialEndDate, subscriptionEndDate });
        return false; // Treat invalid date formats as no active subscription
    }

    // Determine if the user has an active trial or subscription
    if (trialActive === true && trialEndDate > currentTime) {
        return true; // User is on trial
    } else if (accountType && subscriptionEndDate > currentTime) {
        return true; // User has an active subscription
    }

    return false; // User has no active trial or subscription
  }

  async checkAndSetAuthState(email, token) {
    const activeSubscription = await this.hasActiveSubscription();
    const userData = await this.appStateStore.getUserDataStoreData();
    const { cancelled, subscriptionEndDate } = userData.subscriptionData;
    const currentTime = Date.now();

    // Check if the subscription cancelled and expired
    if (cancelled && (currentTime > subscriptionEndDate)) {
      this.setCancelledSubscriptionState(email, token);
    } else if (activeSubscription) {
      this.setAuthenticatedState(email, token);
    } else {
      this.setPendingSubscriptionState(email, token);
    }
  }
  
  // We want to lower case to prevent user mistakes
  async signUp(email, password) {
    const lowercaseEmail = email.toLowerCase();
    console.log(`signUp email: ${lowercaseEmail}`);
    try {
      const data = await Auth.signUp({ username: lowercaseEmail, password });
      console.log('User name is:', data.user.getUsername());
      console.log('Sign-up result:', JSON.stringify(data));
      runInAction(() => {
        this.email = lowercaseEmail;
        this.tempPassword = password;
        this.authState = AuthState.UNVERIFIED;
      });
      return { success: true };
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.authState = AuthState.UNAUTHENTICATED;
      });
      throw (err);
    }
  }

  setAuthenticatedState(email, token) {
    runInAction(() => {
      console.log(`setAuthenticatedState email: ${email}`);
      this.tempPassword = '';
      this.email = email;
      this.idToken = token;
      this.authState = AuthState.AUTHENTICATED;
    });
  }

  setPendingSubscriptionState(email, token) {
    runInAction(() => {
      console.log(`setPendingSubscription email: ${email}`);
      this.tempPassword = '';
      this.email = email;
      this.idToken = token;
      this.authState = AuthState.PENDING_SUBSCRIPTION;
    });
  }

  setCancelledSubscriptionState(email, token) {
    runInAction(() => {
      console.log(`setCancelledSubscription email: ${email}`);
      this.tempPassword = '';
      this.email = email;
      this.idToken = token;
      this.authState = AuthState.CANCELLED_SUBSCRIPTION;
    });
  }

  async signInUser(email, password, asyncAppInitializer) {
    try {
      const lowercaseEmail = email.toLowerCase();
      const user = await Auth.signIn(lowercaseEmail, password);
      console.log('aws sign in successful', user);
  
      const token = user.signInUserSession.getIdToken().getJwtToken();
      const userId = user.attributes.sub; 
  
      // Wait for the asyncAppInitializer to complete before setting authenticated state
      if (asyncAppInitializer && typeof asyncAppInitializer === 'function') {
        await asyncAppInitializer(userId, token);
      }
  
      const activeSubscription = await this.hasActiveSubscription();
      const userData = await this.appStateStore.getUserDataStoreData();
      const { cancelled, subscriptionEndDate } = userData.subscriptionData;
      const currentTime = Date.now();
  
      if (cancelled && (currentTime > subscriptionEndDate)) {
        this.setCancelledSubscriptionState(lowercaseEmail, token);
      } else if (activeSubscription) {
        this.setAuthenticatedState(lowercaseEmail, token);
      } else {
        this.setPendingSubscriptionState(lowercaseEmail, token);
      }
  
      return { success: true, token };
    } catch (err) {
      console.log('authentication failed', err);
      return { success: false, message: err.message };
    }
  }
  
  async doConfirmRegistration(email, code) {
    try {
      const lowercaseEmail = email.toLowerCase();
      const result = await Auth.confirmSignUp(lowercaseEmail, code);
      console.log('Verification result:', result);
      localStorage.removeItem('userData');
    } catch (err) {
      console.error(err);

      // Check if the error message is about the user already being confirmed
      if (err.message === "User cannot be confirmed. Current status is CONFIRMED") {
        console.log('User already confirmed, proceeding to authenticate.');
      } else {
        throw (err);
      }
    }
  }

  async confirmRegistration(email, password, code, asyncAppInitializer) {
    try {
      if (!email || email.length === 0) {
        console.error("Email is empty");
        throw new Error("Sorry, we are unable to process your request at this time. Please contact us at hello@cetrahealth.com for further assistance.");
      }
      if (!password || password.length === 0) {
        console.error("Temporary password is empty");
        throw new Error("Sorry, we are unable to process your request at this time. Please contact us at hello@cetrahealth.com for further assistance.");
      }

      const lowercaseEmail = email.toLowerCase();
      await this.doConfirmRegistration(lowercaseEmail, code);
      return await this.signInUser(lowercaseEmail, password, asyncAppInitializer);
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this.authState = AuthState.UNVERIFIED;
      });
      throw (err);
    }
  }

  setIdToken(token) {
    runInAction(() => {
      this.idToken = token;
      this.authState = AuthState.AUTHENTICATED;
    });
  }

  async logout() {
    try {
      await Auth.signOut();
      runInAction(() => {
        this.idToken = '';
        this.authState = AuthState.UNAUTHENTICATED;
      });
    } catch (err) {
      console.error('error signing out:', err);
    }
  }

  async resendVerificationCode(email) {
    try {
      const lowercaseEmail = email.toLowerCase();
      console.log(`Resending verification code to: ${lowercaseEmail}`);
      await Auth.resendSignUp(lowercaseEmail);
      console.log(`Verification code resent to ${lowercaseEmail}`);
      return { success: true };
    } catch (err) {
      console.error('Error in resendVerificationCode:', err);
      throw err;
    }
  }

}

const createAuthStore = (appStateStore, asyncAppInitializer) => new AuthStore(appStateStore, asyncAppInitializer);
export default createAuthStore;
