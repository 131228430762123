export const inputStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: '#F9F9F9', 
      '& fieldset': {
        border: 'none',
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '16px',
      border: 0,
      color: "black"
    },
    marginBottom: '16px'
  };
  