import React from 'react';
import { Box, Typography, Divider, List, ListItem, useMediaQuery } from '@mui/material';
import subscriptionPlans from '../authenticated/settings/subscriptionPlans';
import PinkGradientButton from './PinkGradientButton';
import CheckListTickIcon from '../../../src/assets/icons/ChecklistTick.svg';

const SubscriptionCardVertical = ({ plan, discount = 73, sx }) => {

  const planDetails = subscriptionPlans[plan];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (!planDetails) {
    return <Typography variant="body1">Invalid plan selected.</Typography>;
  }

  const { priceMonthly, priceAnnually, benefits } = planDetails;

  const discountedPrice = (price, discount) => (price * (1 - discount / 100)).toFixed(2);

  return (
    <Box
      sx={{
        borderRadius: '20px',
        background: 'white',
        padding: '16px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'left',
        position: 'relative',
        width: '280px',
        ...sx
      }}
    >
      <PinkGradientButton
        sx={{
          position: 'absolute',
          top: '10px',
          right: '16px',
          borderRadius: '15px',
          fontSize: '12px!important',
          color: 'white',
          height: '20px',
          width: '76px',
          display: 'flex',
          alignItems: 'center',
          padding: '6px 0px',
          cursor: 'initial'
        }}
      >
        SAVE 70%
      </PinkGradientButton>

      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#000453', marginBottom: '16px' }}>
        {plan}
      </Typography>

      <Box sx={{ marginBottom: '16px' }}>
        <Box variant="body2" sx={{ textDecoration: 'line-through', color: '#000453', fontSize: '36px', fontWeight: 600 }}>
          £{priceAnnually.toFixed(2).toLocaleString()}
        </Box>
        <Box sx={{ marginBottom: '16px' }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#ff4081', display: 'inline-block' }}>
            £{discountedPrice(priceAnnually, discount)}
          </Typography>
          <Typography variant="body2" sx={{ color: '#000453', display: 'inline-block', marginLeft: '4px' }}>
            /year (inc VAT)
          </Typography>
        </Box>

      </Box>

      <Divider sx={{ marginY: '16px' }} />

      <List sx={{ padding: 0 }}>
        {benefits.map((benefit, index) => (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', padding: 0, marginBottom: '12px' }}>
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                marginRight: '0px',
              }}
            />
             <img 
              src={CheckListTickIcon} 
              alt="Checkmark" 
              style={{ width: 20, height: 20, marginRight: 8 }} 
            />
            <Typography variant="body2" sx={{color: '#000453'}}>{benefit}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SubscriptionCardVertical;
