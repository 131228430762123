import React, { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import mixpanel from 'mixpanel-browser';
import { HomeScreen } from './home/home';
import { SearchScreen } from './search/search';
import { CategoriesSearchScreen } from './categories/categories';
import { CategoriesResults } from './categories/categories-results';
import HandoutViewer from './handout/handout-viewer';
import HandoutPDF from './handout/pdf/handout-pdf';
import { SettingsScreen } from './settings/settings';
import { SettingsMultiUserScreen } from './settings/settings-multiuser';
import { SettingsDevScreen } from './settings/settings-dev';
import VerificationCodeInput from '../unauthenticated/onboard/verification-code-input';
import AuthorsAndReferences from './handout/authors-and-references';
import FeedbackScreen from './feedback';
import HandoutLayout from './handout/handout-context-layout';
import StoreContext from '../../react-core-lib/components/app/store-context';
import { UnsavedChangesProvider } from './UnsavedChangesContext';
import Handouts from './settings/handouts';
import Checkout from '../checkout/checkout';
import { SnackbarProvider } from './SnackbarProvider';
import Navigation from './Navigation';
import ManageSubscriptionWrapper from './settings/manage-subscription';
import Models3DScreen from './models3D/models-3D-screen';

const AuthenticatedApp = observer(() => {
    const { userDataStore } = useContext(StoreContext);
    const location = useLocation();

    useEffect(() => {
        const fetchUserAndTrackPageView = async () => {
            try {
                mixpanel.identify(userDataStore.userData.emailAddress);

                mixpanel.people.set({
                    $first_name: userDataStore.userData.firstName,
                    $last_name: userDataStore.userData.lastName,
                    $email: userDataStore.userData.emailAddress,
                    Mobile: userDataStore.userData.mobile,
                    'Clinic Name': userDataStore.userData.clinicName,
                    'Clinic Number': userDataStore.userData.clinicNumber,
                    'Clinic Size': userDataStore.userData.clinicSize,
                });
            } catch (error) {
                console.error('Error fetching current user for Mixpanel:', error);
            }
        };

        fetchUserAndTrackPageView();
    }, [location]);

    return (
        <SnackbarProvider>
            <UnsavedChangesProvider>
                <main id="main-content" style={{ zoom: document.documentElement.style.zoom || 1 }}>
                    <Navigation />
                    <Routes>
                        <Route path="/" element={<HomeScreen />} />
                        <Route path="/search" element={<SearchScreen />} />
                        <Route path="/search/categories" element={<CategoriesSearchScreen />} />
                        <Route path="/search/diagrams" element={<SearchScreen />} />
                        <Route path="/search/3dmodels" element={<SearchScreen />} />
                        <Route path="/categories-results" element={<CategoriesResults />} />
                        <Route path="/settings" element={<SettingsScreen />} />
                        <Route path="/settings-multiuser" element={<SettingsMultiUserScreen />} />
                        <Route path="/settings/manage-subscription" element={<ManageSubscriptionWrapper />} />
                        <Route path="/settings/dev" element={<SettingsDevScreen />} />
                        <Route path="/feedback" element={<FeedbackScreen />} />
                        <Route path="/handout/:id" element={<HandoutLayout />}>
                            <Route index element={<HandoutViewer />} />
                            <Route path="pdf" element={<HandoutPDF />} />
                            <Route path="authors-and-references" element={<AuthorsAndReferences />} />
                        </Route>
                        <Route path="/code-input" element={<VerificationCodeInput />} />
                        <Route path="/handouts" element={<Handouts />} />
                        <Route path="/checkout" element={<Checkout />} />
                        <Route path="/3d-models" element={<Models3DScreen />} />
                    </Routes>
                </main>
            </UnsavedChangesProvider>
        </SnackbarProvider>
    );
});

export default AuthenticatedApp;
