import React from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import bodyAreaIcon from '../../assets/icons/categories/bodyArea.svg';
import chronicConditionsIcon from '../../assets/icons/categories/chronicConditions.svg'; 
import generalAnatomy from '../../assets/icons/categories/generalAnatomy.svg';
import generalAnatomyAnimal from '../../assets/icons/categories/generalAnatomyAnimal.svg';
import generalPrinciples from '../../assets/icons/categories/generalPrinciples.svg';
import generalPrinciplesAnimal from '../../assets/icons/categories/generalPrinciplesAnimal.svg';
import neurologyIcon from '../../assets/icons/categories/neurology.svg';
import mensHealthIcon from '../../assets/icons/categories/mensHealth.svg';
import womensHealthIcon from '../../assets/icons/categories/womensHealth.svg';
import behaviourIcon from '../../assets/icons/categories/behaviour.svg';
import emergencyIcon from '../../assets/icons/categories/emergency.svg';
import farmIcon from '../../assets/icons/categories/farm.svg';
import musculoskeletalIcon from '../../assets/icons/categories/musculoskeletal.svg';
import medicalConditionsIcon from '../../assets/icons/categories/medicalConditions.svg';
import preventativeIcon from '../../assets/icons/categories/preventative.svg';
import parasitesIcon from '../../assets/icons/categories/parasites.svg';
import reproductionIcon from '../../assets/icons/categories/reproduction.svg';
import surgeryIcon from '../../assets/icons/categories/surgery.svg';
import animalIcon from '../../assets/icons/categories/animal.svg';
import denistryIcon from '../../assets/icons/categories/denistry.svg';
import dermatologyIcon from '../../assets/icons/categories/dermatology.svg';
import orthopaedicIcon from '../../assets/icons/categories/orthopaedic.svg';
import ophthalmologyIcon from '../../assets/icons/categories/ophthalmology.svg';
import internalMedicineIcon from '../../assets/icons/categories/internalMedicine.svg';
import pulmonaryAndCardioIcon from '../../assets/icons/categories/pulmonaryAndCardio.svg';
import paediatricsIcon from '../../assets/icons/categories/paediatrics.svg';
import rheumatologyIcon from '../../assets/icons/categories/rheumatology.svg';
import seriousPathologiesIcon from '../../assets/icons/categories/seriousPathologies.svg';

import birdIcon from '../../assets/icons/categories/bird.svg';
import canineIcon from '../../assets/icons/categories/canine.svg';
import chickenIcon from '../../assets/icons/categories/chicken.svg';
import felineIcon from '../../assets/icons/categories/feline.svg';
import fishIcon from '../../assets/icons/categories/fish.svg';

import equineIcon from '../../assets/icons/categories/equine.svg';
import guineaPigIcon from '../../assets/icons/categories/guineaPig.svg';
import hamsterIcon from '../../assets/icons/categories/hamster.svg';
import rabbitIcon from '../../assets/icons/categories/rabbit.svg';
import smallAnimalsIcon from '../../assets/icons/categories/smallAnimals.svg';
import smallMammalsIcon from '../../assets/icons/categories/smallMammals.svg';

import largeAnimalsIcon from '../../assets/icons/categories/largeAnimals.svg';
import exoticAnimalsIcon from '../../assets/icons/categories/exoticAnimals.svg';
import { useNavigate } from 'react-router-dom';

const categoryIconMap = {
    Anatomy: process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalAnatomyAnimal : generalAnatomy,
    Animal: animalIcon,
    Behaviour: behaviourIcon,
    Bird: birdIcon,
    'Body Area': bodyAreaIcon,
    Canine: canineIcon,
    Cardiorespiratory: pulmonaryAndCardioIcon,
    Chicken: chickenIcon,
    'Chronic conditions': chronicConditionsIcon,
    Dentistry: denistryIcon,
    Dermatology: dermatologyIcon,
    Emergency: emergencyIcon,
    Equine: equineIcon,
    'Exotic Animals': exoticAnimalsIcon,
    Farm: farmIcon,
    Feline: felineIcon,
    Fish: fishIcon,
    'General principles':
        process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalPrinciplesAnimal : generalPrinciples,
    'Guinea pig': guineaPigIcon,
    Hamster: hamsterIcon,
    'Internal medicine': internalMedicineIcon,
    'Large Animals': largeAnimalsIcon,
    'Medical conditions': medicalConditionsIcon,
    "Men's health": mensHealthIcon,
    Musculoskeletal: musculoskeletalIcon,
    Neurology: neurologyIcon,
    Ophthalmology: ophthalmologyIcon,
    Orthopaedics: orthopaedicIcon,
    Paediatrics: paediatricsIcon,
    Parasites: parasitesIcon,
    Preventative: preventativeIcon,
    Rabbit: rabbitIcon,
    Reproduction: reproductionIcon,
    Rheumatology: rheumatologyIcon,
    'Serious pathologies': seriousPathologiesIcon,
    'Small Animals': smallAnimalsIcon,
    'Small mammals': smallMammalsIcon,
    Surgery: surgeryIcon,
    "Women's health": womensHealthIcon,
};
const CategoryIcon = observer(({ category }) => {
    const navigate = useNavigate();

    const getCategoryIcon = (category) => {
        return (
            categoryIconMap[category] || (process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? animalIcon : bodyAreaIcon)
        );
    };

    const handleCategoryClick = () => {
        if (category === 'Animal' || category === 'Body Area') {
            navigate('/search/categories');
        } else {
            navigate('/search', { state: { category } });
        }
    };

    return (
        <Box
            onClick={handleCategoryClick}
            sx={{
                width: '40px',
                height: '40px',
                gap: '8px',
                borderRadius: '36px',
                opacity: '0px',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 1,
                cursor: 'pointer',
            }}
        >
            <img src={getCategoryIcon(category)} alt={`${category} Category Icon`} width="24px" height="24px" />
        </Box>
    );
});

export default CategoryIcon;
