import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Button, TextField, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ApiButton from '../UIControls/api-button/api-button';
import { useSendFeedback } from './handout/use-send-feedback';
import { feedbackMessage } from '../../config';
const topics = ['Illustration', 'Text', 'Idea', 'Technical Issue', 'Other'];

const FeedbackScreen = () => {
  const navigate = useNavigate();
  const sendFeedback = useSendFeedback();
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleBack = () => {
    navigate(-1);
  };

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
  };

  const submitFeedback = async () => {
    // Replace this with your actual API call logic
    // console.log('Submitting feedback:', { selectedTopic, message });
    // // Simulate API call delay
    // return new Promise((resolve) =>
    //   setTimeout(() => resolve({ success: true }), 1000)
    // );
    console.log(`Submitting feedback: topic: ${selectedTopic}, message: ${message}`);
    await sendFeedback(selectedTopic, message);
  };

  const resetAndNavigateBack = () => {
    console.log('resetAndNavigateBack');
    setSelectedTopic('');
    setMessage('');
    navigate(-1);
  }

  return (
    <Container component={'section'} maxWidth="sm" sx={{ width: '100%', overflowY: 'auto', backgroundColor: 'white', p: 4, my: 8 }} className='feedback-container'>
      <Box display="flex" alignItems="center">
        <IconButton onClick={handleBack} aria-label="Go back">
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Typography variant="h5" mt={2} mb={2}>
        We appreciate your feedback!
      </Typography>
      <Typography>
        {feedbackMessage}     
      </Typography>

      <Typography variant="h6" mt={4} mb={2}>
        Topic
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1} mb={4}>
        {topics.map((topic) => (
          <Button
            key={topic}
            variant={selectedTopic === topic ? 'contained' : 'outlined'}
            onClick={() => handleTopicSelect(topic)}
          >
            {topic}
          </Button>
        ))}
      </Box>

      <Typography variant="h6" mt={2} mb={0}>
        Message
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={6}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Enter your feedback..."
        margin="normal"
      />

      <ApiButton
        apiCall={submitFeedback}
        buttonLabel="Submit feedback"
        fullWidth={true}
        color='primary'
        errorTitle="Feedback error"
        errorMessage="There was a problem submitting your feedback. Please try again."
        successTitle="Feedback submitted"
        successMessage="Your feedback has been submitted successfully."
        disabled={selectedTopic === null || message.trim() === ''}
        sx={{ mt: 4 }}
        onSuccessConfirm={resetAndNavigateBack}
      />
    </Container>
  );
};

export default FeedbackScreen;
