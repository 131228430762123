import React, { useContext, useState, useEffect, useRef } from 'react';
import { Box, IconButton, InputBase, InputAdornment, Typography, useTheme, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CenteredBox } from '../../UIControls/centered-box';
import { Auth } from 'aws-amplify';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import mixpanel from 'mixpanel-browser';
import { SearchResults } from '../search/search-results';
import NotificationBar from '../../UIControls/NotificationBar';
import { SearchFiltersVet } from '../search/search-filters-vet';

export const SearchHome = observer(() => {
  const theme = useTheme();
  const { contentStore, searchStore, userDataStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchStore.rawSearchTerm);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const inputRef = useRef(null);
  const [categories, setCategories] = useState({});
  const [subscriptionExpiryTime, setSubscriptionExpiryTime] = useState(null);
  const [activeFilter, setActiveFilter] = useState('');
  const [touchStartY, setTouchStartY] = useState(0);
  const [isHoldingFilter, setIsHoldingFilter] = useState(false); 
  let holdTimeout = null;

  useEffect(() => {
    if (contentStore.isInitialized) {
      const filteredData = Object.fromEntries(
        Object.entries(contentStore.getCategoriesData()).filter(([key, _]) => key !== 'Body Area' && key !== 'Animal')
      );
      setCategories(filteredData);
    }
  }, [contentStore]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchStore.rawSearchTerm);
      if (searchStore.rawSearchTerm.trim()) {
        searchStore.setSearchTerm(searchStore.rawSearchTerm.trim());
        const results = contentStore.searchContentMeta(searchStore.rawSearchTerm.trim());
        searchStore.setSearchResults(results);
        setDropdownVisible(true);
      } else {
        searchStore.setSearchTerm('');
        searchStore.setSearchResults([]);
        setDropdownVisible(false); 
      }
    }, 200);

    return () => clearTimeout(timerId);
  }, [searchStore.rawSearchTerm, contentStore]);

  useEffect(() => {
    const subscriptionEndDate = userDataStore?.subscriptionData?.subscriptionEndDate;

    console.warn(subscriptionEndDate)

    if (subscriptionEndDate) {
      const currentTime = Date.now();
      const endTimeInMilliseconds = new Date(subscriptionEndDate).getTime();
      const differenceInMilliseconds = endTimeInMilliseconds - currentTime;
      const millisecondsInOneDay = 24 * 60 * 60 * 1000;
      const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;
      setSubscriptionExpiryTime(Math.max(0, Math.floor(differenceInDays)));
    } else {
      setSubscriptionExpiryTime(null);
    }
  }, [userDataStore]);

  const handleTrackSearchAndBlur = async () => {
    console.warn('Home page searched for: ', debouncedSearchTerm);
    if (debouncedSearchTerm.length >= 3) {
      const currentUser = await Auth.currentAuthenticatedUser();
      mixpanel.track('Search', {
        email: currentUser.attributes.email,
        query: debouncedSearchTerm
      });
    }
    setTimeout(() => {
      if (!inputRef.current?.contains(document.activeElement)) {
        setDropdownVisible(false);
      }
    }, 200);
  };

  const handleFocus = () => {
    if (searchStore.rawSearchTerm.trim() && searchStore.searchResults.length > 0) {
      setDropdownVisible(true);
    }
  };

  const handleSearchInputChange = (value) => {
    console.log('SearchHome::onSearchChange', value);
    searchStore.setRawSearchTerm(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    navigate('/search');
  };

  const handleUpgradeClick = () => {
    navigate('/settings/manage-subscription');
  };

  const handleFilterChange = (filterType) => {
    console.warn(filterType);
    setActiveFilter(filterType);
    navigate('/search', { state: { filter: filterType } });
  };

  const handleTouchStart = (e, filterType) => {
    setTouchStartY(e.touches[0].clientY);
    setIsHoldingFilter(true);
  
    // Set a timeout to navigate if the touch holds for 500ms
    holdTimeout = setTimeout(() => {
      if (isHoldingFilter) {
        handleFilterChange(filterType); // Navigate if it's a confirmed hold
      }
    }, 500);
  };
  
  const handleTouchEnd = () => {
    clearTimeout(holdTimeout); // Clear timeout on touch end
    setIsHoldingFilter(false);
  };
  
  const handleTouchMove = (e) => {
    const touchEndY = e.touches[0].clientY;
    if (Math.abs(touchStartY - touchEndY) > 10) {
      clearTimeout(holdTimeout); // Cancel if movement is detected
      setIsHoldingFilter(false);
    }
  };

  return (
    <> 
      <Box
        display="flex"
        alignItems="center"
        justifyItems="center"
        flexDirection="column"
        paddingY={isMobile ? '48px' : '40px'}
        paddingBottom={'40px'}
        sx={{
          width: '100%',
          borderRadius: '4px',
          background: 'linear-gradient(132deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)',
          height: isMobile ? "initial": "450px"
        }}
        className="search-banner"
      >
      {
          subscriptionExpiryTime <= 7 && userDataStore?.subscriptionData.canelled && 
          <NotificationBar 
            message={`Your ${userDataStore?.subscriptionData?.trialActive ? "free trial" : userDataStore?.subscriptionData?.accountType + " plan" } is ending in ${subscriptionExpiryTime} days`}
            buttonLabel={userDataStore?.subscriptionData?.trialActive ? "Upgrade now" : "Renew subscription"}
            onButtonClick={handleUpgradeClick}
          />
        }
        <CenteredBox sx={{margin:"auto"}}>
          <Typography variant="h3" textAlign="center" color="white">
            What are you looking for?
          </Typography>
          <Typography variant="h6" textAlign="center" color="white" mb={'-16px'}>
            Search by {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'condition or animal' : 'medical condition or body part'}  
          </Typography>

          <Box display="flex" alignItems="center" width="100%" position={'relative'}
            sx={{
              backgroundColor: searchStore.rawSearchTerm ? 'white' : 'transparent',
              borderRadius: searchStore.rawSearchTerm && dropdownVisible? '8px 8px 0 0' : '8px',
              transition: 'backgroundColor 0.2s', padding: isTablet ? 2 : 0
            }} className='search-container'>
            <InputBase
              fullWidth
              sx={{
                p: 1,
                borderRadius: 8,
                border: 'none',
                color: searchStore.rawSearchTerm ? 'black' : 'white',
                paddingLeft: '14px',
                background: 'rgba(255,255,255,0.1)',
                '&::placeholder': { color: 'white' },
                '& .MuiInputBase-input': { color: searchStore.rawSearchTerm ? 'black' : 'white' }
              }}
              placeholder="Search..."
              value={searchStore.rawSearchTerm}
              onChange={(e) => handleSearchInputChange(e.target.value)}
              onKeyPress={handleKeyPress}
              onBlur={handleTrackSearchAndBlur}
              onFocus={handleFocus}
              ref={inputRef}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon sx={{ color: searchStore.rawSearchTerm ? 'black' : 'white' }} />
                  </IconButton>
                </InputAdornment>
              }
            />
            {dropdownVisible && <SearchResults breadcrumbs={['SEARCH']} results={searchStore.searchResults} popup={true} homepage={true} categories={categories} />} 
          </Box>
          {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' && (
            <Box marginTop={1} marginX={isMobile ? 2 : 0} sx={{color: 'white'}}>
              Browse by 
              <SearchFiltersVet activeFilter={activeFilter} onFilterChange={handleFilterChange} homepageView={true} onTouchStart={(e) => handleTouchStart(e, activeFilter)} onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove} />
            </Box>
          )}
        </CenteredBox>
      </Box>
    </>
  );
});
