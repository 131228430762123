import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const NumberList = ({ items }) => (
    <List
        sx={{
            '@media print': {
                pageBreakInside: 'avoid',
                pageBreakBefore: 'auto',
                py: 2,
                display: 'inline',
            },
        }}
    >
        {items.map((item, index) => (
            <ListItem
                key={index}
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    padding: '2px 8px',
                    '@media print': {
                        pageBreakInside: 'avoid',
                        pageBreakBefore: 'auto',
                    },
                }}
            >
                <ListItemText primary={`${index + 1}. ${item}`} />
            </ListItem>
        ))}
    </List>
);

export default NumberList;
