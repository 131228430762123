import React, { useState } from 'react';
import { Box, Typography, TextField, IconButton, Divider } from '@mui/material';
import { Auth } from 'aws-amplify';
import SuccessDialog from '../../UIControls/api-button/SuccessDialog';
import EditIcon from '../../../assets/icons/Edit.png';
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';

const SettingsPassword = ({ isChangePasswordDialogOpen, setIsChangePasswordDialogOpen }) => {
  const [passwordChangeError, setPasswordChangeError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const handleChangePasswordClick = async () => {
    const currentPassword = document.getElementById('current-password').value;
    const newPassword = document.getElementById('new-password').value;
    const confirmPassword = document.getElementById('confirm-password').value;

    setPasswordChangeError('');

    if (newPassword !== confirmPassword) {
      setPasswordChangeError("Please ensure confirm password matches.");
      return;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.signIn(user.username, currentPassword);
      await Auth.changePassword(user, currentPassword, newPassword);
      setIsChangePasswordDialogOpen(false);
      setIsSuccessDialogOpen(true);
    } catch (error) {
      console.error('Error changing password:', error);
      if (error.code === 'NotAuthorizedException') {
        setPasswordChangeError('Current password is incorrect.');
      } else {
        setPasswordChangeError('Failed to change password. Ensure your new password is min 8 characters long with at least 1 number, uppercase and lowercase letter');
      }
    }
  };

  return (
    <Box className="settings-block" sx={{ backgroundColor: 'white', padding: '16px 24px', borderRadius: '16px', marginBottom: '24px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={3} position="relative">
        <Typography variant="h5" sx={{ fontWeight: 600, height: 48 }}>Password</Typography>
        <Divider
          sx={{
            width: 'calc(100% + 48px)', 
            position: 'absolute',
            bottom: 0,
            left: '-24px', 
            backgroundColor: 'rgba(229, 229, 229, 1)',
            mt: 2,
          }}
        />
        <IconButton onClick={() => setIsChangePasswordDialogOpen(true)} sx={{ p: 0 }}>
          <img src={EditIcon} alt='Edit' />
        </IconButton>
      </Box>

      <Box sx={{ fontFamily: 'monospace', fontSize: '16px', lineHeight: 1.5, letterSpacing: -3, mb: 2 }}>
        {'* '.repeat(25)} 
      </Box>

      <ConfirmationDialog
        open={isChangePasswordDialogOpen}
        handleClose={() => setIsChangePasswordDialogOpen(false)}
        onConfirm={handleChangePasswordClick}
        title="Change password"
        confirmText="Change"
        cancelText="Cancel"
        message={
          <>
            <TextField
              autoFocus
              margin="dense"
              id="current-password"
              label="Current Password"
              type="password"
              fullWidth
              variant="outlined"
              error={!!passwordChangeError}
            />
            <TextField
              margin="dense"
              id="new-password"
              label="New password"
              type="password"
              fullWidth
              variant="outlined"
            />
            <TextField
              margin="dense"
              id="confirm-password"
              label="Confirm new password"
              type="password"
              fullWidth
              variant="outlined"
            />
            {passwordChangeError && <Typography color="error" variant="body2">{passwordChangeError}</Typography>}
          </>
        }
      />

      <SuccessDialog
        open={isSuccessDialogOpen}
        handleClose={() => setIsSuccessDialogOpen(false)}
        title="Password Changed"
        message="Your password has been successfully changed!"
      />
    </Box>
  );
};

export default SettingsPassword;
