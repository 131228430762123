import React from 'react';
import { Breadcrumbs, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const HandoutBreadcrumb = ({ breadcrumbs = [], handoutTitle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'ANIMAL' : 'BODY AREA';

  // Determine the starting index for category links to change behavior
  const categoryIndex = breadcrumbs.findIndex(b => b === clinicType);

  const renderBreadcrumb = (breadcrumb, index) => {
    const isLast = index === breadcrumbs.length;
    const style = {
      fontSize: '12px',
      color: 'rgb(0, 4, 83)',
      textDecoration: 'none',
      textTransform: 'uppercase'
    };

    let path = '/';
    let state = {};

    if (breadcrumb === 'SEARCH') {
      path = '/search';
    } else if (index >= categoryIndex && categoryIndex !== -1) {
      // If the breadcrumb is at or beyond the category-specific index and such index exists
      path = '/search/categories';
      state = { category: breadcrumb };
    } else if (index < categoryIndex || categoryIndex === -1) {
      // If before the category-specific index or if such index doesn't exist
      path = '/search';
      state = { category: breadcrumb };
    }

    if (isLast) {
      return (
        <Box key={breadcrumb} style={{ ...style, display: 'inline' }}>
          {breadcrumb.toUpperCase()}
        </Box>
      );
    } else {
      return (
        <RouterLink
          key={breadcrumb}
          to={{ pathname: path }}
          state={state}
          style={style}
        >
          {breadcrumb.toUpperCase()}
        </RouterLink>
      );
    }
  };

  const fullBreadcrumbs = [...breadcrumbs, handoutTitle];

  return (
    <Box sx={{
      backgroundColor: 'white',
      pl: isMobile ? 2 : 5,
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #E5E5E5',
      width: '100%',
      minHeight: '60px'
    }} className='breadcrumb-container'>
      <Breadcrumbs separator="/" aria-label="breadcrumb">
        <RouterLink 
          to="/" 
          style={{ fontSize: '12px', color: 'rgb(0, 4, 83)', textDecoration: 'none', textTransform: 'uppercase' }}
        >
          HOME
        </RouterLink>
        {fullBreadcrumbs.map((breadcrumb, index) =>
          renderBreadcrumb(breadcrumb, index)
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default HandoutBreadcrumb;
