import React, { useContext, useState, useEffect } from 'react';
import { Button, TextField, Container, useMediaQuery, Box, Divider, Typography } from '@mui/material';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import { useNavigate, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import OnboardHeader from './onboard-header';
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';
import UserApi from '../../../rest-api/user-api';
import DownloadUtils from '../../../utils/download-utils';
import ApiButton from '../../UIControls/api-button/api-button';
import config from '../../../config';

const OnboardLoginScreen = () => {
    const appStateStore = useContext(StoreContext);
    const { authStore } = useContext(StoreContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [inviteData, setInviteData] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { loginBanner, clinicType } = config;

    useEffect(() => {
        // 'physio' change it to 'health'
        const effectiveClinicType = clinicType === 'physio' ? 'health' : clinicType;
        document.title = effectiveClinicType === 'health' 
            ? 'Cetra Health' 
            : `Cetra for ${effectiveClinicType.charAt(0).toUpperCase() + effectiveClinicType.slice(1)}s`;
    }, [clinicType]);

    useEffect(() => {
        const fetchInviteData = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const inviteId = queryParams.get('inviteId');
                const senderId = queryParams.get('senderId');

                if (inviteId && senderId) {
                    const inviteData = await UserApi.getClinicianDataMultiuserNoAuth(inviteId);
                    inviteData.inviteId = inviteId;
                    inviteData.senderId = senderId;

                    setInviteData(inviteData);
                    console.warn('Fetched invite data:', inviteData);
                }
            } catch (error) {
                console.error('Error fetching invite data:', error);
            }
        };

        fetchInviteData();
    }, [location.search]);

    const onSubmit = async () => {
        const timestamp = Date.now();
        const dateObject = new Date(timestamp);
        const formattedDate = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`;

        try {
            const result = await authStore.signInUser(email, password, async (userId, token) => {
                console.warn('ID Token after authentication:', token);

                await appStateStore.initializeUserStores(userId);

                const clinicianDataResponse = await UserApi.getClinicianData(token);
                if (clinicianDataResponse) {
                    let isMultiuser = false;
                    let userHandouts = clinicianDataResponse.handouts;
                    let inviteId = '';
                    let foundMultiUserData = {};

                    if (clinicianDataResponse.multiuserData && clinicianDataResponse.multiuserData.multiuserUsers) {
                        const foundUser = clinicianDataResponse.multiuserData.multiuserUsers.find(
                            (user) => user.userId === userId,
                        );
                        if (foundUser) {
                            isMultiuser = true;
                            userHandouts = foundUser.handouts || userHandouts;
                            inviteId = foundUser.inviteId || '';
                            foundMultiUserData = foundUser;
                        }
                    }

                    let formattedData = {
                        firstName: foundMultiUserData.firstName || clinicianDataResponse.userData.firstName,
                        lastName: foundMultiUserData.lastName || clinicianDataResponse.userData.lastName,
                        emailAddress: foundMultiUserData.emailAddress || clinicianDataResponse.userData.emailAddress,
                        mobile: foundMultiUserData.mobile || clinicianDataResponse.userData.mobile || '',
                        clinicImageId: '',
                        showLogoInHandouts: clinicianDataResponse.showLogoInHandouts,
                        handouts: JSON.stringify(userHandouts),
                        inviteId: inviteId,
                    };

                    if (clinicianDataResponse.clinicData) {
                        formattedData.clinicName = clinicianDataResponse.clinicData.clinicName;
                        formattedData.clinicNumber = clinicianDataResponse.clinicData.clinicNumber || '';
                        formattedData.clinicSize = clinicianDataResponse.clinicData.clinicSize || '';

                        if (clinicianDataResponse.clinicData.clinicImageId) {
                            const payload = {
                                files: [{ method: 'get', key: clinicianDataResponse.clinicData.clinicImageId }],
                                contentCategory: 'clinic_image',
                            };
                            try {
                                const clinicImageURL = await UserApi.generatedPresignedUrls(token, payload);
                                if (clinicImageURL && clinicImageURL.getUrls && clinicImageURL.getUrls.length > 0) {
                                    let clinicImageBase64 = await DownloadUtils.downloadImageFromPresignedUrl(
                                        clinicImageURL.getUrls[0].presignedUrl,
                                    );
                                    formattedData.clinicImageId = clinicImageBase64.base64data;
                                }
                            } catch (presignError) {
                                console.error('Error generating presigned URL:', presignError);
                            }
                        }
                    }

                    if (clinicianDataResponse.subscriptionData) {
                        formattedData.subscriptionData = {
                            ...clinicianDataResponse.subscriptionData,
                            accountType: isMultiuser
                                ? 'Clinic-multiuser'
                                : clinicianDataResponse.subscriptionData.accountType,
                        };
                    }

                    if (appStateStore) {
                        console.warn('attempting to store: ', formattedData, appStateStore);
                        const { userDataStore } = appStateStore;
                        userDataStore.setUserData(formattedData);
                    } else {
                        console.error('userDataStore is not defined.');
                    }
                } else {
                    console.error('Clinician data fetch failed.');
                    setErrorMessage('Failed to fetch user data. Please try again.');
                    setIsDialogOpen(true);
                }
            });

            if (result.success) {
                mixpanel.identify(email);
                mixpanel.people.set({ $email: email });
                mixpanel.track('Login Success', { email: email, time: formattedDate });
            } else {
                mixpanel.track('Login Failure', {
                    email: email,
                    errorMessage: result.message,
                    time: formattedDate,
                });

                setIsDialogOpen(true);
                throw new Error(result.message);
            }
        } catch (error) {
            console.error('Error during authentication:', error);
            mixpanel.track('Login Error', { email: email, error: error.message, time: formattedDate });
            if (error.message === 'User is not confirmed.') {
                setErrorMessage('User does not exist - please register');
            } else {
                setErrorMessage(error.message);
            }
            setIsDialogOpen(true);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <>
            <OnboardHeader />
            <Box component="main" sx={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
                <Container
                    component={'section'}
                    sx={{
                        flex: 1,
                        minHeight: 500,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                >
                    {inviteData ? (
                        <Box sx={{ width: 485, margin: 'auto' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Join your team at {inviteData.clinicData?.clinicName}!
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 4 }}>
                                {inviteData.senderData?.firstName} invited you to join the{' '}
                                <strong>{inviteData.clinicData?.clinicName}</strong> workspace on Cetra. Create an
                                account with your work email to accept your invite.
                            </Typography>
                            <PinkGradientButton
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    navigate(
                                        `/onboard/name?inviteId=${inviteData.inviteId}&senderId=${inviteData.senderId}`,
                                    )
                                }
                                sx={{ my: 2 }}
                            >
                                Let's get started
                            </PinkGradientButton>
                        </Box>
                    ) : (
                        <Box sx={{ width: isMobile ? '100%' : 350, margin: 'auto', mt: isMobile ? 3 : 'auto' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
                                Welcome to Cetra!
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb: 6 }}>
                                Log in or try out our 14-day free trial. No credit card needed.
                            </Typography>
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                                margin="normal"
                                onKeyDown={handleKeyDown}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                margin="normal"
                                onKeyDown={handleKeyDown}
                            />

                            <Box sx={{ textAlign: 'left', mt: 2 }}>
                                <Button
                                    disableRipple
                                    color="primary"
                                    onClick={() => navigate('/onboard/login/reset-password')}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                >
                                    Forgot password?
                                </Button>
                            </Box>

                            <Box
                                sx={{
                                    mt: 4,
                                    ...(isMobile && {
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        padding: 2,
                                        width: 'auto',
                                        zIndex: 1,
                                    }),
                                }}
                            >
                                <ApiButton
                                    apiCall={onSubmit}
                                    color="primary"
                                    buttonLabel="Log in"
                                    errorTitle="Log in error"
                                    onSuccess={() => {
                                        console.log('onSuccess called');
                                    }}
                                    errorMessage="Unable to login, please try again"
                                    successMessage="Login success!"
                                    fullWidth={true}
                                    sx={{ width: '100%' }}
                                />

                                <Box sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}>
                                    <Divider sx={{ flex: 1, borderColor: '#000453', mr: 2 }} />
                                    <Typography sx={{ px: 1, color: '#000453', fontWeight: 600 }}>OR</Typography>
                                    <Divider sx={{ flex: 1, borderColor: '#000453', ml: 2 }} />
                                </Box>

                                <Box>
                                    <PinkGradientButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate('/onboard/type')}
                                        sx={{ width: '100%', my: 0 }}
                                    >
                                        Register now
                                    </PinkGradientButton>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Container>

                {!isMobile && (
                    <Box
                        sx={{
                            flex: 1.5,
                            backgroundImage: isMobile ? 'none' : `url(${loginBanner})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom',
                        }}
                    ></Box>
                )}
            </Box>

            <ConfirmationDialog
                open={isDialogOpen}
                handleClose={() => setIsDialogOpen(false)}
                onConfirm={() => setIsDialogOpen(false)}
                title="Login error"
                message={errorMessage}
                confirmText="Close"
                cancelTextReq={false}
            />
        </>
    );
};

export default OnboardLoginScreen;
