import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery, Container } from '@mui/material';
import General3DImage from '../../../assets/images/model-3d-static.svg';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import PlayButtonIcon from '../../../assets/icons/PlayButton.svg';
import ChecklistTickIcon from '../../../assets/icons/ChecklistTick.svg';
import { useNavigate } from 'react-router-dom';

// TODO: that needs dependency on type of project: vets, physio
const Explore3DPanel = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNavigateTo3DModels = () => {
        // Redirect to the 3D models page
        navigate('/3d-models');
    };

    return (
        <>
            <Typography variant="h5" align="center" gutterBottom sx={{ mb: 2, pt: 5 }}>
                Interactive 3D models
            </Typography>

            <Box 
                component="section" 
                maxWidth={isMobile ? 'md' : 'xl'} 
                sx={{
                    mb: 4,
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    boxSizing: 'border-box',
                    display: 'block',
                    paddingLeft: '66px',
                    paddingRight: '66px',
                    position: 'relative',
                    paddingTop: '32px',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: 2,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Grid container spacing={0} direction={{ xs: 'column', md: 'row' }} alignItems="center">
                        {/* Left Section: Image with Play Button */}
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                p: { xs: '20px', md: '50px' }, // same padding on all sides
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    maxWidth: { xs: '100%', sm: '400px', md: 'unset' },
                                    margin: '0 auto', // Center the image on mobile
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    src={General3DImage}
                                    alt="3D model preview"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'cover',
                                    }}
                                />
                                <Box
                                    component="img"
                                    src={PlayButtonIcon}
                                    alt="Play button"
                                    sx={{
                                        width: '64px',
                                        height: '64px',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleNavigateTo3DModels}
                                />
                            </Box>
                        </Grid>

                        {/* Right Section: Text Points and Button */}
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                p: { xs: '20px', md: '32px' }, // it shuld be same/equal padding on all sides
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {[
                                    'Increase referral rates',
                                    'Transform client understanding',
                                    'Boost diagnostic performance',
                                ].map((text, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Box
                                            component="img"
                                            src={ChecklistTickIcon}
                                            alt="Check icon"
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                mr: 2,
                                            }}
                                        />
                                        <Typography
                                            variant="inherit"
                                            sx={{
                                                color: '#000453',
                                                fontSize: '20px',
                                                lineHeight: '1.5',
                                            }}
                                        >
                                            {text}
                                        </Typography>
                                    </Box>
                                ))}

                                <PinkGradientButton
                                    sx={{
                                        height: 68,
                                        width: { xs: '100%', sm: '333px' },
                                        mt: 4,
                                        alignSelf: 'flex-start',
                                    }}
                                    onClick={handleNavigateTo3DModels}
                                >
                                    <Typography variant="inherit" sx={{ fontSize: '20px', fontWeight: 500 }}>
                                        Explore models
                                    </Typography>
                                </PinkGradientButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default Explore3DPanel;
