import React, { useContext, useEffect, useState, useRef } from 'react';
import { Typography, Container, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import HandoutPreview from './handout-preview';
import UserApi from '../../../rest-api/user-api';
import DownloadUtils from '../../../utils/download-utils';
import Model3DIcon from '../../../assets/icons/3D-Preview.png';

const MAX_RECENT_HANDOUTS = 5;

function RecentlyViewedHandouts() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isBelow900 = useMediaQuery(theme.breakpoints.down('md'));
    const isBelow1200 = useMediaQuery(theme.breakpoints.down('lg'));

    const { contentStore, imageStore, authStore } = useContext(StoreContext);
    const [recentHandouts, setRecentHandouts] = useState([]);
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        async function fetchAndStoreImages(handouts) {
            const promises = handouts.map(async (handout) => {
                const locale = 'en-UK';
                const idToken = authStore.idToken;
                const localeData = handout.contentData[locale] || [];
                const introductionSection = localeData.find((section) => section.sectionName === 'introduction') || {
                    blocks: [],
                };
                const is3DModel = localeData.some((section) =>
                    section.blocks.some((block) => block.type === '3dModel'),
                );

                if (is3DModel) {
                    const modelBlock = localeData
                        .flatMap((section) => section.blocks)
                        .find((block) => block.type === '3dModel');

                    if (modelBlock && modelBlock.imageSrc) {
                        const imageSrc = modelBlock.imageSrc;
                        const imageObject = await imageStore.getImageFromIndexedDB(imageSrc);

                        if (!imageObject) {
                            try {
                                const payload = {
                                    files: [{ method: 'get', key: imageSrc }],
                                    contentCategory: 'image',
                                };
                                const presignedUrl = await UserApi.generatedPresignedUrls(idToken, payload);

                                const downloadResult = await DownloadUtils.downloadImageFromPresignedUrl(
                                    presignedUrl.getUrls[0]['presignedUrl'],
                                );

                                if (downloadResult && downloadResult.base64data) {
                                    const base64data = downloadResult.base64data;
                                    const etag = downloadResult.etag;

                                    // Store the image in ImageStore for later use
                                    await imageStore.saveImageToIndexedDB(imageSrc, base64data, etag);

                                    // Set the base64 image for preview
                                    handout.previewImage = base64data;
                                } else {
                                    handout.previewImage = Model3DIcon; // fallback image
                                }
                            } catch (error) {
                                console.error(`Error fetching image: ${error}`);
                                handout.previewImage = Model3DIcon; // fallback image
                            }
                        } else {
                            handout.previewImage = imageObject.data; // Image already in IndexedDB
                        }
                    } else {
                        handout.previewImage = Model3DIcon; // fallback image if no imageSrc
                    }
                } else {
                    const firstIllustration = introductionSection.blocks.find((block) => block.type === 'illustration');

                    if (firstIllustration) {
                        const imageSrc = firstIllustration.src;
                        const imageObject = await imageStore.getImageFromIndexedDB(imageSrc);
                        if (!imageObject) {
                            try {
                                const payload = {
                                    files: [{ method: 'get', key: imageSrc }],
                                    contentCategory: 'image',
                                };
                                // Get presigned URL for the image
                                const presignedUrl = await UserApi.generatedPresignedUrls(idToken, payload);
                                console.warn('new image to downlaod..', presignedUrl);
                                // Download the image using the presigned URL
                                const downloadResult = await DownloadUtils.downloadImageFromPresignedUrl(
                                    presignedUrl.getUrls[0]['presignedUrl'],
                                );
                                // If the download was successful, save the downloaded image to IndexedDB
                                console.warn(downloadResult);
                                if (downloadResult) {
                                    const base64data = downloadResult.base64data;
                                    const etag = downloadResult.etag;
                                    await imageStore.saveImageToIndexedDB(imageSrc, base64data, etag);
                                    // fetchAndStoreImages()
                                } else {
                                    console.error(`Failed to download image: ${downloadResult.reason}`);
                                }
                            } catch (error) {
                                console.error(`Error fetching image: ${error}`);
                            }
                        }
                    }
                }
            });

            await Promise.all(promises);
            setRecentHandouts(handouts);
        }

        const recentHandoutIds = JSON.parse(localStorage.getItem('recentHandouts')) || [];
        const fetchedHandouts = contentStore.getContentFromIds(recentHandoutIds).slice(0, MAX_RECENT_HANDOUTS);
        fetchAndStoreImages(fetchedHandouts);
    }, [contentStore, imageStore, authStore]); // added authStore, as it seems to be missing

    if (recentHandouts.length === 0) {
        return null;
    }

    return (
        <Container component={'section'} maxWidth={isMobile ? 'md' : 'xl'} sx={{ position: 'relative', pt: 4 }}>
            <Typography variant="h5" align="center" gutterBottom sx={{ mb: 2 }}>
                Recently viewed
            </Typography>

            <Swiper
                modules={[Navigation]}
                onSwiper={setSwiper}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                loop={true}
                spaceBetween={10}
                slidesPerView={isMobile ? 1 : isBelow900 ? 2 : isBelow1200 ? 3 : 4}
            >
                {recentHandouts.map((handoutData) => (
                    <SwiperSlide key={handoutData.contentId}>
                        <HandoutPreview handoutData={handoutData} previewImage={handoutData.previewImage} />
                    </SwiperSlide>
                ))}
            </Swiper>

            <IconButton
                ref={navigationPrevRef}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: 0,
                    zIndex: 10,
                    color: '#000453',
                    backgroundColor: '#DFDCEA',
                    '&:hover': {
                        backgroundColor: '#c4c1d7',
                    },
                }}
            >
                <ChevronLeftRoundedIcon />
            </IconButton>
            <IconButton
                ref={navigationNextRef}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: 0,
                    zIndex: 10,
                    color: '#000453',
                    backgroundColor: '#DFDCEA',
                    '&:hover': {
                        backgroundColor: '#c4c1d7',
                    },
                }}
            >
                <ChevronRightRoundedIcon />
            </IconButton>
        </Container>
    );
}

export default RecentlyViewedHandouts;
