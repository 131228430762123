import React from 'react';
import { useMediaQuery, useTheme, Button } from '@mui/material';
import pinkGradient from '../../assets/images/cetra-gradient_720.png';
import bodyAreaIcon from '../../assets/icons/categories/bodyAreaWhite.svg';
import conditionIcon from '../../assets/icons/categories/conditionWhite.svg';
import animalIcon from '../../assets/icons/categories/animalWhite.svg';
import diagramsIcon from '../../assets/icons/categories/diagrams.svg';
import diagramsIconWhite from '../../assets/icons/categories/diagramsWhite.svg'; // White icon for vet
import modelIcon from '../../assets/icons/categories/3D-white-3.svg'; // 3D model icon

const categoryIconMap = {
    'Body area': bodyAreaIcon,
    Condition: conditionIcon,
    Animal: animalIcon,
    Diagrams: diagramsIcon,
    '3D Models': modelIcon,
};

export function CategoryButton({ category, onClick, sx = {} }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE;

    const buttonStyle = {
        backgroundColor: theme.primaryBlue,
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        padding: '16px',
        height: '166px',
        position: 'relative',
        textTransform: 'none',
        width: '49%',
        color: '#fff',
        borderRadius: '12px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.primaryBlue,
        },
    };

    const buttonPinkGradientStyle = {
        backgroundImage: `url(${pinkGradient})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        '&:hover': {
            backgroundImage: `url(${pinkGradient})`,
        },
    };

    const buttonWhiteStyle = {
        backgroundColor: 'white',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'black',
        border: '4px solid #FC96A2',
        '&:hover': {
            backgroundColor: 'white',
        },
    };

    const iconCircleStyle = {
        position: 'absolute',
        top: 12,
        right: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 0,
    };

    const iconDiagramsCircleStyle = {
        position: 'absolute',
        top: 12,
        right: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        backgroundColor: 'rgba(252, 150, 162, 0.3)',
        zIndex: 0,
    };

    const diagramIcon = clinicType === 'vet' ? diagramsIconWhite : diagramsIcon;

    return (
        <Button
            variant="contained"
            onClick={() => onClick(category)}
            sx={{
                ...buttonStyle,
                ...(category === 'Condition' || category === '3D Models' ? buttonPinkGradientStyle : {}),
                ...(category === 'Diagrams' && clinicType === 'vet' ? buttonStyle : {}),
                ...(category === 'Diagrams' && clinicType === 'physio' ? buttonWhiteStyle : {}),
                ...sx, // custom styles from parent component
            }}
        >
            {category === 'Condition' ? 'All Conditions' : category}
            <div style={category === 'Diagrams' ? iconDiagramsCircleStyle : iconCircleStyle}>
                <img
                    src={category === 'Diagrams' ? diagramIcon : categoryIconMap[category]}
                    alt="Category Icon"
                    width="24px"
                    height="24px"
                    style={{
                        position: 'relative',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                />
            </div>
        </Button>
    );
}
