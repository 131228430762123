import React from 'react';
import { Box, Button } from '@mui/material';
import canineIcon from '../../../assets/icons/categories/canine.svg';
import felineIcon from '../../../assets/icons/categories/feline.svg';
import hamsterIcon from '../../../assets/icons/categories/hamster.svg';
import equineIcon from '../../../assets/icons/categories/equine.svg';
import smallAnimalsIcon from '../../../assets/icons/categories/smallAnimals.svg';
import largeAnimalsIcon from '../../../assets/icons/categories/largeAnimals.svg';
import smallMammalsIcon from '../../../assets/icons/categories/smallMammals.svg';

import { observer } from 'mobx-react-lite';

const filterIconMap = {
    Canine: canineIcon,
    Feline: felineIcon,
    Equine: equineIcon,
    'Small mammals': smallMammalsIcon,
    'Large animals': largeAnimalsIcon,
};

export const SearchFiltersVet = observer(({ 
  activeFilter, 
  onFilterChange, 
  filterContext, 
  homepageView = false,  
  onTouchStart = () => {}, 
  onTouchEnd = () => {}, 
  onTouchMove = () => {} 
}) => {
    const handleFilterClick = (filter, event) => {
        event.preventDefault();
        window.scrollTo(0, 650);
        if (filterContext === '3D Models') {
            onFilterChange(filter); // Handle specific to 3D Models
        } else {
            if (activeFilter === filter) {
                onFilterChange('Condition'); // Default to 'Condition' in the main Search screen
            } else {
                onFilterChange(filter);
            }
        }
    };

    return (
        <Box sx={{ marginTop: '8px!important', display: homepageView ? 'flex' : 'initial', justifyContent: homepageView ? 'space-between' : 'initial', flexWrap: 'wrap' }}>
            {Object.entries(filterIconMap).map(([filter, icon]) => (
                <Button
                    key={filter}
                    className="category-btn"
                    sx={{
                        color: homepageView ? 'white' : (activeFilter === filter ? 'white' : 'black'),
                        backgroundColor: activeFilter === filter 
                            ? '#FC96A2' 
                            : (homepageView ? 'rgba(255, 255, 255, 0.4)' : '#f7f7f7'),
                        borderRadius: homepageView ? '24px' : '16px',
                        paddingX: homepageView ? '16px' : '8px',
                        marginRight: 1,
                        marginTop: 1,
                        height: 48,
                        fontSize: 12,
                        '&:hover': {
                            backgroundColor: activeFilter === filter 
                                ? '#FC96A2' 
                                : (homepageView ? 'rgba(255, 255, 255, 0.4)' : '#f7f7f7'),
                        },
                    }}
                    onClick={(e) => handleFilterClick(filter, e)}
                    onTouchStart={onTouchStart}
                    onTouchEnd={(e) => onTouchEnd(e, filter)}
                    onTouchMove={onTouchMove}
                >
                    <img
                        src={icon}
                        alt={`${filter} Filter Icon`}
                        width="24px"
                        height="24px"
                        style={{
                            position: 'relative',
                            top: 0,
                            marginRight: 4,
                            right: 0,
                            zIndex: 1,
                            filter: homepageView || activeFilter === filter ? 'invert(100%)' : 'initial',
                        }}
                    />
                    {filter.replace('Animals', 'animals')}
                </Button>
            ))}
        </Box>
    );
});
