import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const AddToBundleButton = ({ onClick, sx, disabled }) => {
    const defaultSx = {
        width: 200,
        height: 48,
        backgroundColor: '#E86E7B', // Red background
        color: '#FFFFFF', // White text
        borderRadius: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
        transition: 'none',
        '&:hover': {
            //overwrite if hoover above
            backgroundColor: '#E86E7B',
            color: '#FFFFFF',
        },
        ...(disabled && {
            // secure, better to add
            backgroundColor: '#F0EFF1', // Disabled state color
            color: '#979797', // Disabled text color
            cursor: 'not-allowed',
            pointerEvents: 'none',
        }),
        ...sx,
    };

    return (
        <Button onClick={onClick} sx={defaultSx} disabled={disabled}>
            <Typography variant="inherit" sx={{ fontSize: '16px', fontWeight: 500 }}>
                Add to bundle
            </Typography>
            <Box
                sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: '#E86E7B', //  background color, tricky
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '2px solid #FFFFFF', // circle border
                }}
            >
                <Typography variant="inherit" sx={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 'bold' }}>
                    +
                </Typography>
            </Box>
        </Button>
    );
};

AddToBundleButton.propTypes = {
    onClick: PropTypes.func,
    sx: PropTypes.object,
    disabled: PropTypes.bool,
};

AddToBundleButton.defaultProps = {
    onClick: () => {},
    sx: {},
    disabled: false,
};

export default AddToBundleButton;
