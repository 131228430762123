import React, { useContext, useState, useMemo } from 'react';
import { TextField, Container, useMediaQuery, Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { useNavigate } from 'react-router-dom';
import ApiButton from '../../UIControls/api-button/api-button';
import { Auth } from 'aws-amplify';
import OnboardHeader from './onboard-header';
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import config from '../../../config';

const OnboardLoginResetPasswordScreen = () => {
  const appStateStore = useContext(StoreContext);
  const { authStore } = useContext(StoreContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [resetStage, setResetStage] = useState('request'); // 'request', 'verify', 'reset'
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); 
  const [error, setError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const { loginBanner } = config;

  const passwordRequirements = [
    { test: pwd => pwd.length >= 8, text: "At least 8 characters" },
    { test: pwd => /[A-Z]/.test(pwd), text: "An uppercase letter" },
    { test: pwd => /[a-z]/.test(pwd), text: "A lowercase letter" },
    { test: pwd => /\d/.test(pwd), text: "A number" }
  ];

  const passwordCriteria = useMemo(() => passwordRequirements.map(req => ({
    ...req,
    meets: req.test(newPassword)
  })), [newPassword]);

  const isPasswordValid = useMemo(() => passwordCriteria.every(criterion => criterion.meets), [passwordCriteria]);

  const canSubmit = useMemo(() => isPasswordValid && newPassword === confirmPassword, [isPasswordValid, newPassword, confirmPassword]);

  const handleForgotPassword = async () => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }
    try {
      await Auth.forgotPassword(email);
      setResetStage('verify');
      setIsDialogOpen(false);
      console.log('Verification code sent to email.');
    } catch (error) {
      console.error('Forgot password error:', error);
      if (error.code === 'UserNotFoundException') {
        setErrorMessage('No account found with the provided email address.');
      } else {
        setErrorMessage('Failed to send verification code.');
      }
      setIsDialogOpen(true);
    }
  };

  const handleVerifyCode = async () => {
    if (!verificationCode) {
      alert('Please enter the verification code.');
      return;
    }
    try {
      console.log('Code verification set');
      setResetStage('reset');
    } catch (error) {
      console.error('Error verifying code:', error);
      setErrorMessage('Failed to verify the code.');
      setIsDialogOpen(true);
    }
  };

  const handleResetPassword = async () => {
    if (!canSubmit) {
      setError('Your new password does not meet all requirements or the passwords do not match.');
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      setIsSuccessDialogOpen(true); 
    } catch (error) {
      console.error('Error resetting password:', error);

      if (error.code === 'CodeMismatchException') {
        setErrorMessage('The verification code entered is incorrect. Please try again.');
        setResetStage('verify');  
      } else if (error.code === 'ExpiredCodeException') {
        setErrorMessage('The verification code has expired. Please request a new one.');
        setResetStage('request');  
      } else if (error.code === 'InvalidPasswordException') {
        setErrorMessage('Password does not conform to policy: Password must have uppercase characters');
        setResetStage('reset');  
      } else {
        setErrorMessage('Failed to reset password.');
        setResetStage('verify');  
      }
      setIsDialogOpen(true);  
    }
  };

  const handleSuccessDialogClose = () => {
    setIsSuccessDialogOpen(false);
    navigate('/onboard/login'); 
  };

  return (
    <>
      <OnboardHeader />
      <Box sx={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Container component={'section'} sx={{ flex: 1, minHeight: 500, display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }} >
          <Box sx={{ width: 350, margin: "auto" }}>
            <IconButton onClick={() => navigate('/')} sx={{ ml: "-8px" }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
              Reset your password
            </Typography>
            {resetStage === 'request' && (
              <>
                <Typography sx={{ mb: 2 }}>
                  Please enter your email - we will send a verification code to reset your account's password.
                </Typography>
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <ApiButton
                  apiCall={handleForgotPassword}
                  color="primary"
                  buttonLabel="Send reset code"
                  fullWidth
                  sx={{ mt: 2 }}
                  showSuccessDialog={false}
                />
              </>
            )}
            {resetStage === 'verify' && (
              <>
                <Typography sx={{ mb: 2 }}>
                  Please enter the verification code sent to your email. Check your junk/spam folder if needed.
                </Typography>
                <TextField
                  label="Verification code"
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <ApiButton
                  apiCall={handleVerifyCode}
                  color="primary"
                  buttonLabel="Verify code"
                  fullWidth
                  sx={{ mt: 2 }}
                  showSuccessDialog={false}
                />
              </>
            )}
            {resetStage === 'reset' && (
              <>
                <Typography sx={{ mb: 2 }}>
                  Please enter your new password.
                </Typography>
                <TextField
                  label="New password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Confirm password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                  sx={{ mt: 2 }}
                />
                <ApiButton
                  apiCall={handleResetPassword}
                  color="primary"
                  buttonLabel="Reset password"
                  fullWidth
                  sx={{ mt: 2 }}
                  showSuccessDialog={false}
                />
                <List dense sx={{ mt: 2 }}>
                  {passwordCriteria.map((criterion, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemIcon>
                        {criterion.meets ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                      </ListItemIcon>
                      <ListItemText primary={criterion.text} />
                    </ListItem>
                  ))}
                </List>
                {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
              </>
            )}
          </Box>
        </Container>

        {!isMobile && 
          <Box sx={{
            flex: 1.5,
            backgroundImage: isMobile ? 'none' : `url(${loginBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom"
          }}>
          </Box>
        }
        
      </Box>

      <ConfirmationDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        onConfirm={() => setIsDialogOpen(false)}
        title="Error"
        message={errorMessage}
        confirmText="Close"
        cancelTextReq={false}
      />

      <ConfirmationDialog
        open={isSuccessDialogOpen}
        handleClose={handleSuccessDialogClose}
        onConfirm={handleSuccessDialogClose}
        title="Success"
        message="Password reset successfully! Please login with your new password."
        confirmText="Close"
        cancelTextReq={false}
      />
    </>
  );
};

export default OnboardLoginResetPasswordScreen;
