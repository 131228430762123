import RestAPIUtils from "./rest-api-utils";

class KlaviyoApi {

  static KLAVIYO_API_CONFIG = {
    apiUrl: process.env.REACT_APP_USER_API_URL,
    apiKey: process.env.REACT_APP_USER_API_KEY
  };

  // Handle user removal from all Klaviyo lists, then add to the specified list
  static async updateUserInKlaviyo(bearerToken, email, targetListName) {
    const apiPath = `/v1/user/klaviyo`;
    const payload = {
      email: email,
      targetListName: targetListName
    };

    console.log(`updateUserInKlaviyo request payload: ${JSON.stringify(payload)}`);
    
    try {
      const response = await RestAPIUtils.makeWriteRequest(bearerToken, KlaviyoApi.KLAVIYO_API_CONFIG, apiPath, 'POST', payload);
      return response;
    } catch (error) {
      console.error('Klaviyo API error:', error);
      return {
        success: false,
        message: 'Failed to update user in Klaviyo',
        error: error.message || 'Unknown error'
      };
    }
  }
}

export default KlaviyoApi;
