import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SettingsBilling from '../authenticated/settings/settings-billing';
import SubscriptionCard from '../UIControls/SubscriptionCard';
import subscriptionPlans from '../authenticated/settings/subscriptionPlans';
import { CenteredWrapper } from '../UIControls/centered-wrapper';
import { styled } from '@mui/system';
import OnboardHeader from '../unauthenticated/onboard/onboard-header';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: 50,
  overflow: 'hidden',
  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.12)',
  width: 205
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
  color: selected ? '#fff' : '#000',
  backgroundColor: selected ? '#000453' : '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: selected ? '#000453' : '#f4f4f4',
  },
  '&.Mui-selected': {
    backgroundColor: '#000453',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000453',
    },
  },
  border: 'none',
  borderRadius: 0,
  flex: 1,
}));

const Checkout = ({ isOnboarding = false }) => {
  const location = useLocation();
  const { plan = 'Professional', billingCycle: initialBillingCycle = 'annually' } = location.state || {};
  const [billingCycle, setBillingCycle] = useState(initialBillingCycle);
  const selectedPlan = subscriptionPlans[plan];
  const [planDetails, setPlanDetails] = useState({
    priceId: billingCycle === 'monthly' ? selectedPlan.priceMonthlyId : selectedPlan.priceAnnuallyId,
    price: billingCycle === 'monthly' ? selectedPlan.priceMonthly : selectedPlan.priceAnnually,
    productId: selectedPlan.productId,
    isMonthly: billingCycle === 'monthly',
    accountType: selectedPlan.accountType
  });
  const [nextPaymentDate, setNextPaymentDate] = useState('');
  const isLVS = localStorage.getItem('LVS') === 'true';
  const [promoDiscount, setPromoDiscount] = useState(50); // default 50% off until further notice
  console.warn(location.state, billingCycle);
  console.warn(planDetails);

  useEffect(() => {
    setPlanDetails({
      priceId: billingCycle === 'monthly' ? selectedPlan.priceMonthlyId : selectedPlan.priceAnnuallyId,
      price: billingCycle === 'monthly' ? selectedPlan.priceMonthly : selectedPlan.priceAnnually,
      productId: selectedPlan.productId,
      isMonthly: billingCycle === 'monthly',
      accountType: selectedPlan.accountType,
      couponId: selectedPlan.couponId
    });

    const today = new Date();
    let nextDate = new Date(today);

    // Set the next payment date based on the billing cycle
    if (billingCycle === 'monthly') {
      nextDate.setMonth(today.getMonth() + 1);
    } else if (billingCycle === 'annually') {
      nextDate.setFullYear(today.getFullYear() + 1);
    }

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    setNextPaymentDate(nextDate.toLocaleDateString('en-GB', options));
  }, [billingCycle, selectedPlan]);

  const handleBillingCycleChange = (event, newCycle) => {
    if (newCycle !== null) {
      setBillingCycle(newCycle);
    }
  };

  const handlePromoDiscountChange = (discount) => {
    setPromoDiscount(discount); 
  };

  return (
    <>
      {isOnboarding && <OnboardHeader />}
      <CenteredWrapper component="main" sx={{ zIndex: 1, position: 'relative', mt: '64px' }}>
        <Box sx={{ width: '100%', maxWidth: 638 }}>
          <Box>
            <Typography variant="h4" sx={{ marginBottom: 1.5, fontSize: 32 }}>
              Overview of your plan
            </Typography>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {plan} plan - First payment today, next payment on {nextPaymentDate}.
            </Typography>
            <Divider sx={{ marginBottom: 4 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ maxWidth: 400 }}>
                Opt for our annual plan for an extra saving! Enjoy additional seats, more features and priority support all year long.
              </Typography>
              {!isLVS && 
                (
                  <StyledToggleButtonGroup
                  value={billingCycle}
                  exclusive
                  onChange={handleBillingCycleChange}
                  aria-label="billing cycle"
                >
                  <StyledToggleButton value="monthly" aria-label="monthly" selected={billingCycle === 'monthly'}>
                    Monthly
                  </StyledToggleButton>
                  <StyledToggleButton value="annually" aria-label="annually" selected={billingCycle === 'annually'}>
                    Annually
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
                )
              }
            </Box>

            <SubscriptionCard plan={plan} billingCycle={billingCycle} discount={promoDiscount}  />
            <Box sx={{ mt: 4 }}>
              <SettingsBilling plan={planDetails} editorActiveDefault={true}  onPromoDiscountChange={handlePromoDiscountChange} showPromo={billingCycle === 'annually' ? true : false} isLVS={isLVS && billingCycle === 'annually'} />
            </Box>
          </Box>
        </Box>
      </CenteredWrapper>
    </>
  );
};

export default Checkout;
