import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, IconButton, Box, Checkbox, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PinkGradientButton from './PinkGradientButton';
import StoreContext from '../../react-core-lib/components/app/store-context';

const CancelPlanModal = ({ open, onClose, onConfirm }) => {
  const { authStore, userDataStore} = useContext(StoreContext);
  const [isConfirming, setIsConfirming] = useState(true);
  const [checkedReasons, setCheckedReasons] = useState({
    noneRelevant: false,
    notEnoughHandouts: false,
    priceTooHigh: false
  });

   // Convert subscriptionEndDate to a readable format
   const formattedEndDate = new Date(Number(userDataStore?.subscriptionData?.subscriptionEndDate)).toLocaleDateString('en-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  const handleCheckboxChange = (reason) => {
    setCheckedReasons((prevState) => ({
      ...prevState,
      [reason]: !prevState[reason]
    }));
  };

  const handleCancel = () => {
    setIsConfirming(true);
    onConfirm();
    onClose();
  };

  const handleClose = () => {
    setIsConfirming(true);
    onConfirm();
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      aria-labelledby="change-plan-dialog" 
      PaperProps={{
        sx: {
          p: 4, 
          maxWidth: '711px',
          borderRadius: 4
        },
      }}
    >
      <DialogTitle variant="h4" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', p: 0 , fontWeight: 600 }}>
          {isConfirming ? "Are you sure you want to cancel your plan?" : "Why are you cancelling your plan?"}
        <IconButton onClick={handleClose} sx={{ color: '#0b0c26' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p:0, mt: 3, mb: 4 }}>
        {isConfirming ? (
          <Typography variant="body1" sx={{ color: '#616063' }}>
            Please confirm that you want to proceed with cancelling your subscription. You can continue to use your account until {formattedEndDate}.
          </Typography>
        ) : (
          <Box>
            <Typography variant="body1" sx={{ color: '#616063', mb: 2 }}>
              Please let us know why you are cancelling:
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedReasons.noneRelevant}
                  onChange={() => handleCheckboxChange('noneRelevant')}
                  sx={{ color: '#616063' }}
                />
              }
              label="None of the features are relevant for my needs"
              sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedReasons.notEnoughHandouts}
                  onChange={() => handleCheckboxChange('notEnoughHandouts')}
                  sx={{ color: '#616063' }}
                />
              }
              label="There's not enough handouts"
              sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedReasons.priceTooHigh}
                  onChange={() => handleCheckboxChange('priceTooHigh')}
                  sx={{ color: '#616063' }}
                />
              }
              label="The price is too high, I can't afford it"
              sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', p: 0, alignItems: { xs: 'stretch', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>        
        {isConfirming ? (
          <>
            <PinkGradientButton
              onClick={() => setIsConfirming(false)} 
              variant="contained"
              sx={{m:0}}
            >
              Yes, cancel
            </PinkGradientButton>
            <Button
              onClick={onClose}
              className='black-btn'
              sx={{
                color: '#0b0c26',
                textDecoration: 'underline',
                textTransform: 'none',
                fontWeight: 600,
                justifyContent: 'flex-start'
              }}
            >
              No, go back
            </Button>
          </>
        ) : (
          <PinkGradientButton
            onClick={handleCancel}
            variant="contained"
            sx={{ m: 0 }}
          >
            Confirm cancellation
          </PinkGradientButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CancelPlanModal;
