import React, { useState, useContext } from 'react';
import { Box, Typography, Divider, List, ListItem, useMediaQuery } from '@mui/material';
import subscriptionPlans from '../authenticated/settings/subscriptionPlans';
import BlueButton from './BlueButton';
import { useNavigate, useLocation } from 'react-router-dom';
import PinkGradientButton from './PinkGradientButton';
import ConfirmationDialog from './ConfirmationDialog';
import StoreContext from '../../react-core-lib/components/app/store-context';
import UserApi from '../../rest-api/user-api';
import { useSnackbar } from '../authenticated/SnackbarProvider';
import KlaviyoApi from '../../rest-api/klaviyo-api';

const SubscriptionCard = ({
    plan,
    discount = 50,
    isCurrentPlan,
    billingCycle,
    currentPlanBillingCycle,
    showSelect = false,
    sx,
}) => {
    console.warn('discount found ', discount);
    console.warn('currentPlanBillingCycle found ', currentPlanBillingCycle);

    const planDetails = subscriptionPlans[plan];
    const navigate = useNavigate();
    const location = useLocation();
    const [showTrialDialog, setShowTrialDialog] = useState(false);
    const { authStore, userDataStore } = useContext(StoreContext);
    const showSnackbar = useSnackbar();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    if (!planDetails) {
        return <Typography variant="body1">Invalid plan selected.</Typography>;
    }

    const { priceMonthly, priceAnnually, benefits } = planDetails;

    const discountedPrice = (price, discount) => (price * (1 - discount / 100)).toFixed(2);

    const onConfirmTrial = async () => {
        const trialEndDate = new Date();
        trialEndDate.setDate(trialEndDate.getDate() + 14);
        const trial_end = Math.floor(trialEndDate.getTime());

        const subscriptionData = {
            accountType: 'Professional',
            trialActive: true,
            trialEndDate: trial_end,
            subscriptionEndDate: trial_end,
            cancelled: false,
        };

        const updatedData = await UserApi.updateClinicianData(authStore.idToken, { subscriptionData });

        // Update userDataStore with the new subscription details
        userDataStore.setUserData({
            ...userDataStore.getUserData(),
            subscriptionData,
        });
        localStorage.setItem('trialSuccess', 'true');
        authStore.checkAndSetAuthState(userDataStore.userData.emailAddress, authStore.idToken);
        KlaviyoApi.updateUserInKlaviyo(authStore.idToken, userDataStore.userData.emailAddress, 'Pro Plan Trial 14d');
    };

    const handleCardClick = () => {
        if (showSelect) {
            setShowTrialDialog(true);
        }
    };

    const handleClose = (e) => {
        if (e) {
            e.stopPropagation();
        }
        setShowTrialDialog(false);
    };

    return (
        <Box
            onClick={handleCardClick}
            sx={{
                cursor: 'pointer',
                background: isCurrentPlan ? 'linear-gradient(to right, #ff4081, #ff80ab)' : 'transparent',
                borderRadius: '20px',
                padding: isCurrentPlan ? '5px' : '0',
                my: 3,
                ...sx,
            }}
        >
            <Box
                className="settings-block"
                sx={{
                    backgroundColor: showSelect ? '#000453' : 'white',
                    borderRadius: '18px',
                    padding: 3,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                    }}
                >
                    <Box sx={{ textAlign: 'left' }}>
                        {isCurrentPlan && (
                            <Typography variant="body2" sx={{ mb: 0.5 }}>
                                Current plan
                            </Typography>
                        )}
                        <Typography variant="h6" sx={{ fontWeight: 700, color: showSelect ? 'white' : '#000453' }}>
                            {plan}
                        </Typography>
                        {isCurrentPlan && (
                            <Typography variant="body2" sx={{ mb: 0.5 }}>
                                {userDataStore?.subscriptionData?.cancelled
                                    ? 'Ends '
                                    : userDataStore?.subscriptionData?.trialActive
                                      ? 'Free trial ends '
                                      : 'Renews '}
                                {new Date(userDataStore?.subscriptionData?.subscriptionEndDate).toLocaleDateString(
                                    'en-GB',
                                    { day: '2-digit', month: 'long', year: 'numeric' },
                                )}
                            </Typography>
                        )}
                    </Box>
                    {priceMonthly > 0 && !location.pathname.endsWith('/settings') && (
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body1" sx={{ textDecoration: 'line-through' }}>
                                £
                                {billingCycle === 'monthly'
                                    ? priceMonthly.toFixed(2) + '/month'
                                    : priceAnnually.toFixed(2) + '/year'}
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 600, color: '#ff4081' }}>
                                £
                                {billingCycle === 'monthly'
                                    ? discountedPrice(priceMonthly, discount) + '/month'
                                    : discountedPrice(priceAnnually, discount) + '/year'}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Divider sx={{ my: 1, backgroundColor: showSelect ? 'white' : 'initial' }} />
                <List
                    sx={{
                        columns: isMobile ? 1 : 2,
                        columnGap: '16px',
                        p: 0,
                        listStyleType: 'disc',
                        listStylePosition: 'inside',
                    }}
                >
                    {benefits.map((benefit, index) => (
                        <ListItem key={index} sx={{ display: 'list-item', p: 0, pt: 0.5, typography: 'body2' }}>
                            {benefit}
                        </ListItem>
                    ))}
                </List>
                {location.pathname.endsWith('settings') && (
                    <BlueButton onClick={() => navigate('./manage-subscription')}>Manage subscription</BlueButton>
                )}
                {showSelect && (
                    <PinkGradientButton onClick={() => setShowTrialDialog(true)} sx={{ mb: 0 }}>
                        Select
                    </PinkGradientButton>
                )}
            </Box>

            <ConfirmationDialog
                open={showTrialDialog}
                handleClose={handleClose}
                onConfirm={onConfirmTrial}
                title="Great choice! Let's get started!"
                message="Rest assured we won't take any payment now. We will remind you 7 days before your trial ends."
                confirmText="Start trial now"
                cancelText="No, go back"
            />
        </Box>
    );
};

export default SubscriptionCard;
