import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

function UnsavedChangesModal({ open, onDiscardChanges, onCancel }) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have unsaved changes. Are you sure you want to leave?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onDiscardChanges} color="primary">
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnsavedChangesModal;
