import React, { useContext } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CheckListTickIcon from '../../../assets/icons/ChecklistTick.svg';
import EditIcon from '../../../assets/icons/Edit.png';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import { practiceOrClinicText } from '../../../config';

const CustomProgressBar = ({ currentStep, userData, isCustomOnboarding }) => {
  const { userDataStore} = useContext(StoreContext);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const steps = isCustomOnboarding
    ? ["Personal details", "Password", "Verification"] 
    : userData.subscriptionData?.accountType === 'Student' || userDataStore?.subscriptionData?.accountType === 'Student'
      ? ["Personal details", "Password", "Verification", "Choose plan"]
      : ["Personal details", `${practiceOrClinicText[0].toUpperCase() + practiceOrClinicText.slice(1)}  details`, "Password", "Verification", "Choose plan"];

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mt={5} mb={10} position="relative">
      {currentStep > 0 && 
        steps.map((step, index) => (
          <React.Fragment key={index}>
            <Box display="flex" flexDirection="column" alignItems="center" width={`${100 / steps.length}%`}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={26}
                height={26}
                borderRadius="50%"
                bgcolor={currentStep >= index + 1 ? 'none' : '#4C4C4C'}
                color="white"
                zIndex={1}
              >
                {currentStep === index + 1 ? (
                  <img src={EditIcon} alt="Current" width={26} />
                ) : currentStep > index + 1 ? (
                  <img src={CheckListTickIcon} alt="Completed" />
                ) : (
                  <Typography variant="body2">{index + 1}</Typography>
                )}
              </Box>
              {!isSmallScreen && (
                <Typography variant="body2" color={currentStep >= index + 1 ? 'textPrimary' : 'textSecondary'} mt={1}>
                  {step}
                </Typography>
              )}
            </Box>
            {index < steps.length - 1 && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '10%',
                  left: `${(index + 1) * (100 / steps.length)}%`,
                  width: `calc(${100 / steps.length}% - 30px)`,
                  height: '2px',
                  backgroundColor: currentStep > index + 1 ? 'primary.main' : 'grey.300',
                  zIndex: 0,
                  transform: 'translateX(-50%)'
                }}
              />
            )}
          </React.Fragment>
        ))
      }
    </Box>
  );
};

export default CustomProgressBar;
