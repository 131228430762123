import Dexie from 'dexie';

export default class StoreUtils {

  static STORE_PREFIX = "AnatomyStuff";

  static APP_TYPE = process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'Vet' : 'Physio'

  static APP_ENV = (() => {
    const url = process.env.REACT_APP_USER_API_URL || '';
    if (url.includes('dev')) return 'dev';
    if (url.includes('staging')) return 'staging';
    if (url.includes('prod')) return 'prod';
    return 'dev';
  })();

  static generateStoreName(userId, storeName) {
    return `${this.STORE_PREFIX}-${userId}-${StoreUtils.APP_TYPE}-${StoreUtils.APP_ENV}-${storeName}`;
  }

  static createDexieUserDB(userId, storeName) {
    const dbName = StoreUtils.generateStoreName(userId, storeName);
    console.log(`createDexieUserStore dbName: ${dbName}`);
    return new Dexie(dbName);
  }

} // StoreUtils