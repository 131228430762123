import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, TextField, Divider, LinearProgress, Button, Link } from '@mui/material';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import UserApi from '../../../rest-api/user-api';
import { useSnackbar } from '../SnackbarProvider';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';

const SettingsMultiUserAdmin = ({ clinicianData }) => {
  const [newEmailInvite, setNewEmailInvite] = useState('');
  const [data, setData] = useState(clinicianData); 
  const [seatsUsed, setSeatsUsed] = useState(clinicianData?.multiuserData?.multiuserUsers.length || 0);
  const showSnackbar = useSnackbar();
  const { authStore } = useContext(StoreContext);
  const [openDialog, setOpenDialog] = useState(false); 
  const [selectedUserToBeRemoved, setSelectedUserToBeRemoved] = useState(null);

  const maxSeats = 4;
  console.warn(clinicianData?.multiuserData)

  const fetchClinicianData = async () => {
    try {
      const result = await UserApi.getClinicianData(authStore.idToken); 
      setData(result);
      const usedSeats = result?.multiuserData?.multiuserUsers ? result.multiuserData.multiuserUsers.length : 0;
      setSeatsUsed(usedSeats);
    } catch (error) {
      console.error("Error fetching clinician data:", error);
      showSnackbar("Failed to fetch data. Please try again.", "error");
    }
  };

  const handleEmailInviteChange = (e) => {
    setNewEmailInvite(e.target.value);
  };

  const handleSendInvite = async () => {
    try {
      if (newEmailInvite && seatsUsed < maxSeats) {
        await UserApi.updateClinicianDataMultiuser(authStore.idToken, {
          action: "createInvitation",
          emailAddress: newEmailInvite,
        });
        showSnackbar("Invitation sent successfully!");
        await fetchClinicianData(); 
      } else {
        showSnackbar("You have used all available seats!");
      }
    } catch (error) {
      console.error("Error sending invite:", error);
      showSnackbar("Failed to send invitation. Please try again.", "error");
    }
  };
  
  const confirmRemoveUser = async () => {
    try {
      if (selectedUserToBeRemoved) {
        await UserApi.updateClinicianDataMultiuser(authStore.idToken, {
          action: "removeMultiuser",
          inviteId: selectedUserToBeRemoved.inviteId,
          emailAddress: selectedUserToBeRemoved.emailAddress,
        });
        showSnackbar("You have successfully removed that user.");
        setOpenDialog(false); 
        await fetchClinicianData(); // Refresh data
      }
    } catch (error) {
      console.error("Error removing user:", error);
      showSnackbar("Failed to remove user. Please try again." , "error");
    }
  };

  const handleOpenRemoveDialog = (user) => {
    setSelectedUserToBeRemoved(user); 
    setOpenDialog(true); 
  };
  
  const handleResendLink = async (inviteId, emailAddress) => {
    try {
      await UserApi.updateClinicianDataMultiuser(authStore.idToken, {
        action: "resendInvite",
        inviteId: inviteId,
        emailAddress: emailAddress,
      });
      showSnackbar("Invitation link resent!");
    } catch (error) {
      console.error("Error resending link:", error);
      showSnackbar("Failed to resend link. Please try again.", "error");
    }
  };
  
  const seatUsagePercentage = (seatsUsed / maxSeats) * 100;

  const renderUserStatus = (user) => {
    let statusText;
    let statusColor;

    switch (user.inviteStatus) {
      case 'active':
        statusText = 'Active';
        statusColor = 'green';
        break;
      case 'pending':
        statusText = 'Pending';
        statusColor = 'orange';
        break;
      case 'link expired':
        statusText = 'Link expired';
        statusColor = 'red';
        break;
      default:
        statusText = '';
        statusColor = 'black';
    }

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' ,alignItems: 'center', gap: 2 }}>
        <Button 
          disableRipple
          sx={{ 
            color: statusColor, 
            textTransform: 'none', 
            fontWeight: 'bold',
            padding: 2,
            borderColor: statusColor,
            borderRadius: 2,
            width: 80,
            height: 26
          }}
        >
          {statusText}
        </Button>
        {user.inviteStatus === 'link expired' && (
          <Link 
            onClick={() => handleResendLink(user.inviteId, user.emailAddress)} 
            sx={{ 
              borderRadius: '36px', 
              height: 48, 
              color: '#000453', 
              textAlign: 'center', 
              textTransform: 'none' 
            }}
            disableRipple
          >
            Re-send link
          </Link>
        )}
        <Button 
          onClick={() => handleOpenRemoveDialog(user)} 
          sx={{ textTransform: 'none', color: 'red', padding: 0, textDecoration: 'underline' }}
          disableRipple
        >
          Remove
        </Button>
      </Box>
    );
  };

  return (
    <Box
      className='settings-block'
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '16px 24px',
        pb: 0,
        mb: 0,
        my: 3,
      }}
    >
      <Box sx={{ mb: 3 , position: "relative"  }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
          Seats
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 , pb: 2 , position: "relative" }}>
          Send invitations to members of your team to start collaborating in the workspace
          <Divider
            sx={{
              width: 'calc(100% + 48px)', 
              position: 'absolute',
              bottom: 0,
              left: '-24px', 
              backgroundColor: 'rgba(229, 229, 229, 1)',
            }}
          />
        </Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255, 0, 128, 1)', mb: 2 }}>
          {seatsUsed} of {maxSeats} seats used
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={seatUsagePercentage} 
          sx={{
            height: 8,
            borderRadius: 4,
            backgroundColor: '#F7F7F7',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'rgba(255, 0, 128, 1)',
            },
            mb: 2,
          }}
        />
        <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' }, gap: 2, mb: 2 , pb: 2, flexDirection: { xs: 'column', sm: 'row' }  }}>
          <TextField
            placeholder="Enter email address"
            value={newEmailInvite}
            onChange={handleEmailInviteChange}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 56,
                borderRadius: 2,
                backgroundColor: '#F7F7F7',
                '& fieldset': {
                  borderColor: 'transparent',
                },
              },
            }}
          />
          <PinkGradientButton
            className='black-btn'
            onClick={handleSendInvite}
            sx={{
              borderRadius: 6,
              paddingX: 3,
              color: '#FFF',
            }}
          >
            Send invite
          </PinkGradientButton>
        </Box>
        <Divider
          sx={{
            width: 'calc(100% + 48px)', 
            position: 'absolute',
            bottom: 0,
            left: '-24px', 
            backgroundColor: 'rgba(229, 229, 229, 1)',
          }}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, pb: 2,  position: "relative"  }}>
          Team members
          {
            data?.multiuserData?.multiuserUsers.length  && 
            <Divider
              sx={{
                width: 'calc(100% + 48px)', 
                position: 'absolute',
                bottom: 0,
                left: '-24px', 
                backgroundColor: 'rgba(229, 229, 229, 1)',
              }}
            />  
          }
        </Typography>
        <Box sx={{ color: '#666' }}>
          {data?.multiuserData?.multiuserUsers?.map((user, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, pb: 2, position: 'relative', flexWrap: 'wrap' }}>
              <Typography>
                {user.emailAddress}
                {user.firstName || user.lastName ? ` (${user.firstName} ${user.lastName})` : ''}
              </Typography>
              {renderUserStatus(user)}
              {index !== data?.multiuserData?.multiuserUsers.length - 1 && (
                <Divider
                  sx={{
                    width: 'calc(100% + 48px)', 
                    position: 'absolute',
                    bottom: 0,
                    left: '-24px', 
                    backgroundColor: 'rgba(229, 229, 229, 1)',
                  }}
                />
              )}
            </Box> 
          ))}
        </Box>
      </Box>

      <ConfirmationDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        onConfirm={confirmRemoveUser}
        title="Are you sure you want to permanently delete this member from your clinic?"
        message="Please note this will delete ALL associated data with that user including their saved handouts!"
        confirmText="Yes, continue"
        cancelText="No, go back"
      />
    </Box>
  );
};

export default SettingsMultiUserAdmin;
