import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PinkGradientButton from './PinkGradientButton';
import CheckListTickIcon from '../../../src/assets/icons/ChecklistTick.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CTAUpgradeDialog = ({ 
  open, 
  handleClose, 
  onConfirm, 
  title = "Are you sure?", 
  message = "Are you sure you want to do something?",
  buttonText = "Do something",
  imageUrl = "path_to_your_image.jpg",
  benefits = [],
  isLocked = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery('(max-width:400px)'); 

  return (
    <Dialog 
      open={open} 
      onClose={isLocked ? undefined : handleClose} // Disable onClose if isLocked is true
      PaperProps={{
        sx: {
          backgroundColor: '#000453',
          height: isMobile ? 'auto' : 603,
          width: 711,
          borderRadius: 3,
          overflow: 'hidden',
          position: 'relative'
        },
      }}
    >
      {/* Only show close button if isLocked is false */}
      {!isLocked && (
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: isMobile ? 'white' : 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {!isMobile && (
        <Box 
          sx={{ 
            height: 222, 
            backgroundImage: `url(${imageUrl})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center' 
          }} 
        />
      )}
      <Box 
        sx={{ 
          height: isMobile ? 'auto' : 381, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'space-between', 
          padding: 3,
        }}
      >
        <DialogTitle 
          sx={{ 
            color: 'white', 
            padding: 0, 
            mb: 2, 
            fontSize: isSmallScreen ? 24 : 32, 
            lineHeight: isSmallScreen ? '28px' : '36px' 
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText 
            sx={{ 
              color: 'white', 
              mb: 2, 
              fontSize: isSmallScreen ? 12 : 16, 
            }}
          >
            {message}
          </DialogContentText>
          {benefits.length > 0 && (
            <Box sx={{ mb: 2 }}>
              {benefits.map((benefit, index) => (
                <Box 
                  key={index} 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 1 
                  }}
                >
                  <img 
                    src={CheckListTickIcon} 
                    alt="Checkmark" 
                    style={{ width: 20, height: 20, marginRight: 8 }} 
                  />
                  <DialogContentText 
                    sx={{ 
                      color: 'white', 
                      fontSize: isSmallScreen ? 10 : 16 
                    }}
                  >
                    {benefit}
                  </DialogContentText>
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 0, justifyContent: 'flex-start'}}>
          <PinkGradientButton
            onClick={() => {
              onConfirm();
              if (!isLocked) handleClose(); // Only close if isLocked is false
            }}
            color="primary"
          >
            {buttonText}
          </PinkGradientButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CTAUpgradeDialog;
