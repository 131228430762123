import RestAPIUtils from "./rest-api-utils";

class PaymentApi {

  static PAYMENT_API_CONFIG = {
    apiUrl: process.env.REACT_APP_USER_API_URL,
    apiKey: process.env.REACT_APP_USER_API_KEY
  };

  // create or update payment details (e.g., billing information) and handle subscription if given
  static async updatePaymentDetails(bearerToken, payload) {
    const apiPath = `/v1/user/payment`;
    console.log(`updatePaymentDetails payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, PaymentApi.PAYMENT_API_CONFIG, apiPath, 'POST', payload);
  }

  // get payment details, user default payment method and all subscriptions from stripe
  static async getPaymentDetails(bearerToken, stripeCustomerId) {
    const apiPath = `/v1/user/payment/current`;
    console.log(`getPaymentDetails request with stripeCustomerId: ${stripeCustomerId}`);
    
    const params = { stripeCustomerId: stripeCustomerId };

    return RestAPIUtils.makeGetRequest(bearerToken, PaymentApi.PAYMENT_API_CONFIG, apiPath, params);
  }

  // confirm 3DS check if requires_action is true
  static async confirmPaymentDetails(bearerToken, payload) {
    const apiPath = `/v1/user/payment/confirm`;
    console.log(`confirmPaymentDetails payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, PaymentApi.PAYMENT_API_CONFIG, apiPath, 'POST', payload);
  }

  // ends current subscription to new one with prorated payment applied immediately
  static async updateSubscription(bearerToken, payload) {
    const apiPath = `/v1/user/payment/update`;
    console.log(`updateSubscription payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, PaymentApi.PAYMENT_API_CONFIG, apiPath, 'POST', payload);
  }

  // ends current subscription, can still use account until end of subscriptionEndDate
  static async cancelSubscription(bearerToken, payload) {
    const apiPath = `/v1/user/payment/cancel`;
    console.log(`cancelSubscription payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, PaymentApi.PAYMENT_API_CONFIG, apiPath, 'POST', payload);
  }

  // checks if promo code is valid
  static async validatePromo(bearerToken, payload) {
    const apiPath = `/v1/user/payment/validate-promo`;
    console.log(`validatePromo payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, PaymentApi.PAYMENT_API_CONFIG, apiPath, 'POST', payload);
  }
}

export default PaymentApi;
