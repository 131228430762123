import { makeObservable, action, observable, runInAction } from 'mobx';
import FuseWrapper from './fuse-wrapper';
import StoreUtils from './store-utils';
import CategoryManager from './category-manager';

// contentData is ultimately a map of contentId to contentData
class ContentStore {
    contentData = {};
    mostRecentLastUpdated = null;
    fuseWrapper;
    categoryManager;
    db;
    isInitialized = false;

    constructor(userId) {
        console.log(`ContentStore constructor userId: ${userId}`);

        // Initialize Dexie
        this.db = StoreUtils.createDexieUserDB(userId, 'ContentStore');

        this.db.version(1).stores({
            content: 'contentId, contentData',
        });

        // Dexie migration example
        // this.db.version(2).stores({
        //   content: 'contentId, contentData, newField',
        // }).upgrade(tx => {
        //   return tx.table('content').toCollection().modify(content => {
        //     console.log('ContentStore upgrade to version 2');
        //     content.newField = 'defaultValue'; // or some calculated value
        //   });
        // });

        makeObservable(this, {
            contentData: observable,
            mostRecentLastUpdated: observable,
            setContentData: action.bound,
            isInitialized: observable,
        });
    }

    async initialize() {
        console.log('ContentStore initialize');
        const storedData = await this.db.content.toArray();
        console.log('Current DB Version:', this.db.verno);
        if (storedData && storedData.length > 0) {
            this._setInternalData(storedData.map((item) => item.contentData));
        } else {
            console.log('ContentStore initialize no storedData');
            runInAction(() => {
                this.isInitialized = true;
            });
        }
    }

    async setContentData(data) {
        this._setInternalData(data);

        const mappedData = data.map((item) => ({
            contentId: item.contentId,
            contentData: item,
        }));

        // Save to IndexedDB
        try {
            await this.db.content.bulkPut(mappedData);
        } catch (error) {
            console.error('Dexie error: ', error);
        }
    }

    isContentAvailable(item) {
        // Assuming 'isDeleted' is false if not present
        const isDeleted = item.isDeleted || false;
        return !isDeleted && item.contentStatus === 'published';
    }

    _setInternalData(dataArray) {
        // Run mobx actions to modify observables
        runInAction(() => {
            // Process snapshot
            dataArray.forEach((item) => {
                if (this.isContentAvailable(item)) {
                    this.contentData[item.contentId] = item;
                }
            });

            // Process diff
            dataArray.forEach((item) => {
                if (!this.isContentAvailable(item)) {
                    console.log(`_setInternalData deleting contentId: ${item.contentId}`);
                    delete this.contentData[item.contentId];
                }
            });

            // If dataArray is not empty, find the most recent 'lastUpdated'
            if (dataArray.length > 0) {
                // log the first item
                console.log(`_setInternalData dataArray[0]: ${JSON.stringify(dataArray[0], null, 2)}`);
                const mostRecent = Math.max(...dataArray.map((item) => item.lastUpdated));
                this.mostRecentLastUpdated = mostRecent;
            }

            console.log(`_setInternalData contentData length: ${Object.keys(this.contentData).length}`);
            if (dataArray.length > 0) {
                console.log(`_setInternalData mostRecentLastUpdated: ${this.mostRecentLastUpdated}`);
            }
            this.fuseWrapper = new FuseWrapper(this.contentData);
            this.categoryManager = new CategoryManager(this.contentData);
            this.categoryManager.categorizeContent();
            // this.categoryManager.logCategorizedContent();
            this.isInitialized = true;
        });
    }

    hasContent() {
        return this.contentData && Object.keys(this.contentData).length !== 0;
    }

    async clearStore() {
        console.log('Clearing IndexedDB content store...');
        await this.db.content.clear();
    }

    async deleteDatabase() {
        console.log('Deleting IndexedDB database...');
        await this.db.delete();
    }

    searchContentMeta(searchTerm) {
        if (this.isInitialized && this.fuseWrapper) return this.fuseWrapper.searchContentMeta(searchTerm);
        return [];
    }

    getContentFromIds(ids) {
        return ids.map((id) => this.contentData[id]).filter(Boolean);
    }

    getFeaturedContent() {
        let contentData = Object.values(this.contentData);
        return contentData.filter((content) => content.featured);
    }

    searchAndRetrieveContent(searchTerm) {
        const metas = this.searchContentMeta(searchTerm);
        return this.getContentFromMetas(metas);
    }

    getCategoriesData() {
        if (this.isInitialized && this.categoryManager)
            // console.warn("content-store, this is category data: ",this.categoryManager.parsedData)
            return this.categoryManager.parsedData;
        return {};
    }

    getAlphabeticallySortedContent(categoryData = null, filter = '') {
        const sortedContent = {};
        let contentValues = Object.values(categoryData || this.contentData);

        // some filters work by finding it as a deep nested category or simply in the contentName
        if (['Small Animals', 'Large Animals', 'Small mammals'].includes(filter)) {
            contentValues = contentValues.filter((content) => this.checkCategory(content.categories, filter));
        } else if (filter) {
            contentValues = contentValues.filter((content) =>
                content.contentName.toLowerCase().includes(filter.toLowerCase()),
            );
        }

        // Adjusted sorting function
        const sortedValues = contentValues.sort((a, b) => {
            const nameA = this.getSortableName(a);
            const nameB = this.getSortableName(b);
            return nameA.localeCompare(nameB);
        });

        // Grouping sorted content by first letter
        sortedValues.forEach((content) => {
            const sortingName = this.getSortableName(content);
            const firstLetter = sortingName[0].toUpperCase();
            if (!sortedContent[firstLetter]) {
                sortedContent[firstLetter] = [];
            }
            sortedContent[firstLetter].push({
                contentName: content.contentName,
                alphabeticalName: content.alphabeticalName,
                contentId: content.contentId,
            });
        });

        return sortedContent;
    }

    // Helper function to get the name for sorting
    getSortableName(content) {
        const fullTitle = content.alphabeticalName || content.contentName;
        return fullTitle.replace(/^Canine\s|^Feline\s|^Equine\s|^Guinea\s/, '');
    }

    // Recursive function to check if a category exists within nested structure
    checkCategory(categoryObject, filter) {
        if (typeof categoryObject !== 'object' || !categoryObject) return false;
        for (const key of Object.keys(categoryObject)) {
            if (key === filter) return true;
            if (this.checkCategory(categoryObject[key], filter)) return true;
        }
        return false;
    }
}

// This is now an asynchronous factory function
const createContentStore = async (userId) => {
    const store = new ContentStore(userId);
    await store.initialize(); // Wait for the store to be initialized
    return store;
};

export default createContentStore;
