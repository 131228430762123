import React, { useEffect } from 'react';

// Dynamically load the Sketchfab script when the component is mounted
const loadSketchfabScript = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://static.sketchfab.com/api/sketchfab-viewer-1.12.1.js';
        script.onload = resolve;
        document.body.appendChild(script);
    });
};

const Model3DViewerPopup = ({ uid, alt }) => {
    useEffect(() => {
        let sketchfabClient;

        const initSketchfabViewer = async () => {
            await loadSketchfabScript();
            const iframe = document.getElementById(`api-frame-${uid}`);
            sketchfabClient = new window.Sketchfab(iframe);

            sketchfabClient.init(uid, {
                success: function (api) {
                    api.start();
                    api.addEventListener('viewerready', function () {
                        console.log('Viewer is ready');
                    });
                },
                error: function () {
                    console.log('Viewer error');
                },
                ui_general_controls: 0,
            });
        };

        initSketchfabViewer();

        return () => {
            if (sketchfabClient) {
                sketchfabClient = null;
            }
        };
    }, [uid]);

    return (
        <div style={{ width: '100%', borderRadius: '16px', overflow: 'hidden' }}>
            <div className="iframe-container" style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                <iframe
                    id={`api-frame-${uid}`}
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                    title={alt}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '16px',
                    }}
                ></iframe>
            </div>
        </div>
    );
};

export default Model3DViewerPopup;
