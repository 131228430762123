import { makeAutoObservable, runInAction } from 'mobx';
import Dexie from 'dexie';
import StoreUtils from './store-utils';
import { toJS } from 'mobx';

export const UserDataState = Object.freeze({
  NO_DATA: 0,
  HAVE_NAME_AND_EMAIL: 1,
  HAVE_CLINIC_NAME: 2,
  HAVE_CLINIC_IMAGE: 4,
  DATA_SYNCED_TO_SERVER: 8,
});

class UserDataStore {
  userDataState = UserDataState.NO_DATA; // Start with no data
  userId = '';
  userData = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    mobile: '',
    clinicName: '',
    clinicNumber: '',
    clinicSize: '',
    clinicImageId: '', // Store base64 encoded image as string
    showLogoInHandouts: true,
    handouts: '[]'
  };
  lastUpdated = Date.now(); // Initialize with the current timestamp
  subscriptionData = {
    accountType: "",
    trialActive: false,
    trialEndDate: null,
    subscriptionEndDate: null,
    stripeCustomerId: null,
    cancelled: null
  }; 
  db;
  isInitialized = false;

  constructor(userId) {
    this.userId = userId;
    // Initialize Dexie
    this.db = StoreUtils.createDexieUserDB(userId, 'UserDataStore');
    this.db.version(1).stores({
      user: '&key', // Use '&key' for unique records
    });

    makeAutoObservable(this);
  }

  async initialize() {
    const userRecord = await this.db.user.get('userData');
    runInAction(() => {
      if (userRecord) {
        this.userDataState = userRecord.userDataState;
        this.userData = userRecord.userData;
        this.lastUpdated = userRecord.lastUpdated;
        this.subscriptionData = userRecord.subscriptionData || {
          accountType: "",
          trialActive: false,
          trialEndDate: null,
          subscriptionEndDate: null,
          stripeCustomerId: null,
          cancelled: null
        }; 
      }
      this.isInitialized = true;
    });
  }

  setUserData(data) {
    const currentTime = Date.now();
    runInAction(() => {
      // Separate subscriptionData if it exists in the data being set
      const { subscriptionData, ...restUserData } = data;

      if (restUserData.firstName && restUserData.lastName && restUserData.emailAddress) {
        this.userDataState |= UserDataState.HAVE_NAME_AND_EMAIL;
      }
      if (restUserData.clinicName) {
        this.userDataState |= UserDataState.HAVE_CLINIC_NAME;
      }
      if (restUserData.clinicImageId) {
        this.userDataState |= UserDataState.HAVE_CLINIC_IMAGE;
      }
      console.warn('before userData', toJS(this));

      // Combine existing userData with the incoming userData
      this.userData = { ...this.userData, ...restUserData };

      // Update subscriptionData if it was provided
      if (subscriptionData) {
        this.subscriptionData = { ...this.subscriptionData, ...subscriptionData };
      }

      console.warn('updated userData', toJS(this));
      this.lastUpdated = currentTime;

      // Clone data to ensure it's serializable
      const clonedUserData = JSON.parse(JSON.stringify(this.userData));
      const clonedSubscriptionData = JSON.parse(JSON.stringify(this.subscriptionData));

      // Update the single user record in IndexedDB
      this.db.user.put({
        key: 'userData',
        userId: this.userId,
        userDataState: this.userDataState,
        userData: clonedUserData,
        lastUpdated: this.lastUpdated,
        subscriptionData: clonedSubscriptionData // Ensure subscriptionData is a plain object
      }).catch(error => {
        console.error('Error storing userData in IndexedDB:', error);
      });
    });
  }

  updateUserHandouts(handouts) {
    const currentTime = Date.now();
    runInAction(() => {
      // Update the handouts field
      this.userData.handouts = JSON.stringify(handouts)

      // Update the lastUpdated timestamp
      this.lastUpdated = currentTime;

      // Clone data to ensure it's serializable
      const clonedUserData = JSON.parse(JSON.stringify(this.userData));
      const clonedSubscriptionData = JSON.parse(JSON.stringify(this.subscriptionData));

      // Update the single user record in IndexedDB
      this.db.user.put({
        key: 'userData',
        userId: this.userId,
        userDataState: this.userDataState,
        userData: clonedUserData,
        lastUpdated: this.lastUpdated,
        subscriptionData: clonedSubscriptionData // Ensure subscriptionData is a plain object
      }).catch(error => {
        console.error('Error storing userData in IndexedDB:', error);
      });
    });
  }

  getUserData() {
    // Return a copy to prevent direct modifications
    return {
      ...this.userData,
      userDataState: this.userDataState,
      lastUpdated: this.lastUpdated,
      subscriptionData: { ...this.subscriptionData } // Return a plain object
    };
  }

  async clearUserData() {
    await this.db.user.delete('userData');
    runInAction(() => {
      this.userDataState = UserDataState.NO_DATA;
      this.userData = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        mobile: '',
        clinicName: '',
        clinicNumber: '',
        clinicSize: '',
        clinicImageId: '', // Store base64 encoded image as string
        showLogoInHandouts: true,
        handouts: '[]'
      };
      this.subscriptionData = { // Ensure subscriptionData is reset correctly
        accountType: "",
        trialActive: false,
        trialEndDate: null,
        subscriptionEndDate: null,
        stripeCustomerId: null,
        cancelled: null
      };
      this.lastUpdated = Date.now();
    });
  }

  async deleteDatabase() {
    await this.db.delete();
  }
}

const createUserDataStore = async (userId) => {
  const store = new UserDataStore(userId);
  await store.initialize(); // Wait for the store to be initialized
  return store;
};

export default createUserDataStore;
