import React from 'react';
import { Box, Button, Typography, Container, useMediaQuery, useTheme, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { surveyUrl } from '../../../config';

export function Feedback(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const navigateToFeedback = () => {
        navigate('/feedback');
    };

    const navigateToSurvey = () => {
        window.open(surveyUrl, '_blank');
    };

    const SurveyBoxContent = () => (
        <Box
            bgcolor="#000453"
            p={3}
            borderRadius={2}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            height="auto"
            gap={2}
            {...props}
        >
            <Box sx={{ flex: 1, alignSelf: theme.breakpoints.down('md') ? 'flex-start' : 'initial' }}>
                <Typography
                    variant="h4"
                    align="left"
                    color="white"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.5rem',
                        },
                    }}
                >
                    What comes next?
                </Typography>
                <Typography
                    variant="h4"
                    align="left"
                    color="white"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.5rem',
                        },
                    }}
                >
                    You decide.
                </Typography>
                <Typography color="white" mt={1}>
                    Share your insights so far and what features you'd like to see in the future.
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    endIcon={<ArrowForwardIcon />}
                    onClick={navigateToSurvey}
                    variant="contained"
                    sx={{
                        backgroundColor: '#292F68',
                        padding: '20px 24px',
                        borderRadius: '36px',
                        width: 200,
                    }}
                >
                    Complete survey
                </Button>
            </Box>
        </Box>
    );

    const FeedbackBoxContent = () => (
        <Box
            bgcolor="#000453"
            p={3}
            borderRadius={2}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            height="auto"
            gap={0}
            {...props}
        >
            <Box sx={{ flex: 1, alignSelf: theme.breakpoints.down('md') ? 'flex-start' : 'initial' }}>
                <Typography
                    variant="h4"
                    align="left"
                    color="white"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.5rem',
                        },
                    }}
                >
                    Suggest a handout
                </Typography>
                <Typography
                    variant="h4"
                    align="left"
                    color="white"
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            fontSize: '1.5rem',
                        },
                    }}
                >
                    condition or topic!
                </Typography>
                <Typography color="white" mt={1}>
                    Please share any content amendments, illustration ideas and new topics with us!
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    endIcon={<ArrowForwardIcon />}
                    onClick={navigateToFeedback}
                    variant="contained"
                    sx={{
                        backgroundColor: '#292F68',
                        padding: '20px 24px',
                        borderRadius: '36px',
                        width: 200,
                    }}
                >
                    Give feedback
                </Button>
            </Box>
        </Box>
    );

    return (
        <Container component={'section'} maxWidth={isMobileOrTablet ? 'md' : 'xl'} sx={{ position: 'relative', pt: 4 }}>
            <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
                <Grid item xs={12} md={6}>
                    <SurveyBoxContent />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FeedbackBoxContent />
                </Grid>
            </Grid>
        </Container>
    );
}
