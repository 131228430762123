import React from 'react';
import { Container, Typography, Box, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import arrowWhiteRight from '../../../assets/icons/Arrow-white_Right.svg';
import cetraWhiteLogo from '../../../assets/logos/cetra_white.png';
import OnboardHeader from './onboard-header'; 
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import CustomProgressBar from './CustomProgressBar'; 

export function OnboardingLayout({ 
  title, 
  subtitle,
  inputChildren, 
  navChildren, 
  currentStep, 
  totalSteps: totalStepsProp,
  goBack,
  userData,
  previousStepPath,
  backgroundColor = true
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation(); 
  const searchParams = new URLSearchParams(location.search);
  const inviteId = searchParams.get('inviteId');
  const senderId = searchParams.get('senderId');
  const isCustomOnboarding = inviteId && senderId;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine total steps
  const totalSteps = isCustomOnboarding ? 3 : (totalStepsProp || (userData?.subscriptionData?.accountType === 'Student' ? 4 : 5));

  const handleBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigate(previousStepPath, { state: { userData } });
    }
  };

  return (
    <Box className="onboarding-container" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <OnboardHeader />
      <Box sx={{ flexGrow: 1 }}>
        <CenteredWrapper component="main" sx={{ flexGrow: 1, minHeight: 400, height: '100%', width: isMobile ? "90%" : "100%", maxWidth: 638, p: 0, margin: "0 auto", mb: 10 }}>
          <CustomProgressBar currentStep={currentStep} userData={userData} totalSteps={totalSteps} isCustomOnboarding={isCustomOnboarding}/> 
          <Box mb={4} sx={{ [theme.breakpoints.down('sm')]: { marginBottom: 8 } }} width={"100%"} >
            <Typography variant="h4" textAlign="left">
              {title}
            </Typography>
            <Typography variant="subtitle1" textAlign="left" mt={1.5}>
              {subtitle}
            </Typography>
          </Box>
          
          <Box className="onboarding-block" display="flex" flexDirection="column" justifyContent="center" flexGrow={1} width={"100%"} sx={{
              backgroundColor: backgroundColor ? 'white' : 'initial',
              borderRadius: 4,
              padding: backgroundColor ? '16px 24px 16px 24px' : 'initial',
              my: backgroundColor ? 3 : 'initial',
              overflowY: !location.pathname.includes('onboard/code') ? 'auto' : 'initial',           
              pb: 5,
            }}>
            {inputChildren}
          </Box>
        </CenteredWrapper>
      </Box>
      <Box
        component={"footer"}
        gap={2}
        sx={{
          backgroundColor: "white",
          bottom: 0,
          width: "100%",
          zIndex: 1,
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: theme.spacing(2),
            width: 'auto',
          }
        }}
      >
        <Box sx={{maxWidth: 638, margin: '0 auto'}}>
          {navChildren}
        </Box>
      </Box>
    </Box>
  );
}
