import React, { useState, useEffect } from 'react';
import ApiLinkText from '../../UIControls/api-button/api-link-text';
import { ca } from 'date-fns/locale';

const ResendVerificationLink = ({ authStore, emailAddress }) => {
  const maxRetries = 3;
  const [retryCount, setRetryCount] = useState(0);
  const [waitTime, setWaitTime] = useState(10); // Initial wait time in seconds
  const [timer, setTimer] = useState(0);
  const [interimText, setInterimText] = useState('');

  useEffect(() => {
    let interval;
    if (timer > 0) {
      setInterimText(`Resending available in ${timer} seconds...`);
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && retryCount > 0 && retryCount < maxRetries) {
      setInterimText('');
      setWaitTime(prevWaitTime => prevWaitTime + 5); // Increment wait time for the next retry
    }

    return () => clearInterval(interval);
  }, [timer, retryCount]);

  const handleResendVerificationCode = async () => {
    // await new Promise(resolve => setTimeout(resolve, 2000));

    // Temporarily commenting out the actual resend call for testing
    await authStore.resendVerificationCode(emailAddress);

    // Simulate an error
    // throw new Error('Simulated error during resendVerificationCode');
  };

  const contineRetryLogic = () => {
    console.log('contineRetryLogic called');
    if (retryCount < maxRetries) {  
      setRetryCount(prevRetryCount => prevRetryCount + 1);
      if (retryCount < maxRetries - 1) {
        setTimer(waitTime); // Start the countdown only if not the last retry
      } else {
        // For the final retry, set a different interim text
        setInterimText("Resending code unavailable. Please try again later.");
      }
    }
  };

  return (
    <ApiLinkText
      apiCall={handleResendVerificationCode}
      primaryText="Didn't get the code? "
      linkText="Click here to resend"
      progressText="Resending"
      interimText={interimText}
      disabled={retryCount >= maxRetries || timer > 0}
      onErrorConfirm={contineRetryLogic}
      onSuccessConfirm={contineRetryLogic}
      errorTitle="Resend code error"
      errorMessage="There was a problem with resending the code. Please try again."
      successTitle="Resend Successful"
      successMessage="The code was resent successfully."
      sx={{position: "absolute", bottom: "-90px", left: "-20px"}}
    />
  );
};

export default ResendVerificationLink;
