import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress } from "@mui/material";
import ErrorDialog from './ErrorDialog';
import SuccessDialog from './SuccessDialog';

const AnimatedEllipsis = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(dots => (dots.length < 3 ? dots + '.' : ''));
    }, 500); // Adjust the speed as needed

    return () => clearInterval(interval);
  }, []);

  return <span>{dots}</span>;
};

const ApiLinkText = ({
  apiCall,
  onSuccess,
  onError,
  primaryText,
  linkText,
  progressText,
  interimText,
  errorTitle,
  errorMessage,
  successTitle,
  successMessage,
  disabled = false,
  onErrorConfirm = () => { },
  onSuccessConfirm = () => { },
  sx = {},
}) => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const defaultErrorHandler = (error) => {
    setErrorDetails(error.toString());
    setOpenErrorDialog(true);
  };

  const defaultSuccessHandler = () => {
    setOpenSuccessDialog(true);
  };

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const result = await apiCall();
      if (onSuccess) {
        onSuccess(result);
      } else {
        defaultSuccessHandler();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        defaultErrorHandler(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
        <Typography component="span">{primaryText}</Typography>
        <Typography
          component="span"
          sx={{ cursor: 'pointer', textDecoration: 'underline', ml: 1, fontWeight: 700 }}
          onClick={!isLoading && interimText === '' && !disabled ? handleClick : null}
        >
          {isLoading ? (
            <>
              {progressText}<AnimatedEllipsis />
            </>
          ) : interimText !== '' ? interimText : linkText}
        </Typography>
      </Box>

      <ErrorDialog
        open={openErrorDialog}
        handleClose={() => {
          setErrorDetails(null);
          setOpenErrorDialog(false);
          onErrorConfirm();
        }}
        title={errorTitle || "Error"}
        message={errorMessage || "An error occurred."}
        details={errorDetails}
      />

      <SuccessDialog
        open={openSuccessDialog}
        handleClose={() => {
          setOpenSuccessDialog(false);
          onSuccessConfirm();
        }}
        title={successTitle || "Success"}
        message={successMessage || "Your action was successful."}
      />
    </>
  );
};

export default ApiLinkText;
