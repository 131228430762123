import { useContext } from 'react';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import UserApi from '../../../rest-api/user-api';

export function useGenerateLink(handoutData, isBundled = false) {
    const { authStore, userDataStore } = useContext(StoreContext);
    const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE;

    // Process handout data to ensure it is in the correct format for the API call
    const processHandoutData = () => (isBundled ? handoutData.map((data) => data.contentId) : [handoutData.contentId]);

    const generateLink = async (toggleStates = {}) => {
        const contentIds = processHandoutData();
        console.warn('Content IDs to process:', contentIds);

        try {
            const result = await UserApi.generatePatientLink(authStore.idToken, contentIds);
            const userId = userDataStore.userId;
            let linkWithClinicId = `${result.linkId}&clinicId=${userId}`;

            // toggle states
            if (toggleStates) {
                const params = new URLSearchParams(toggleStates).toString();
                linkWithClinicId += `&${params}`;
            }

            // Cache the link generation details
            const dataToCache = {
                linkId: result.linkId,
                linkWithClinicId,
                timestamp: new Date().getTime(),
            };
            localStorage.setItem('contentLink', JSON.stringify(dataToCache));

            return linkWithClinicId; // Return the single linkId with clinicId
        } catch (error) {
            console.error('Error generating link:', error);
            throw new Error('Failed to generate link');
        }
    };

    // passed oggleStates with default {}
    const generateLinkOnly = async (toggleStates = {}) => {
        const linkWithClinicId = await generateLink();
        let fullLink = `${process.env.REACT_APP_PATIENT_HANDOUT_URL}?linkId=${linkWithClinicId}`;

        // If toggles are off, add them to the URL
        const { show3DModel, showDiagram, showTutorialVideo } = toggleStates;
        const toggles = {};
        if (show3DModel === false) toggles.show3DModel = false;
        if (showDiagram === false) toggles.showDiagram = false;
        if (showTutorialVideo === false) toggles.showTutorialVideo = false;

        if (Object.keys(toggles).length > 0) {
            const url = new URL(fullLink);
            Object.keys(toggles).forEach((key) => {
                url.searchParams.append(key, toggles[key]);
            });
            fullLink = url.toString();
        }

        return fullLink;
    };

    const createLinkText = async (toggleStates = {}) => {
        const link = await generateLinkOnly(toggleStates);
        const body = `Your information sheet is ready! Please click on the link below to view it:\n\n${link}`;
        return body;
    };

    const createShareData = async (toggleStates = {}) => {
        const link = await generateLinkOnly(toggleStates);
        const title = `Cetra ${clinicType === 'vet' ? 'Vets' : 'Health'} Information Sheet(s)`;
        const handoutTitle = handoutData.contentName;

        let messageSingleVet = `Please read your ${handoutTitle} handout by clicking the link below. This handout contains valuable information around your pet's condition. If you have any questions about your pet's condition, please don't hesitate to reach out directly - We're here to help! \n\n ${link}`;

        let messageBundledVet = `As discussed in your consultation, these handouts contain valuable information around your pet's condition. \n\n Please take a moment to open and read the attached handouts. If you have any questions about your pet's condition, please don't hesitate to reach out directly to us - We're here to help! \n\n ${link}`;

        let messageSinglePhysio = `Please read your ${handoutTitle} handout by clicking the link below. This handout contains valuable information around your condition. If you have any questions about your condition, please don't hesitate to reach out directly - We're here to help!\n\n ${link}`;

        let messageBundledPhysio = `As discussed in your consultation, these handouts contain valuable information around your condition. \n\n Please take a moment to open and read the attached handouts. If you have any questions about your condition, please don't hesitate to reach out directly to us - We're here to help! \n\n ${link}`;

        let messageSingle = clinicType === 'vet' ? messageSingleVet : messageSinglePhysio;
        let messageBundled = clinicType === 'vet' ? messageBundledVet : messageBundledPhysio;

        const message = isBundled ? messageBundled : messageSingle;

        return { title, url: link, message };
    };

    return { generateLink, generateLinkOnly, createLinkText, createShareData };
}
