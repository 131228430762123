import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const SettingsCancelPlan = ({ onOpenCancelPlanModal, subscriptionExpiryTime, cancelled }) => {
  const formattedEndDate = new Date(Number(subscriptionExpiryTime)).toLocaleDateString('en-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return (
    <Box
      sx={{
        my: 3,
        alignSelf: 'center',
        border: 0,
      }}
    >
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
          Cancel plan
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {cancelled 
            ? `You have already cancelled your plan. Your billing cycle ends on ${formattedEndDate}. You can use all features of your plan until this date.`
            : "You're thinking about cancelling your plan? Cancelling will terminate your current plan, and you will not be able to use any of the features or benefits after the end of the current billing cycle."
          }
        </Typography>
        {!cancelled && (
          <Link 
            onClick={onOpenCancelPlanModal}
            variant="contained" 
            sx={{ 
              borderRadius: '36px', 
              height: 48, 
              color: '#000453', 
              textAlign: 'center', 
              textTransform: 'none' 
            }}
          >
            Cancel plan permanently
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default SettingsCancelPlan;
