import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import OnboardHeader from './onboard-header';
import config from '../../../config';

const OnboardResubscribeScreen = () => {
  const navigate = useNavigate();
  const { loginBanner } = config;

  return (
    <>
      <OnboardHeader />
      <Box component="main" sx={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Container component={'section'} sx={{ flex: 1, minHeight: 500, display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }} >  
            <Box sx={{ width: 485, margin: 'auto' }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Welcome back! Resubscribe today
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  Restart your subscription today to unlock and share our exclusive veterinary content. Save time in clinic, enhance pet owner engagement, and improve pet health with Cetra! 
                </Typography>
                <PinkGradientButton variant="contained" color="primary" onClick={() => navigate(`/settings/manage-subscription`)} sx={{ my: 2 }}>
                  Restart subscription
                </PinkGradientButton>
            </Box>
        </Container>
        <Box sx={{ flex: 1.5, backgroundImage: `url(${loginBanner})`, backgroundSize: "cover", backgroundPosition: "bottom" }}>
        </Box>
      </Box>
    </>
  );
};

export default OnboardResubscribeScreen;
