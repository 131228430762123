import React, { useState, useEffect } from 'react';
import { Fab, Button, IconButton, Tooltip, Typography } from '@mui/material';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import HeadsetIcon from '@mui/icons-material/Headset';
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import './Accessibility.css';

const WhiteTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    fontSize: 14,
  },
}));

const letterSpacingLevels = [0, 1.68, 3.36, 5.04];

const AccessibilityOptions = ({
  fontSizeScale,
  setFontSizeScale,
  letterSpacing,
  setLetterSpacing,
  handleZoomIn,
  handleZoomOut,
  handleFontSizeIncrease,
  handleFontSizeDecrease,
  handleClose,
  zoom,
  isHighContrast,
  setHighContrast,
}) => {
  const isZoomInDisabled = zoom >= 2.7;
  const isZoomOutDisabled = zoom <= 0.1;
  const isFontSizeIncreaseDisabled = fontSizeScale >= 2;
  const isFontSizeDecreaseDisabled = fontSizeScale <= 0.05;
  const [isReading, setIsReading] = useState(false);
  const [utterance, setUtterance] = useState(null);

  const handleScreenReaderClick = () => {
    if (!isReading) {
      const content = document.getElementById('main-content').innerText;
      const newUtterance = new SpeechSynthesisUtterance(content);
      newUtterance.onend = () => {
        setIsReading(false);
      };
      window.speechSynthesis.speak(newUtterance);
      setIsReading(true);
      setUtterance(newUtterance);
    } else {
      window.speechSynthesis.cancel();
      setIsReading(false);
      setUtterance(null);
    }
  };

  useEffect(() => {
    if (utterance && !isReading) {
      window.speechSynthesis.cancel();
      setUtterance(null);
    }
  }, [isReading, utterance]);

  return (
    <div
      style={{
        position: 'fixed',
        right: 16,
        bottom: 80,
        zIndex: 100000000,
        padding: '16px',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '8px',
        }}
      >
        <Typography variant="h6">Accessibility menu</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <WhiteTooltip title="Increase font size" arrow placement="left">
        <Button
          onClick={handleFontSizeIncrease}
          startIcon={<FormatSizeIcon />}
          fullWidth
          disabled={isFontSizeIncreaseDisabled}
        >
          Bigger text
        </Button>
      </WhiteTooltip>
      <WhiteTooltip title="Decrease font size" arrow placement="left">
        <Button
          onClick={handleFontSizeDecrease}
          startIcon={<FormatSizeIcon />}
          fullWidth
          disabled={isFontSizeDecreaseDisabled}
        >
          Smaller text
        </Button>
      </WhiteTooltip>
      <WhiteTooltip title="Toggle text spacing" arrow placement="left">
        <Button
          onClick={() => setLetterSpacing(letterSpacingLevels[(letterSpacingLevels.indexOf(letterSpacing) + 1) % letterSpacingLevels.length])}
          startIcon={<TextFieldsIcon />}
          fullWidth
        >
          Text spacing
        </Button>
      </WhiteTooltip>
      <WhiteTooltip title="Zoom in" arrow placement="left">
        <Button
          onClick={handleZoomIn}
          startIcon={<ZoomInIcon />}
          fullWidth
          disabled={isZoomInDisabled}
        >
          Zoom In
        </Button>
      </WhiteTooltip>
      <WhiteTooltip title="Zoom out" arrow placement="left">
        <Button
          onClick={handleZoomOut}
          startIcon={<ZoomOutIcon />}
          fullWidth
          disabled={isZoomOutDisabled}
        >
          Zoom out
        </Button>
      </WhiteTooltip>
      <WhiteTooltip title={isReading ? 'Stop screen reader' : 'Start screen reader'} arrow placement="left">
        <Button onClick={handleScreenReaderClick} startIcon={isReading ? <HeadsetOffIcon /> : <HeadsetIcon />} fullWidth>
          {isReading ? 'Stop screen reader' : 'Start screen reader'}
        </Button>
      </WhiteTooltip>
      <WhiteTooltip title="Toggle high contrast Mode" arrow placement="left">
        <Button onClick={() => setHighContrast(!isHighContrast)} startIcon={isHighContrast ? <Brightness7Icon /> : <Brightness4Icon />} fullWidth>
          {isHighContrast ? 'Disable high contrast' : 'Enable high contrast'}
        </Button>
      </WhiteTooltip>
    </div>
  );
};

const AccessibilityWidget = () => {
  const [showAccessibilityModal, setShowAccessibilityModal] = useState(false);
  const [fontSizeScale, setFontSizeScale] = useState(1); // Scale factor 1 (default)
  const [letterSpacing, setLetterSpacing] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [isHighContrast, setHighContrast] = useState(false);

  useEffect(() => {
    const showAccessibilitySettings = localStorage.getItem('showAccessibilitySettings');
    if (showAccessibilitySettings !== 'false') {
      setShowAccessibilityModal(true);
      localStorage.setItem('showAccessibilitySettings', 'false');
    }
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--custom-font-size-scale', fontSizeScale);
    document.documentElement.style.setProperty('--custom-letter-spacing', `${letterSpacing}px`);
    document.documentElement.style.setProperty('--custom-zoom', zoom);
    document.documentElement.style.setProperty('--custom-high-contrast', isHighContrast ? '1' : '0');
    document.documentElement.classList.toggle('high-contrast', isHighContrast);
    document.documentElement.classList.toggle('large-font', fontSizeScale > 1);
    document.getElementById('main-content').style.zoom = zoom;
  }, [fontSizeScale, letterSpacing, zoom, isHighContrast]);

  const handleFontSizeIncrease = () => {
    setFontSizeScale((prevScale) => Math.min(prevScale + 0.2, 2)); // Increase by 0.2, limit to 2
  };

  const handleFontSizeDecrease = () => {
    setFontSizeScale((prevScale) => Math.max(prevScale - 0.2, 0.05)); // Decrease by 0.2, limit to 0.05
  };

  const handleCloseAccessibilityModal = () => {
    setShowAccessibilityModal(false);
  };

  const handleShowAccessibilityModal = () => {
    setShowAccessibilityModal((prevState) => !prevState);
  };
  
  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 2.7)); // Increase by 0.2, limit to 2.7
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.1)); // Decrease by 0.2, limit to 0.1
  };

  return (
    <section id="accessibility-menu">
      <Fab
        color="primary"
        aria-label="Accessibility Options"
        onClick={handleShowAccessibilityModal}
        style={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 100000,
          boxShadow: 'none'
        }}
      >
        <AccessibilityNewIcon />
      </Fab>
      {showAccessibilityModal && (
        <AccessibilityOptions
          fontSizeScale={fontSizeScale}
          setFontSizeScale={setFontSizeScale}
          handleFontSizeIncrease={handleFontSizeIncrease}
          handleFontSizeDecrease={handleFontSizeDecrease}
          letterSpacing={letterSpacing}
          setLetterSpacing={setLetterSpacing}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          handleClose={handleCloseAccessibilityModal}
          zoom={zoom}
          isHighContrast={isHighContrast}
          setHighContrast={setHighContrast}
        />
      )}
    </section>
  );
};

export default AccessibilityWidget;
