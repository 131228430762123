import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, TextField } from '@mui/material';
import PinkGradientButton from './PinkGradientButton';

const ConfirmationDialog = ({ 
  open, 
  handleClose, 
  onConfirm, 
  title = "Are you sure?", 
  message = "Are you sure you want to do something?",
  confirmText,
  cancelText,
  cancelTextReq = true,
  showTextField = false,  
  textFieldLabel = "Enter code", 
  textFieldValue, 
  onTextFieldChange,
  validate 
}) => {
  const [error, setError] = useState(''); 

  const handleConfirm = () => {
    if (validate && showTextField) {
      const isValid = validate(textFieldValue);
      if (!isValid) {
        setError('Invalid input'); 
        return;
      }
    }
    setError(''); 
    onConfirm();
    handleClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      aria-labelledby="confirmation-dialog" 
      PaperProps={{
        className:'dialog-container',
        sx: {
          p: 4, 
          maxWidth: '711px',
          borderRadius: 4
        },
      }}
    >
      <DialogTitle variant="h4" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', p: 0, fontWeight: 600 }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ p:0, mt: 3, mb: 4 }}>
        <Typography variant="body1" sx={{ color: '#616063' }}>
          {message}
        </Typography>
        {showTextField && (
          <TextField
            autoFocus
            margin="dense"
            label={textFieldLabel}
            type="text"
            fullWidth
            value={textFieldValue}
            onChange={(e) => {
              onTextFieldChange(e);
              setError(''); 
            }}
            error={!!error} 
            helperText={error} 
            sx={{ mt: 2 }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', p: 0, alignItems: { xs: 'stretch', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
        <PinkGradientButton
          onClick={handleConfirm} 
          variant="contained"
          sx={{m:0}}
        >
          {confirmText ? confirmText : "Yes"} 
        </PinkGradientButton>
        {cancelTextReq && (
          <Button
            onClick={handleClose}
            className='black-btn'
            sx={{
              color: '#0b0c26',
              textDecoration: 'underline',
              textTransform: 'none',
              fontWeight: 600,
              justifyContent: 'flex-start'
            }}
          >
            {cancelText ? cancelText : "No"} 
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
