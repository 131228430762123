import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OnboardLoginScreen from './onboard/onboard-login';
import OnboardNameScreen from './onboard/onboard-name';
import OnboardClinicNameScreen from './onboard/onboard-clinic-name';
import OnboardEmailScreen from './onboard/onboard-email';
import OnboardPasswordScreen from './onboard/onboard-password';
import OnboardVerificationCodeScreen from './onboard/onboard-code';
import OnboardLoginResetPasswordScreen from './onboard/onboard-login-reset-password';
import OnboardTypeScreen from './onboard/onboard-type';
import OnboardPlanScreen from './onboard/onboard-plan';
import OnboardResubscribeScreen from './onboard/onboard-resubscribe';
import OnboardOfferLVS from './onboard/onboard-offer-lvs';
import Checkout from '../checkout/checkout';
import { SnackbarProvider } from '../authenticated/SnackbarProvider';
import ManageSubscription from '../authenticated/settings/manage-subscription';

const UnauthenticatedApp = () => (
    <SnackbarProvider>
        <main id="main-content" style={{ zoom: document.documentElement.style.zoom || 1 }}>
            <Routes>
                <Route path="/" element={<OnboardLoginScreen />} />
                <Route path="/onboard/login" element={<OnboardLoginScreen />} />
                <Route path="/onboard/login/reset-password" element={<OnboardLoginResetPasswordScreen />} />
                <Route path="/onboard/type" element={<OnboardTypeScreen />} />
                <Route path="/onboard/name" element={<OnboardNameScreen />} />
                <Route path="/onboard/clinic-name" element={<OnboardClinicNameScreen />} />
                <Route path="/onboard/email" element={<OnboardEmailScreen />} />
                <Route path="/onboard/password" element={<OnboardPasswordScreen />} />
                <Route path="/onboard/code" element={<OnboardVerificationCodeScreen />} />     
                <Route path="/onboard/plan" element={<OnboardPlanScreen />} /> 
                <Route path="/onboard/resubscribe" element={<OnboardResubscribeScreen />} /> 
                <Route path="/onboard/offer-lvs" element={<OnboardOfferLVS />} /> 
                <Route path="/lvs" element={<OnboardOfferLVS />} /> 
                <Route path="/checkout" element={<Checkout isOnboarding = {true} />} />
                <Route path="/settings/manage-subscription" element={<ManageSubscription />} />
            </Routes>
        </main>
    </SnackbarProvider>
);

export default UnauthenticatedApp;
