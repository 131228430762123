import React, { useContext, useState } from 'react';
import { Box, Stack, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import ApiButton from '../../UIControls/api-button/api-button';
import UserApi from '../../../rest-api/user-api';
import VerificationCodeInput from './verification-code-input'; 
import ResendVerificationLink from './resend-verification-link';
import mixpanel from 'mixpanel-browser';
import KlaviyoApi from '../../../rest-api/klaviyo-api';

const OnboardVerificationCodeScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
    return { ...storedUserData, ...location.state?.userData };
  });
  const appStateStore = useContext(StoreContext);
  const { authStore, userDataStore } = useContext(StoreContext);
  const [otp, setOtp] = useState('');
  const [otpComplete, setOtpComplete] = React.useState(false);
  const searchParams = new URLSearchParams(location.search);
  const inviteId = searchParams.get('inviteId');
  const senderId = searchParams.get('senderId');
  const isLVS = searchParams.get('LVS');
  console.warn("/code local storage ", JSON.parse(localStorage.getItem('userData')));
  console.warn("isLVS? ", isLVS);
  console.warn("/code location state", location.state?.userData );
  
  const handleBack = () => {
    navigate('/onboard/password', { state: { userData } });
  };

  const verifyCode = async (otp) => {
    const codeAsString = String(otp);
    console.log(`verifyCode called email: ${userData.emailAddress}, otp as string: ${codeAsString}`, userData, inviteId);
    const result = await authStore.confirmRegistration(userData.emailAddress, userData.password, codeAsString, onRegistrationConfirmed);

    if (result.success) {
    } else {
      console.error('Error during authentication:', result.message);
      throw new Error(result.message);
    }
  };

  // callback function referenced as asyncAppInitializer in auth store
  const onRegistrationConfirmed = async (userId, idToken) => {
    console.log(`onRegistrationConfirmed called: userId: ${userId}, idToken: ${idToken}`);
    let payload = userData;
    payload.clinicImageId = "";
    payload.showLogoInHandouts = true;
    payload.handouts = [];
    delete payload.password;
    await appStateStore.initializeUserStores(userId);
  
    // Check the 'LVS' parameter in the query string and store it if true
    if (isLVS) {localStorage.setItem('LVS', 'true');}

    try {
      if (inviteId) { 
        userData.inviteId = inviteId;
        userData.action = "acceptInvitation";
        await UserApi.updateClinicianDataMultiuser(idToken, userData);
        var inviteUserData = await UserApi.getClinicianData(idToken);
      } else {
        await UserApi.updateClinicianData(idToken, payload);
      }

      if (appStateStore) {
        console.warn("attempting to store: ", payload, appStateStore, inviteUserData)
        const { userDataStore } = appStateStore;
        if (inviteUserData) {
          let formattedData = {
            firstName: inviteUserData.userData.firstName,
            lastName: inviteUserData.userData.lastName,
            emailAddress: inviteUserData.userData.emailAddress,
            mobile: inviteUserData.userData.mobile || '',
            clinicName: inviteUserData.clinicData.clinicName,
            clinicNumber: inviteUserData.clinicData.clinicNumber || '',
            clinicSize: inviteUserData.clinicData.clinicSize || '',
            clinicImageId: '',
            showLogoInHandouts: inviteUserData.showLogoInHandouts,
            handouts: JSON.stringify(inviteUserData.handouts),
            subscriptionData: {
              ...inviteUserData.subscriptionData,
              accountType: "Clinic-multiuser"
            },
            inviteId: inviteId
          };
          userDataStore.setUserData(formattedData);
        } else {
          payload.handouts = "[]"
          userDataStore.setUserData(payload);
        }
      } else {
        console.error('userDataStore is not defined.');
      }
      
      await mixpanel.identify(userData.emailAddress);

      const mixpanelTrackData = {
        userId: userId,
        email: userData.emailAddress,
        ...(userData.clinicNumber && { clinicNumber: userData.clinicNumber }),
        ...(userData.clinicName && { clinicName: userData.clinicName }),
        ...(userData.mobile && { mobile: userData.mobile }),
        ...(userData.clinicSize && { clinicSize: userData.clinicSize }),
        userName: userData.firstName + ' ' + userData.lastName,
      };
  
      await mixpanel.track('New User Sign Up', mixpanelTrackData);

      const mixpanelPeopleData = {
        "$first_name": userData.firstName,
        "$last_name": userData.lastName,
        "$email": userData.emailAddress,
        ...(userData.mobile && { "Mobile": userData.mobile }),
        ...(userData.clinicName && { "Clinic Name": userData.clinicName }),
        ...(userData.clinicNumber && { "Clinic Number": userData.clinicNumber }),
        ...(userData.clinicSize && { "Clinic Size": userData.clinicSize }),
      };
      
      await mixpanel.people.set(mixpanelPeopleData);

      KlaviyoApi.updateUserInKlaviyo(authStore.idToken, userDataStore.userData.emailAddress, 'General'); 
      localStorage.removeItem('userData');
    } catch (error) {
      console.error('Error during post-registration onboard/code onRegistrationConfirmed:', error);
    }
  };

  const ResendLink = () => (
    <ResendVerificationLink
      authStore={authStore}
      emailAddress={userData.emailAddress}
    />
  );

  const renderButtonGroup = () => (
    <Box sx={{ display: 'flex' , alignItems: "center", justifyContent: "space-between", margin: "auto", padding: "24px 0"}}>
      <Box onClick={handleBack} sx={{display: "flex", alignItems: "center", width: 120}}>
        <KeyboardBackspaceIcon/>
        <Button
          sx={{
            color: '#000453',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 600,
          }}
        >
          Go back
        </Button>
      </Box>
      
      {renderNextButton()}
    </Box>
  );

  const renderNextButton = () => (
    <ApiButton
      apiCall={() => verifyCode(otp)} 
      buttonLabel="Next"
      color="primary"
      errorTitle="Signup error"
      errorMessage="There was a problem with the signup. Please try again."
      successTitle="Save Successful"
      successMessage="Your changes were saved successfully."
      disabled={!otpComplete}
      fullWidth={true}
      buttonVariant = 'pink'
      sx={{ maxWidth: 200, m: 0, borderRadius: 36 }}
    />
  );

  const renderInputChildren = () => (
      <Box position={"relative"}>
        <Stack spacing={0} alignItems="center" justifyContent="center">
          <VerificationCodeInput
            setOtpComplete={setOtpComplete}
            onOtpChange={(value) => setOtp(value)}
          />
        </Stack>
        <ResendLink />
      </Box>
  );

  return (
    <OnboardingLayout
      title="OTP Verification"
      subtitle="Enter the verification code we just sent to your email address"
      inputChildren={renderInputChildren()}
      navChildren={renderButtonGroup()}
      currentStep={4}
      previousStepPath="/onboard/password"
      userData={userData}
    />
  );
};

export default OnboardVerificationCodeScreen;
