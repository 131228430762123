import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Divider, ToggleButton, ToggleButtonGroup, Button, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import SubscriptionCard from '../../UIControls/SubscriptionCard';
import subscriptionPlans from './subscriptionPlans';
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import ChangePlanModal from '../../UIControls/ChangePlanModal';
import SettingsCancelPlan from './settings-cancel-plan';
import CancelPlanModal from '../../UIControls/CancelPlanModal';
import SettingsFAQ from './settings-faq';
import { useSnackbar } from '../SnackbarProvider';
import { toJS } from 'mobx';
import PaymentApi from '../../../rest-api/payment-api';
import UserApi from '../../../rest-api/user-api';
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import KlaviyoApi from '../../../rest-api/klaviyo-api';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: 50,
  overflow: 'hidden',
  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.12)',
  width: 205
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
  color: selected ? '#fff' : '#000',
  backgroundColor: selected ? '#000453' : '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: selected ? '#000453' : '#f4f4f4',
  },
  '&.Mui-selected': {
    backgroundColor: '#000453',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000453',
    },
  },
  border: 'none',
  borderRadius: 0,
  flex: 1,
}));

const ManageSubscription = () => {
  const stripe = useStripe();
  const elements = useElements();
  const planKeys = Object.keys(subscriptionPlans);
  const appStateStore = useContext(StoreContext);
  const { authStore, userDataStore} = useContext(StoreContext);
  const [subscriptionExpiryTime, setSubscriptionExpiryTime] = useState(null);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentDiscount, setPaymentDiscount] = useState(50);
  const [paymentDetailsBillingCycle, setPaymentDetailsBillingCycle] = useState(50);

  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  const [cancelPlanModalOpen, setCancelPlanModalOpen] = useState(false);
  const showSnackbar = useSnackbar(); 
  const location = useLocation(); 
  const navigate = useNavigate(); 
  const stripeCustomerId = userDataStore?.subscriptionData?.stripeCustomerId;
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchPaymentMethod = async () => {
    try {
      if (stripeCustomerId) {
        const paymentDetails = await PaymentApi.getPaymentDetails(authStore.idToken , stripeCustomerId);
        console.warn(paymentDetails)
        setPaymentDetails(paymentDetails);
        if (paymentDetails?.subscription?.discount?.coupon?.percent_off) {
          console.warn('coupon found ', paymentDetails.subscription.discount.coupon )
          setPaymentDiscount(paymentDetails.subscription.discount.coupon.percent_off)
        }
        if (paymentDetails?.subscription?.plan) {
          setPaymentDetailsBillingCycle(paymentDetails?.subscription?.plan.interval)
        }
      }
    } catch (error) {
      console.error('Error fetching payment method:', error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchPaymentMethod();
  }, [userDataStore, authStore]);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.warn(toJS(userDataStore))
    if (location.state && location.state.subscriptionUpdated) {
      showSnackbar('Your subscription details have been successfully updated. Start enjoying the benefits now!', 'success');
      // clear state to prevent the message from showing again on subsequent navigations
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, showSnackbar, navigate]);

  useEffect(() => {
    const subscriptionEndDate = userDataStore?.subscriptionData?.subscriptionEndDate;
    if (subscriptionEndDate) {
      const currentTime = Date.now();
      const endTimeInMilliseconds = new Date(subscriptionEndDate).getTime();
      const differenceInMilliseconds = endTimeInMilliseconds - currentTime;
      const millisecondsInOneDay = 24 * 60 * 60 * 1000;
      const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;
      setSubscriptionExpiryTime(Math.max(0, Math.floor(differenceInDays)));
    } else {
      setSubscriptionExpiryTime(null);
    }
  }, [userDataStore?.subscriptionData?.subscriptionEndDate]);

  const handleBillingCycleChange = (event, newCycle) => {
    if (newCycle !== null) {
      setBillingCycle(newCycle);
    }
  };

  const handleCardClick = (plan) => {
    const subscriptionEndDate = userDataStore?.subscriptionData?.subscriptionEndDate;
    const currentTime = Date.now();
  
    console.warn(userDataStore.subscriptionData?.accountType, plan, subscriptionEndDate, currentTime);
  
    if (userDataStore.subscriptionData?.accountType === plan && Date.now() < userDataStore?.subscriptionData?.subscriptionEndDate) {
      return false;
    }
  
    console.warn("paymentDetails should have both keys: ", paymentDetails);
  
    const hasActiveSubscription = paymentDetails?.subscription;
    const hasPaymentMethods = paymentDetails?.paymentMethods && paymentDetails.paymentMethods.length > 0;
    console.warn(hasActiveSubscription, hasPaymentMethods, stripeCustomerId, userDataStore.subscriptionData, subscriptionEndDate, currentTime  )
    if (
      !stripeCustomerId || // customer is yet to be added to stripe
      !userDataStore.subscriptionData || // No subscription data means no paid plan
      !subscriptionEndDate || // Subscription end date is undefined (not set)
      currentTime >= subscriptionEndDate || // Subscription has expired
      !hasActiveSubscription || // No active subscription
      !hasPaymentMethods // No payment methods saved
    ) {
      navigate('/checkout', { state: { plan, billingCycle } });
    } else {
      setSelectedPlan(plan);
      setChangePlanModalOpen(true);
    }
  };

  const handleConfirmChangePlan = async () => {
    console.warn("current plan", userDataStore.subscriptionData?.accountType)

    console.warn("new plan", selectedPlan)

    const planDetails = subscriptionPlans[selectedPlan];

    planDetails.isMonthly = billingCycle === 'monthly'
    planDetails.priceId =  billingCycle === 'monthly' ? planDetails.priceMonthlyId : planDetails.priceAnnuallyId

    console.warn("new plan details ", planDetails);
    
    console.warn("selected billing cycle ", billingCycle);

    console.warn("use payment method: ", paymentDetails["paymentMethods"][0])

    await sendUpdateSubscriptionToBackend( paymentDetails["paymentMethods"][0], planDetails);

    setChangePlanModalOpen(false);
  };

  const sendUpdateSubscriptionToBackend = async (paymentMethod, plan) => {
    try {
      const req = {
        userId: userDataStore.userId,
        paymentMethodId: paymentMethod.id,
        email: userDataStore.userData.emailAddress,
        ...(plan && { plan }), 
      };
  
      const bearerToken = authStore.idToken;
  
      // payment method is not needed in req but have for future just in case
      const response = await PaymentApi.updateSubscription(bearerToken, req);

      console.warn('update subscription  response: ', response);
  
      let updatedSubscriptionData = { ...userDataStore.subscriptionData };

      if (response.requiresAction) {
        showSnackbar('Payment pending - please complete the security check with your bank', 'success');
        const result = await stripe.confirmCardPayment(response.paymentIntentClientSecret);
        if (result.error) {
          showSnackbar('3D Secure authentication failed.', 'error');
        } else if (result.paymentIntent.status === 'succeeded') {
          await confirmPaymentIntent(result.paymentIntent.id, response.subscriptionId);
        } else if (result.paymentIntent.status === 'requires_confirmation') {
          await confirmPaymentIntent(result.paymentIntent.id, response.subscriptionId);
        }
      } else if (response.subscription) {
        // Handle subscription success
        const { id, status, items, current_period_end } = response.subscription;
  
        updatedSubscriptionData = {
          ...updatedSubscriptionData,
          accountType: plan?.accountType || null,
          trialActive: false,
          trialEndDate: Date.now(),
          subscriptionEndDate: current_period_end * 1000 || null, // Convert seconds to milliseconds
          cancelled: false,
          stripeCustomerId: response.customerId || updatedSubscriptionData.stripeCustomerId,
        };
  
        console.warn("sub data post stripe: ", updatedSubscriptionData);
      }
  
      // Update user data if changes occurred
      if (response.customerId || response.subscription) {
        await UserApi.updateClinicianData(authStore.idToken, { subscriptionData: updatedSubscriptionData });
        userDataStore.setUserData({
          ...userDataStore.getUserData(),
          subscriptionData: updatedSubscriptionData,
        });
        authStore.checkAndSetAuthState(userDataStore.userData.emailAddress, authStore.idToken );
        KlaviyoApi.updateUserInKlaviyo(authStore.idToken, userDataStore.userData.emailAddress, plan?.accountType);
        if (response.subscription) {
          navigate("/", { state: { subscriptionSuccess: true } });
        } else {
          showSnackbar('Billing details have been successfully updated.', 'success');
        }
      }
      } catch (error) {
        console.error('Error sending payment details to backend:', error);
        showSnackbar('Error when updating billing details, please try again.', 'error');
      }
  };

  const confirmPaymentIntent = async (paymentIntentId, subscriptionId) => {
    console.warn("Check this Payment Intent: ", paymentIntentId);
    try {
      const req = {
        paymentIntentId,
        userId: userDataStore.userId,
        subscriptionId: subscriptionId
      };
  
      const bearerToken = authStore.idToken;
  
      const response = await PaymentApi.confirmPaymentDetails(bearerToken, req);
  
      let updatedSubscriptionData = { ...userDataStore.subscriptionData };

      const planDetails = subscriptionPlans[selectedPlan];
  
      // Update customerId if returned
      if (response.customerId) {
        updatedSubscriptionData = {
          ...updatedSubscriptionData,
          stripeCustomerId: response.customerId,
        };
      }

      if (response.subscription) {
        const { id, status, items, current_period_end } = response.subscription;

        updatedSubscriptionData = {
          ...updatedSubscriptionData,
          accountType: planDetails?.accountType || null,
          trialActive: false,
          trialEndDate: Date.now(),
          subscriptionEndDate: current_period_end * 1000 || null,
          cancelled: false,
          stripeCustomerId: response.customerId || updatedSubscriptionData.stripeCustomerId,
        };
  
        console.warn("subscription data post stripe: ", updatedSubscriptionData);
      }
  
      // Update user data if changes occurred
      if (response.customerId || response.subscription) {
        await UserApi.updateClinicianData(authStore.idToken, { subscriptionData: updatedSubscriptionData });
        userDataStore.setUserData({
          ...userDataStore.getUserData(),
          subscriptionData: updatedSubscriptionData,
        });
        authStore.checkAndSetAuthState(userDataStore.userData.emailAddress, authStore.idToken);
        KlaviyoApi.updateUserInKlaviyo(authStore.idToken, userDataStore.userData.emailAddress, planDetails?.accountType);
        if (response.subscription) {
          navigate("/", { state: { subscriptionSuccess: true } });
        }
        // Re-fetch payment methods to update state
        await fetchPaymentMethod();
      }
    } catch (error) {
      console.error("Error confirming payment intent:", error);
      showSnackbar("Error when confirming payment.", "error");
    }
  };

  const handleConfirmCancelPlan = async () => {
    await sendCancelSubscriptionToBackend()
    setCancelPlanModalOpen(false);
    window.location.reload(); 
  };

  const sendCancelSubscriptionToBackend = async () => {
    try {
      const req = {
        userId: userDataStore.userId,
      };
  
      const bearerToken = authStore.idToken;
  
      const response = await PaymentApi.cancelSubscription(bearerToken, req);
  
      if (response.error) {
        // maybe sync issue between stripe and our db debug here?
        throw new Error(response.error || 'Failed to cancel subscription');
      }

      if (response.subscription) {
        const { current_period_end } = response.subscription;
  
        const existingSubscriptionData = userDataStore.subscriptionData;
  
        // they can still use subscription until end of billing peroid, only cancelled = true
        const subscriptionData = {
          ...existingSubscriptionData,
          cancelled: true, 
          subscriptionEndDate: current_period_end * 1000 || null, // Convert seconds to milliseconds
        };

        console.warn("sub data post stripe: ", subscriptionData);
        const updatedData = await UserApi.updateClinicianData(authStore.idToken, { subscriptionData });

        // Update userDataStore with the new subscription details
        userDataStore.setUserData({
          ...userDataStore.getUserData(),
          subscriptionData,
        });

        authStore.checkAndSetAuthState( userDataStore.userData.emailAddress, authStore.idToken );
        KlaviyoApi.updateUserInKlaviyo(authStore.idToken, userDataStore.userData.emailAddress, 'Cancelled');
        showSnackbar("Your subscription plan has been successfully cancelled.");
      } 
    } catch (error) {
      console.error('Error sending payment details to backend:', error);
      showSnackbar('Error when cancelling subscription.', 'error');
    }
  }

  const handleSignout = async () => {
    await authStore.logout();
    await appStateStore.clearUserStores();
    localStorage.clear();
  };

  return (
    <CenteredWrapper component="main" sx={{ zIndex: 1, position: 'relative', mt: '64px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className="settings-container" sx={{ width: '100%', maxWidth: 638 }}>
          <Box>
            <Typography variant="h4" sx={{ marginBottom: 1.5, fontSize: 32 }}>
              Manage your subscription
            </Typography>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {Date.now() < userDataStore?.subscriptionData?.subscriptionEndDate && (
                <>
                  Current plan:  
                  <strong style={{ textTransform: "capitalize" }}>
                    {' '} {userDataStore?.subscriptionData?.accountType}
                  </strong> 
                  {userDataStore?.subscriptionData?.trialActive ? " free trial" : ""}
                </>
              )}
            </Typography>
            <Divider sx={{ marginBottom: 4 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
              {
                subscriptionExpiryTime <= 30 && ( Date.now() < userDataStore?.subscriptionData?.subscriptionEndDate ) &&
                <Typography variant="subtitle" style={{ marginBottom: '0' }}>
                  You have <strong> {subscriptionExpiryTime} days</strong> left of your {userDataStore?.subscriptionData?.accountType} {userDataStore?.subscriptionData?.trialActive ? "free trial" : ""} plan.
                </Typography>
              }
              <StyledToggleButtonGroup
                value={billingCycle}
                exclusive
                onChange={handleBillingCycleChange}
                aria-label="billing cycle"
                sx={{ marginTop: isMobile ? 2 : 0 }}
              >
                <StyledToggleButton value="monthly" aria-label="monthly" selected={billingCycle === 'monthly'}>
                  Monthly
                </StyledToggleButton>
                <StyledToggleButton value="annually" aria-label="annually" selected={billingCycle === 'annually'}>
                  Annually
                </StyledToggleButton>
              </StyledToggleButtonGroup>
            </Box>

            <Box sx={{ textAlign: 'left', marginTop: '24px' }}>
            {planKeys.filter(plan => !plan.includes('trial')).map((plan, index) => (
              <Box key={index} onClick={() => handleCardClick(plan)} sx={{ cursor: 'pointer' }}>
                <SubscriptionCard
                  plan={plan}
                  billingCycle={billingCycle}
                  isCurrentPlan={userDataStore?.subscriptionData?.accountType === plan && Date.now() < userDataStore?.subscriptionData?.subscriptionEndDate}
                  currentPlanBillingCycle={paymentDetailsBillingCycle}
                />
                {userDataStore?.subscriptionData?.accountType === plan && Date.now()< userDataStore?.subscriptionData?.subscriptionEndDate &&
                <Box sx={{fontWeight: 600}}> *exclusive discounts applied during sign up will not display here</Box>}
              </Box>
            ))}
            </Box>

            <Divider sx={{ marginBottom: 4 }} />

            <SettingsFAQ />

            {
              !userDataStore?.subscriptionData?.trialActive && 
              <>    
                <Divider sx={{ marginBottom: 4 }} />

                <SettingsCancelPlan subscriptionExpiryTime={userDataStore?.subscriptionData?.subscriptionEndDate} cancelled={userDataStore?.subscriptionData?.cancelled} onOpenCancelPlanModal={()=>{setCancelPlanModalOpen(true)} }/>
              </>
            }
            
            <Divider sx={{marginTop: 5, marginBottom: 5}} />

            <Button className='logout-btn' variant="outlined" color="primary" onClick={handleSignout} sx={{ borderRadius: '36px', width: '200px', height: 48, borderColor: '#616063', color:'#616063', textAlign: 'center'}}>
              Logout
            </Button>
          </Box>
        </Box>
      )}

      <ChangePlanModal
        open={changePlanModalOpen}
        onClose={()=>{setChangePlanModalOpen(false)}}
        onConfirm={handleConfirmChangePlan}
        selectedPlan={selectedPlan}
      />

      <CancelPlanModal
        open={cancelPlanModalOpen}
        onClose={()=>{setCancelPlanModalOpen(false)}}
        onConfirm={handleConfirmCancelPlan}
      />
    </CenteredWrapper>
  );
};

const ManageSubscriptionWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <ManageSubscription {...props} />
    </Elements>
  );
};

export default ManageSubscriptionWrapper;
