import React, { useContext } from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import { landingPageUrl } from '../../../config';

const Copyright = () => {
  const theme = useTheme();
  const { userDataStore } = useContext(StoreContext);

  const { clinicImageId, showLogoInHandouts, clinicName } = userDataStore?.userData || {};
  const { accountType } = userDataStore?.subscriptionData || {};

  return (
    <>
      <Box
        component="footer"
        sx={{
          py: 1,
          textAlign: 'left',
          position: 'relative',
          '@media print': {
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            bgcolor: 'background.paper',
            zIndex: 0,
            overflow: 'visible',
            py: 0,
          },
        }}
      >
        <Typography variant="caption" sx={{ lineHeight: '0.8', '@media print': {fontSize: '6pt', marginBottom: '-9px'}}}>
          © Copyright Cetra Health. {' '}
          <Box sx={{'@media (min-width: 600px)': {display: 'inline-block'}}}>
            All rights reserved.
          </Box>
          <br />
          <Link
            href={landingPageUrl}
            color="inherit"
            underline="always"
            sx={{
              '@media print': {
                textDecoration: 'none',
                lineHeight: '1',
                display: 'block',
                marginTop: '-3px',
              },
            }}
            target="_blank"
            rel="noopener"
          >
            {landingPageUrl}
          </Link>
        </Typography>

        {accountType === 'Clinic' && showLogoInHandouts && (
          clinicImageId ? (
            <img
              src={clinicImageId}
              alt="Clinic Logo"
              style={{
                position: 'absolute',
                right: 10,
                bottom: 2,
                height: 75,
                marginTop: 0,
              }}
              cache={false}
            />
          ) : (
            <Box
              sx={{
                position: 'absolute',
                right: 10,
                bottom: 2,
                fontSize: 16,
              }}
            >
              {clinicName}
            </Box>
          )
        )}
      </Box>
    </>
  );
};

export default Copyright;
