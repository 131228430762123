import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box, Button, Typography, CircularProgress, Divider, useMediaQuery, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import DownloadUtils from '../../../utils/download-utils';
import UserApi from '../../../rest-api/user-api';
import { useNavigate } from 'react-router-dom';
import UploadUtils from '../../../utils/UploadUtils';
import { format } from 'date-fns';
import { Auth } from 'aws-amplify';
import SuccessDialog from '../../UIControls/api-button/SuccessDialog';
import profilePlaceholder from '../../../assets/logos/profile_placeholder.svg';
import { useUnsavedChanges } from '../UnsavedChangesContext';
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';
import SettingsUser from './settings-user';
import SettingsClinic from './settings-clinic';
import UnsavedChangesModal from '../../UIControls/UnsavedChangesModal';
import SettingsPassword from './settings-password';
import SettingsBilling from './settings-billing';
import SettingsDeleteAccount from './settings-delete-account';
import SettingsSubscription from './settings-subscription';
import CTAUpgrade from '../../UIControls/CTAUpgrade';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import SettingsMultiUserAdmin from './settings-multiuser-admin';
import { getCTAUpgradeSettingsImageUrl, practiceOrClinicText } from '../../../config';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: 50,
  overflow: 'hidden',
  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.12)',
  width: 205
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
  color: selected ? '#fff' : '#000',
  backgroundColor: selected ? '#000453' : '#fff',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: selected ? '#000453' : '#f4f4f4',
  },
  '&.Mui-selected': {
    backgroundColor: '#000453',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000453',
    },
  },
  border: 'none',
  borderRadius: 0,
  flex: 1,
}));

export function SettingsScreen() {
  const theme = useTheme();
  const appStateStore = useContext(StoreContext);
  const { authStore, userDataStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const idToken = authStore.idToken;
  const [clinicianData, setClinicianData] = useState(null);
  const [clinicImage, setClinicImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [passwordChangeError, setPasswordChangeError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isDeleteLogoDialogOpen, setIsDeleteLogoDialogOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [isVerificationDialogOpen, setIsVerificationDialogOpen] = useState(false);
  const [pendingClinicDataUpdate, setPendingClinicDataUpdate] = useState(null);
  const [isSuccessEmailDialogOpen, setIsSuccessEmailDialogOpen] = useState(false);
  const [trialExpiryTime, setTrialExpiryTime] = useState(null);
  const [showClinicSettings, setShowClinicSettings] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const { hasUnsavedChanges, setHasUnsavedChanges, onConfirmNavigation, onCancelNavigation, showPrompt} = useUnsavedChanges();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false); 
  const [errorDialogMessage, setErrorDialogMessage] = useState(''); 

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    if (hasUnsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  
  useEffect(() => {
    fetchClinicianData();
  }, []); 

  useEffect(() => {
    if (newEmail) {
      setClinicianData((prevData) => ({
        ...prevData,
        emailAddress: newEmail
      }));
    }
  }, [newEmail]);
  
  const fetchClinicianData = async () => {
    setLoading(true);
    try {
      const clinicianDataResponse = await UserApi.getClinicianData(authStore.idToken);
      setNewEmail(clinicianDataResponse.userData.emailAddress);
      if (clinicianDataResponse) {
        clinicianDataResponse.clinicData = clinicianDataResponse.clinicData || {};
        setClinicianData(clinicianDataResponse);
        console.warn("Clinician data fetched and component state updated successfully.", clinicianDataResponse);
  
        // Placeholder end date in epoch to find days until expiry
        const { subscriptionEndDate } = clinicianDataResponse.subscriptionData || {};
  
        if (subscriptionEndDate) {
          const currentTime = Date.now();
          const subscriptionEndTimeInMilliseconds = subscriptionEndDate;
          const differenceInMilliseconds = subscriptionEndTimeInMilliseconds - currentTime;
          const millisecondsInOneDay = 24 * 60 * 60 * 1000;
          const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;
  
          setTrialExpiryTime(Math.floor(differenceInDays));
          console.warn('Expiry in days:', Math.floor(differenceInDays));
        }
  
        // Initialize formattedData with properties, ensuring clinicData defaults to {}
        const clinicData = clinicianDataResponse.clinicData || {};
  
        let formattedData = {
          firstName: clinicianDataResponse.userData.firstName,
          lastName: clinicianDataResponse.userData.lastName,
          emailAddress: clinicianDataResponse.userData.emailAddress,
          mobile: clinicianDataResponse.userData.mobile || '',
          jobTitle: clinicianDataResponse.userData.jobTitle || '',
          yearsExperience: clinicianDataResponse.userData.yearsExperience || '',
          clinicName: clinicData.clinicName || '',
          clinicNumber: clinicData.clinicNumber || '',
          clinicSize: clinicData.clinicSize || '',
          clinicType: clinicData.clinicType || '',
          clinicImageId: '',
          showLogoInHandouts: clinicianDataResponse.showLogoInHandouts,
          handouts: JSON.stringify(clinicianDataResponse.handouts),
          subscriptionData: clinicianDataResponse.subscriptionData || {}
        };
  

        if (clinicData.clinicImageId) {
          const payload = {
            files: [{ method: "get", key: clinicData.clinicImageId }],
            contentCategory: "clinic_image"
          };
  
          const clinicImageURL = await UserApi.generatedPresignedUrls(authStore.idToken, payload);
          if (clinicImageURL && clinicImageURL.getUrls) {
            console.warn('Clinic image found:', clinicImageURL);
            setClinicImage(clinicImageURL.getUrls[0].presignedUrl);
            let clinicImageBase64 = await DownloadUtils.downloadImageFromPresignedUrl(clinicImageURL.getUrls[0].presignedUrl);
            formattedData.clinicImageId = clinicImageBase64.base64data;
          }
        } else {
          setClinicImage('');
        }
  
        console.warn(formattedData);
        userDataStore.setUserData(formattedData);
      }
    } catch (error) {
      console.error("Failed to fetch clinician data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSignout = async () => {
    await authStore.logout();
    await appStateStore.clearUserStores();
    localStorage.clear();
  };

  const handleFileChange = async (e) => {
    if (e.target.files.length > 0) {
        const file = e.target.files[0];

        if (file.size > 2 * 1024 * 1024) {
            alert('File size exceeds the allowed maximum of 2MB. Please select another image.');
            return;
        }

        const objectURL = URL.createObjectURL(file);

        const image = new Image();
        image.src = objectURL;
        image.onload = async () => {
            if (image.width > 700 || image.height > 700) {
                alert('Image dimensions exceed the allowed maximum of 700x700 pixels. Please select another image.');
                URL.revokeObjectURL(objectURL);
            } else {
                setSelectedFile(file);
                await uploadClinicImage(file); 
            }
        };
        image.onerror = () => {
            alert("There was an error loading the image. Please select another image.");
            URL.revokeObjectURL(objectURL);
        };
    }
  };

  const createFileObject = (file) => {
    const dateStr = format(new Date(), 'yyyy-MM-dd');
    const fileNameStr = file.name.replace(/ /g, '-').toLowerCase();
    const extension = file.name.split('.').pop();
    const baseKey = `${userDataStore.userId}/profile-image`;
    console.warn('file object:', file)
    return {
        key: `${baseKey}.${extension}`,
        fileContentType: file.type,
        fileSize: file.size,
      };
  };
  
  const uploadClinicImage = async (selectedFile) => {
    if (!selectedFile) return;

    const fileObject = createFileObject(selectedFile);
    try {
        const response = await UserApi.createPresignedImagePutUrl(idToken, fileObject);
        if (response && response.putUrls) {
            const { presignedUrl, fields } = response.putUrls[0];
            const uploadedClinicImage = await UploadUtils.uploadFile(fields, selectedFile, presignedUrl);

            if (uploadedClinicImage) {
              assignClinicImageToUser(fileObject);
            }
        }
    } catch (error) {
        console.error("Failed to upload file:", error);
    } finally {
        setSelectedFile(null);
    }
  };
  
  const assignClinicImageToUser = async(fileObject) => {
    const payload = {
      firstName: clinicianData.userData.firstName,
      lastName: clinicianData.userData.lastName,
      emailAddress: clinicianData.userData.emailAddress,
      mobile: clinicianData.userData.mobile || '',
      jobTitle: clinicianData.userData.jobTitle || '',
      yearsExperience: clinicianData.userData.yearsExperience || '',
      clinicName: clinicianData.clinicData.clinicName,
      clinicNumber: clinicianData.clinicData.clinicNumber || '',
      clinicSize: clinicianData.clinicData.clinicSize || '',
      clinicType: clinicianData.clinicData.clinicType || '',
      showLogoInHandouts: clinicianData.showLogoInHandouts,
      clinicImageId: fileObject.key,
    };
    console.warn('assignClinicImageToUser payload:', payload)
    await UserApi.updateClinicianData(authStore.idToken, payload);
    // update indexDB then reload view to show updated image
    fetchClinicianData();
  }

  const removeClinicImage = async(fileObject) => {
    console.warn('removeClinicImage');
    const payload = {
      firstName: clinicianData.userData.firstName,
      lastName: clinicianData.userData.lastName,
      emailAddress: clinicianData.userData.emailAddress,
      mobile: clinicianData.userData.mobile || '',
      jobTitle: clinicianData.userData.jobTitle || '',
      yearsExperience: clinicianData.userData.yearsExperience || '',
      clinicName: clinicianData.clinicData.clinicName,
      clinicNumber: clinicianData.clinicData.clinicNumber || '',
      clinicSize: clinicianData.clinicData.clinicSize || '',
      clinicType: clinicianData.clinicData.clinicType || '',
      clinicImageId: '',
      showLogoInHandouts: clinicianData.showLogoInHandouts,
    };
    console.warn('removeClinicImage payload:', payload)
    await UserApi.updateClinicianData(authStore.idToken, payload);

    // reload view to show updated image
    fetchClinicianData();
  }

  const handleCloseDeleteLogoDialog = () => {
    setIsDeleteLogoDialogOpen(false);
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    
    // Unique case: handling combined Username field
    if (name === 'username') {
      const names = value.split(' ');
      const firstName = names[0] || '';
      const lastName = names.slice(1).join(' ') || '';
  
      setClinicianData((prevState) => ({
        ...prevState,
        userData: {
          ...prevState.userData,
          firstName,
          lastName,
        },
      }));
    } else {
      const [parentKey, childKey] = name.split('.');
  
      setClinicianData((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value,
        },
      }));
    }
    setHasUnsavedChanges(true);  
  };

  const updateEmail = async (newEmail, clinicDataPayload) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: newEmail });
      const clinicDataPayloadWithEmail = {...clinicDataPayload,emailAddress: newEmail }
      setPendingClinicDataUpdate(clinicDataPayloadWithEmail);
      setIsVerificationDialogOpen(true); 
    } catch (error) {
      if (error.message.includes('An account with the given email already exists')) {
        setErrorDialogMessage('This email is already being used by another account.');
        setIsErrorDialogOpen(true);
      } else {
        console.error('Error updating email in Cognito:', error);
      }
    }
  };

  const verifyEmailWithCode = async () => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', verificationCode);
      console.log('Email verified successfully');
  
      if (pendingClinicDataUpdate) {
        await performClinicDataUpdate(pendingClinicDataUpdate);
        setPendingClinicDataUpdate(null); 
      }
  
      setIsVerificationDialogOpen(false);
      setIsSuccessEmailDialogOpen(true); 
    } catch (error) {
      console.error('Error verifying email:', error);
    }
  };
  
  const updateClinicData = async () => {  
    const clinicDataPayload = {
      firstName: clinicianData.userData.firstName,
      lastName: clinicianData.userData.lastName,
      emailAddress: clinicianData.userData.emailAddress,
      mobile: clinicianData.userData.mobile || '',
      jobTitle: clinicianData.userData.jobTitle || '',
      yearsExperience: clinicianData.userData.yearsExperience || '',
      clinicName: clinicianData.clinicData.clinicName,
      clinicNumber: clinicianData.clinicData.clinicNumber || '',
      clinicSize: clinicianData.clinicData.clinicSize || '',
      clinicType: clinicianData.clinicData.clinicType || '',
      clinicImageId: clinicianData.clinicData.clinicImageId,
      showLogoInHandouts: clinicianData.showLogoInHandouts,
    };

    console.warn("payload", clinicDataPayload);
  
    if (newEmail !== clinicianData.userData.emailAddress) {
      await updateEmail(newEmail, clinicDataPayload);
    } else {
      await performClinicDataUpdate(clinicDataPayload);
      setHasUnsavedChanges(false);
    }
  };

  const performClinicDataUpdate = async (clinicDataPayload) => {
    try {
      const updatedData = await UserApi.updateClinicianData(authStore.idToken, clinicDataPayload);
      console.warn('Clinic data update API response:', updatedData);
      fetchClinicianData(); // Refresh clinician data
    } catch (error) {
      console.error('Failed to update clinic data:', error);
    }
  };
    
  return (
    <CenteredWrapper component="main" sx={{zIndex: 1, position: 'relative', overflowX: 'hidden'}}>
      {
        loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) 
        : 
        (
        <Box sx={{ gap: 0, width: '100%', maxWidth: 638}} className='settings-container'>
          <Box>               
            <Box
              style={{
                height: '108px', 
                overflow: 'hidden',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundImage: `url(${clinicImage})`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                margin: '0 auto',
                marginBottom: '10px'
              }}
            >
              {
                !clinicImage && <img src={profilePlaceholder} alt='Logo Placeholder'/>
              }
            </Box>
            <Box style={{textAlign: 'center'}}>
              <Typography variant="h6">{clinicianData?.clinicData?.clinicName}</Typography>
              <Typography variant="subtitle2">{clinicianData?.userData?.emailAddress}</Typography>
            </Box>

            <Box style={{ textAlign: 'left', marginTop: '24px'}}>
              <Typography variant="h4" sx={{marginBottom: 5, fontSize: 32}}>Manage your account</Typography>
              
              <Divider sx={{marginBottom: 4}}/>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>

                { 
                trialExpiryTime <= 30 && 
                  <Typography variant="subtitle" style={{ marginBottom: '0' }}>
                    You have <strong> {trialExpiryTime} days</strong> left of your {clinicianData?.subscriptionData?.accountType} {clinicianData?.subscriptionData?.trialActive ? "free trial" : ""} plan.
                  </Typography>
                }
                
                {
                  userDataStore?.subscriptionData?.accountType === "Clinic" &&
                    <StyledToggleButtonGroup
                    value={showClinicSettings}
                    exclusive
                    onChange={()=>setShowClinicSettings(!showClinicSettings)}
                    aria-label="billing cycle"
                    sx={{ marginTop: isMobile ? 2 : 0 }}
                  >
                    <StyledToggleButton value="admin" aria-label="admin" selected={showClinicSettings === false}>
                      Admin
                    </StyledToggleButton>
                    <StyledToggleButton value="clinic" aria-label="clinic" selected={showClinicSettings === true}>
                      Clinic
                    </StyledToggleButton>
                    </StyledToggleButtonGroup>
                }

                {
                  userDataStore?.subscriptionData?.trialActive && 
                  <PinkGradientButton sx={{height: 40, width: 99}} onClick={() => navigate('./manage-subscription')} >
                    Upgrade
                  </PinkGradientButton>
                }
                
              </Box>

              {
                !showClinicSettings &&
                <>
                  <SettingsUser
                    clinicianData={clinicianData}
                    updateClinicData={updateClinicData}
                    handleChangeInput={handleChangeInput}
                    newEmail={newEmail}
                    setNewEmail={setNewEmail}
                    updateEmail={updateEmail}
                  />

                  <SettingsPassword isChangePasswordDialogOpen={isChangePasswordDialogOpen} setIsChangePasswordDialogOpen={setIsChangePasswordDialogOpen} />
                </>
              }

              {
                showClinicSettings && 
                <SettingsMultiUserAdmin clinicianData={clinicianData}/>
              }
              
              {
                clinicianData?.subscriptionData?.accountType !== 'Student' &&
                <SettingsClinic
                  setClinicianData={setClinicianData}
                  clinicianData={clinicianData}
                  clinicImage={clinicImage}
                  updateClinicData={updateClinicData}
                  handleChangeInput={handleChangeInput}
                  handleFileChange={handleFileChange}
                  handleSignout={handleSignout}
                  fileInputRef={fileInputRef}
                  setIsDeleteLogoDialogOpen={setIsDeleteLogoDialogOpen}
                  pendingClinicDataUpdate={pendingClinicDataUpdate}
                  setPendingClinicDataUpdate={setPendingClinicDataUpdate}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  idToken={idToken}
                  isMobile={isMobile}
                  passwordChangeError={passwordChangeError}
                  setPasswordChangeError={setPasswordChangeError}
                  isSuccessDialogOpen={isSuccessDialogOpen}
                  setIsSuccessDialogOpen={setIsSuccessDialogOpen}
                  isDeleteLogoDialogOpen={isDeleteLogoDialogOpen}
                  isVerificationDialogOpen={isVerificationDialogOpen}
                  setIsVerificationDialogOpen={setIsVerificationDialogOpen}
                  showPrompt={showPrompt}
                  onConfirmNavigation={onConfirmNavigation}
                  onCancelNavigation={onCancelNavigation}
                  hasUnsavedChanges={hasUnsavedChanges}
                  loading={loading}
                  setLoading={setLoading}
                />
              }
              
              {
                clinicianData?.subscriptionData?.trialActive  && 
                <CTAUpgrade
                  open={true}
                  title="Take the next step!"
                  message="Your free trial is ending soon. Why not upgrade your subscription now and gain access to all of our features."
                  buttonText="Upgrade plan"
                  imageUrl={getCTAUpgradeSettingsImageUrl('trial', isMobile)}
                  isMobile={isMobile}
                />
              }

              {
                !clinicianData?.subscriptionData?.trialActive && clinicianData?.subscriptionData?.accountType === 'Student' &&
                <CTAUpgrade
                  open={true}
                  title="Go premium now!"
                  message="Upgrade your subscription to professional and access even more features."
                  buttonText="Upgrade plan"
                  imageUrl={getCTAUpgradeSettingsImageUrl('professional', isMobile)}
                  isMobile={isMobile}
                />
              }

              {
                !clinicianData?.subscriptionData?.trialActive && clinicianData?.subscriptionData?.accountType === 'Professional' &&
                <CTAUpgrade
                  open={true}
                  title="Add your team members!"
                  message="Upgrade to our clinic plan and add up to 4 users whom can share their own handouts!"
                  buttonText="Upgrade plan"
                  imageUrl={getCTAUpgradeSettingsImageUrl('clinic', isMobile)}
                  isMobile={isMobile}
                />
              }

              {
                !showClinicSettings &&
                <>
                  {clinicianData?.subscriptionData?.stripeCustomerId &&
                    <SettingsBilling />
                  }

                  <SettingsSubscription plan={clinicianData?.subscriptionData?.accountType}/>

                  <SettingsDeleteAccount />              
                </>
              }

            </Box>
          </Box>
          
          <ConfirmationDialog
            open={isVerificationDialogOpen}
            onConfirm={() => verifyEmailWithCode(verificationCode)}
            handleClose={() => setIsVerificationDialogOpen(false)}
            title="Verify new email"
            message="A verification code has been sent to your new email. Please enter the code below to verify your email change."
            confirmText="Verify"
            cancelText="Cancel"
            showTextField={true}
            textFieldLabel="Verification Code"
            textFieldValue={verificationCode}
            onTextFieldChange={(e) => setVerificationCode(e.target.value)}
          />

          <ConfirmationDialog
            open={isErrorDialogOpen}
            onConfirm={() => setIsErrorDialogOpen(false)} 
            handleClose={() => setIsErrorDialogOpen(false)} 
            title="Email error"
            message={errorDialogMessage}
            confirmText="OK"
            cancelText={null} 
            cancelTextReq={false} 
          />

          <SuccessDialog 
            open={isSuccessEmailDialogOpen} 
            handleClose={() => setIsSuccessEmailDialogOpen(false)}
            title="Email updated"
            message="Your email has been successfully changed!"
          />

          <Divider sx={{marginTop: 5, marginBottom: 5}} />

          <Button className='logout-btn' variant="outlined" color="primary" onClick={handleSignout} sx={{ borderRadius: '36px', width: '200px', height: 48, borderColor: '#616063', color:'#616063', textAlign: 'center'}}>
            Logout
          </Button>
        </Box>
        )
      }
      <UnsavedChangesModal
        open={showPrompt}
        onDiscardChanges={onConfirmNavigation}
        onCancel={onCancelNavigation}
      />
      <ConfirmationDialog
        open={isDeleteLogoDialogOpen}
        handleClose={handleCloseDeleteLogoDialog}
        onConfirm={removeClinicImage}
        title="Confirm removal"
        message={`Are you sure you want to remove the ${practiceOrClinicText} logo?`}
      />
    </CenteredWrapper>
  );
}
