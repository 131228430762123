import React from 'react';
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';

const BlueButton = ({ children, onClick, sx, disabled, ...props }) => {
  const defaultSx = {
    borderRadius: '36px',
    width: '200px',
    height: '48px',
    borderColor: '#000453',
    color: '#fff',
    textAlign: 'center',
    backgroundColor: '#000453',
    mt: 2,
    ...(disabled && {
      backgroundColor: '#F0EFF1',
      color: '#979797',
      cursor: 'not-allowed',
      pointerEvents: 'none',
    }),
  };

  return (
    <ButtonBase
      sx={{ ...defaultSx, ...sx }}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};

BlueButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
};

BlueButton.defaultProps = {
  onClick: () => {},
  sx: {},
  disabled: false,
};

export default BlueButton;
