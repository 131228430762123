import React from 'react';
import { Box, Typography, IconButton, List, ListItem, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AuthorsAndReferences = ({handoutData, onToggleAuthorsAndReferences }) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return 'Date not available'; // Handle null timestamp
    const date = new Date(timestamp);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={2} mb={2}>
      <Box display="flex" alignItems="center" mt={2}>
        <IconButton onClick={onToggleAuthorsAndReferences} aria-label="Go back">
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Typography variant="h6">Authors</Typography>
      <Typography>{handoutData.writtenBy ? `Written by ${handoutData.writtenBy}` : 'Author information not available'}</Typography>
      <Typography>{handoutData.reviewedBy ? `Reviewed by ${handoutData.reviewedBy}` : 'Reviewer information not available'}</Typography>
      <Typography>Updated {formatDate(handoutData.lastUpdated)}</Typography>

      {handoutData.citationList?.values?.length && (
        <>
          <Typography variant="h6" mt={4}>Sources</Typography>
          <List sx={{pt:0}}>
            {handoutData.citationList.values.map((citation, index) => (
              <ListItem sx={index === 0 ? { pt: 0 } : {}} key={index}>{index + 1}. {citation}</ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default AuthorsAndReferences;
