import React, { useContext } from 'react';
import { Box, Typography, useMediaQuery, useTheme, Card, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HandoutImageLoader from './handout-image-loader';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { toJS } from 'mobx';
import Model3DIcon from '../../../assets/icons/3D-Preview.png'; // Fallback for 3D models

function HandoutPreview({ handoutData, isBundledHandout = false, categories, previewImage }) {
    const { imageStore } = useContext(StoreContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const locale = 'en-UK';
    const localeData = handoutData.contentData[locale] || [];
    const introductionSection = localeData.find(
        (section) => section.sectionName === 'introduction' || section.sectionName === 'diagram',
    ) || { blocks: [] };

    // Find the first paragraph and illustration in the introduction section
    const firstParagraph = introductionSection.blocks.find((block) => block.type === 'paragraph') || {};
    const firstIllustration = introductionSection.blocks.find((block) => block.type === 'illustration');

    // this one is for checking if it is 3D model ONLY - it means if there are 2 blocks: title and 3d model
    // if there is only 3d model and title - means - it is 3D model handout (type)
    const is3DModel = localeData.every((section) => {
        return section.blocks.every((block) => block.type === '3dModel' || block.type === 'documentTitle');
    });

    // Log the first section, paragraph, and illustration
    // console.log(`localeData json: ${JSON.stringify(localeData)}`);
    // console.log('introductionSection:', introductionSection);
    // console.log('First Paragraph:', firstParagraph);
    // console.warn('First Illustration in handout-preview.js:', toJS(firstIllustration));

    const modelBlock = localeData.flatMap((section) => section.blocks).find((block) => block.type === '3dModel');

    // Check if the preview image should be taken from the illustration or fallback for the 3D model
    const imageSrc = firstIllustration ? firstIllustration.src : modelBlock ? modelBlock.imageSrc : null;
    const fallbackImage = is3DModel && !firstIllustration ? Model3DIcon : null;

    const handlePreviewClick = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        mixpanel.track('HandoutOpened', {
            email: currentUser.attributes.email,
            handoutTitle: handoutData.contentName,
        });

        navigate(`/handout/${handoutData.contentId}`);
    };

    return (
        <Card
            sx={{
                width: isMobile ? '100%' : theme.spacing(35),
                minHeight: theme.spacing(20),
                boxShadow: 3,
                margin: isMobile ? theme.spacing(0) : theme.spacing(1),
                marginBottom: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
            }}
            className="handout-preview-card"
        >
            <CardActionArea onClick={handlePreviewClick}>
                <Box
                    sx={{
                        padding: theme.spacing(2),
                        border: '0px',
                        background: theme.palette.background.paper,
                        height: isMobile ? '370px' : '320px',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        {handoutData.contentName}
                    </Typography>

                    {is3DModel ? (
                        // fix for My handouts where 3d did not load
                        <HandoutImageLoader
                            src={imageSrc || fallbackImage} // Load from IndexedDB or use fallback if present, else 3D will be loaded
                            alt={`${handoutData.contentName} preview`}
                            imageStore={imageStore}
                            handoutData={handoutData}
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    ) : (
                        <>
                            <Typography variant="body2" gutterBottom>
                                {firstParagraph.value?.substring(0, 100)}
                                {firstParagraph.value ? '...' : ''}
                            </Typography>
                            {firstIllustration && (
                                <HandoutImageLoader
                                    src={firstIllustration.src}
                                    alt={firstIllustration.alt}
                                    imageStore={imageStore}
                                    handoutData={handoutData}
                                    style={{ maxWidth: '100%', height: 'auto' }}
                                />
                            )}
                        </>
                    )}
                </Box>
            </CardActionArea>
        </Card>
    );
}

export default HandoutPreview;
