import React, { useEffect, useState } from 'react';
import AuthenticatedApp from './components/authenticated/authenticated-app';
import UnauthenticatedApp from './components/unauthenticated/unauthenticated-app';
import createAppStateStore from './app-state-store';
import { cetraLightModeTheme } from './theme';
import { observer } from 'mobx-react-lite';
import AppAuthState from './react-core-lib/components/global/AuthState';
import ContentManager from './components/state-mgmt/content-manager';
import AuthenticatedAppWithStore from './react-core-lib/components/app/authenticated-app-with-store';
import configureAmplify from './react-core-lib/third-party/amplify';
import mixpanel from 'mixpanel-browser';
import ReactGA4 from 'react-ga4';
import './App.css';
import './console-overrides';
import { register } from 'swiper/element/bundle';
import AccessibilityWidget from './AccessibilityWidget';
import config from './config'; 

register();
configureAmplify();

function App() {
  useEffect(() => {
    const { clinicType, isProd, mixpanelToken, gtagId } = config;

    if (mixpanelToken) {
      mixpanel.init(mixpanelToken, {
        verbose: true,
        debug: true,
        track_pageview: "url-with-path",
        persistence: 'localStorage',
        ignore_dnt: true,
      });
      console.log(`Mixpanel initialized with token: ${mixpanelToken}`, clinicType, isProd);
    }

    if (gtagId) {
      ReactGA4.initialize(gtagId);
    }
  }, []);

  return (
    <>
      <AuthenticatedAppWithStore
        AuthenticatedComponent={AuthenticatedApp}
        UnauthenticatedComponent={UnauthenticatedApp}
        createAppStateStore={createAppStateStore}
        theme={cetraLightModeTheme}
        globalComponents={
          <>
            <AppAuthState />
            <AccessibilityWidget />
          </>
        }
        globalAuthenticatedComponents={<ContentManager />}
      />
    </>
  );
}

export default observer(App);
