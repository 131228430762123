import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme, useMediaQuery } from '@mui/material';
import FAQs from './faqs.json';
import plusIcon from '../../../assets/icons/Plus.svg';
import Remove from '@mui/icons-material/Remove';

const SettingsFAQ = () => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      className="settings-block"
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '40px 32px',
        my: 3,
      }}
    >
      <Typography variant="h4" sx={{ mb: 3 }}>
        FAQs
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Got questions? Check out our FAQs for quick solutions to common queries. If you need further assistance, our support team is here to help.
      </Typography>
      {FAQs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
          sx={{
            mb: 2,
            backgroundColor: '#F7F7F7',
            color: '#000453',
            boxShadow: 0,
            padding: isMobile ? 1 : 3, 
          }}
        >
          <AccordionSummary
            expandIcon={expanded === index ? <Remove /> : <img src={plusIcon} alt="plus icon" />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography variant="subtitle1">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default SettingsFAQ;
