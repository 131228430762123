import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useNavigate, useLocation } from 'react-router-dom';
import StoreContext from "../app/store-context";
import { AuthState } from '../../../stores/auth-store';

const AuthStateObserver = ({ onAuthenticated, onUninitialized, onUnauthenticated, onUnverified, onPendingSubscription, onCancelledSubscription }) => {
  const { authStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(`AuthStateObserver::useEffect authState changed to ${authStore.authState}`);
    switch (authStore.authState) {
      case AuthState.AUTHENTICATED:
        onAuthenticated && onAuthenticated(location, navigate, authStore.authState);
        break;
      case AuthState.UNINITIALIZED:
        onUninitialized && onUninitialized(location, navigate, authStore.authState);
        break;
      case AuthState.UNAUTHENTICATED:
        onUnauthenticated && onUnauthenticated(location, navigate, authStore.authState);
        break;
      case AuthState.UNVERIFIED:
        onUnverified && onUnverified(location, navigate, authStore.authState);
        break;
      case AuthState.PENDING_SUBSCRIPTION:
        onPendingSubscription && onPendingSubscription(location, navigate, authStore.authState);
      case AuthState.CANCELLED_SUBSCRIPTION:
        onCancelledSubscription && onCancelledSubscription(location, navigate, authStore.authState);
        break;
      default:
        console.warn('Unknown auth state:', authStore.authState);
    }
  }, [authStore.authState, navigate, location.pathname, onAuthenticated, onUninitialized, onUnauthenticated, onUnverified, onPendingSubscription, onCancelledSubscription]);

  return null;
};

AuthStateObserver.propTypes = {
  onAuthenticated: PropTypes.func,
  onUninitialized: PropTypes.func,
  onUnauthenticated: PropTypes.func,
  onUnverified: PropTypes.func,
  onPendingSubscription: PropTypes.func,
  onCancelledSubscription: PropTypes.func
};

export default observer(AuthStateObserver);
