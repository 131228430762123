import React from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';
import pinkGradient from "../../../assets/images/cetra-gradient_720.png";
import { Link } from 'react-router-dom';

const SettingsDeleteAccount = () => {
  return (
    <Box
      sx={{
        // backgroundColor: 'white',
        // borderRadius: 4,
        // padding: '16px 24px',
        my: 3,
        alignSelf: 'center',
        border: 0
      }}
    >
      {/* <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
          Deactivate account
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          By deactivating your account, all notifications and services will be paused until you log back in. Your account information will still be retained in our database during this period.
        </Typography>
        <Link 
          variant="contained" 
          sx={{ 
            borderRadius: '36px', 
            height: 48, 
            color: 'white', 
            textAlign: 'center', 
            textTransform: 'none' 
          }}
        >
          Deactivate account
        </Link>
        <Divider sx={{ mt: 5, backgroundColor: 'rgba(229, 229, 229, 1)' }} />
      </Box>

      <Box>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
          Permanently delete account
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          By permanently deleting your account, all your data will be removed from our database after 30 days. During this period, you can still log back in to restore your account. However, after 30 days, your account will be gone forever, and you will need to start over if you wish to use our services again.
        </Typography>
        <Link 
          variant="contained" 
          sx={{ 
            backgroundImage: `url(${pinkGradient})`, 
            borderRadius: '36px', 
            height: 48, 
            color: '#000453', 
            textAlign: 'center', 
            textTransform: 'none' 
          }}
        >
          Delete account
        </Link>
        <Divider sx={{ mt: 5, backgroundColor: 'rgba(229, 229, 229, 1)' }} />
      </Box> */}
    </Box>
  );
};

export default SettingsDeleteAccount;
