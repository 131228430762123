import React, { createContext, useContext, useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';

const SnackbarContext = createContext();

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '400px',
    height: '96px',
    top: '80px !important',
    right: '32px !important',
    position: 'absolute',
    padding: 20,
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%', 
      maxWidth: '100%', 
      top: '20px !important',
      right: '0 !important', 
      borderRadius: 0, 
      padding: 8
    },
  },
}));

const CustomAlert = styled(Alert)(({ theme, severity }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: severity === 'error' ? '#f44336' :
                   severity === 'warning' ? '#ff9800' :
                   severity === 'info' ? '#2196f3' :
                   severity === 'success' ? '#4caf50' :
                   '#616161',
  color: theme.palette.common.white,
  '& .MuiAlert-icon': {
    display: 'none',
  },
}));

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const showSnackbar = useCallback((message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const handleClose = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <CustomSnackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <CustomAlert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </CustomAlert>
      </CustomSnackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
