import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, AppBar, useMediaQuery, useTheme, Box, Badge } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useUnsavedChanges } from './UnsavedChangesContext';
import StoreContext from '../../react-core-lib/components/app/store-context';
import searchIcon from '../../assets/icons/Search.svg';
import handoutIcon from '../../assets/icons/Handout.svg';
import explore3DModelsIcon from '../../assets/icons/3D-explore.svg';
import CTAUpgradeDialog from '../UIControls/CTAUpgradeDialog';
import { getCTAUpgradeImageUrl, cetraBlueLogo } from '../../config';
import { AccountCircle } from '@mui/icons-material';

const Navigation = observer(() => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { promptUnsavedChanges } = useUnsavedChanges();
    const location = useLocation();
    const navigate = useNavigate();
    const { userDataStore } = useContext(StoreContext);
    const [upgradeCTAOpen, setUpgradeCTAOpen] = useState(false);
    const [upgradeCTAOpenProfessional, setUpgradeCTAOpenProfessional] = useState(false);
    const isMultiUser = userDataStore?.subscriptionData?.accountType === 'Clinic-multiuser' ? true : false;

    const handleNavigate = (path) => {
        promptUnsavedChanges(path);
    };

    const handleHandoutsClick = () => {
        if (
            userDataStore?.subscriptionData?.accountType === 'Student' ||
            userDataStore?.subscriptionData?.accountType === 'Professional'
        ) {
            setUpgradeCTAOpen(true);
        } else {
            handleNavigate('/handouts');
        }
    };

    // New handler for the 3D Models Explore icon
    const handle3DModelsClick = () => {
        if (
            userDataStore?.subscriptionData?.accountType === 'Student'
        ) {
            setUpgradeCTAOpenProfessional(true);
        } else {
            handleNavigate('/3d-models');
        }
    };

    const isHandoutsOrSearchRoute =
        location.pathname.includes('/handout') ||
        location.pathname.includes('/search') ||
        location.pathname.includes('/handouts') ||
        location.pathname.includes('/feedback');

    return (
        <>
            <AppBar
                position="relative"
                color="default"
                sx={{
                    top: isMobile ? 'auto' : 0,
                    boxShadow: 'none',
                    background: 'white',
                    padding: isMobile ? '0 16px' : '0px 40px',
                    position: 'relative',
                    zIndex: 0,
                    height: '72px',
                    marginBottom: '0',
                    bottom: isMobile ? 0 : 'auto',
                    '@media print': {
                        display: 'none',
                    },
                }}
            >
                <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} sx={{ opacity: 1 }}>
                    {/* Home Logo */}
                    <Tab
                        disableRipple
                        sx={{ p: 0 }}
                        icon={<img src={cetraBlueLogo} alt="Home" style={{ p: 0 }} width={120} />}
                        onClick={() => handleNavigate('/')}
                        className={`app-logo ${isHandoutsOrSearchRoute ? 'app-logo-search' : ''}`}
                    />
                    <Box position="relative" display="flex" alignItems="center">
                        {/* Search Icon */}
                        <Box>
                            <Tab
                                disableRipple
                                icon={<img src={searchIcon} alt="Search Icon" />}
                                onClick={() => handleNavigate('/search')}
                                sx={{ minWidth: 'auto', padding: '12px 0px' }}
                            />
                        </Box>

                        {/* Handout Icon */}
                        <Box sx={{ marginLeft: '12px' }}>
                            <Badge
                                badgeContent={
                                    userDataStore?.userData?.handouts
                                        ? JSON.parse(userDataStore.userData.handouts).length
                                        : null
                                }
                                sx={{
                                    '& .MuiBadge-badge': {
                                        backgroundColor: '#FC96A2',
                                        color: 'white',
                                        transform: 'translateZ(1px)',
                                    },
                                }}
                                overlap="circular"
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Tab
                                    disableRipple
                                    icon={<img src={handoutIcon} alt="Handouts Icon" />}
                                    onClick={handleHandoutsClick}
                                    sx={{ minWidth: 'auto', padding: '12px 0px' }}
                                />
                            </Badge>
                        </Box>

                        {/* 3D Models Explore */}
                        {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' && (
                            <Box sx={{ marginLeft: '12px' }}>
                                <Tab
                                    disableRipple
                                    icon={<img src={explore3DModelsIcon} alt="3D Models Explore" />}
                                    onClick={handle3DModelsClick}
                                    sx={{ minWidth: 'auto', padding: '12px 0px' }}
                                />
                            </Box>
                        )}

                        {/* Settings */}
                        <Box sx={{ marginLeft: '12px' }}>
                            <Tab
                                disableRipple
                                icon={
                                    userDataStore?.subscriptionData?.accountType.includes('Clinic') &&
                                    userDataStore?.userData?.clinicImageId ? (
                                        <img
                                            width={40}
                                            style={{ marginBottom: 0, marginRight: isMobile ? null : 8 }}
                                            src={userDataStore?.userData?.clinicImageId}
                                            alt="Clinic User Icon"
                                        />
                                    ) : (
                                        <AccountCircle
                                            style={{ marginBottom: 0, marginRight: isMobile ? null : 8 }}
                                            fontSize="large"
                                        />
                                    )
                                }
                                label={
                                    userDataStore?.subscriptionData?.accountType.includes('Clinic') &&
                                    userDataStore?.userData?.clinicName
                                        ? isMobile
                                            ? null
                                            : userDataStore?.userData?.clinicName
                                        : isMobile
                                            ? null
                                            : 'Profile'
                                }                                
                                onClick={() =>
                                    isMultiUser ? handleNavigate('/settings-multiuser') : handleNavigate('/settings')
                                }
                                sx={{
                                    height: 40,
                                    background: '#F6F6F6',
                                    borderRadius: '36px',
                                    minHeight: 'auto',
                                    minWidth: isMobile ? 0 : null,
                                    flexDirection: 'row',
                                    padding: isMobile ? 0 : null,
                                }}
                                className="header-button"
                            />
                        </Box>
                    </Box>
                </Box>
            </AppBar>

            <CTAUpgradeDialog
                open={upgradeCTAOpen}
                handleClose={() => setUpgradeCTAOpen(false)}
                onConfirm={() => navigate('/settings/manage-subscription')}
                title="Upgrade now for clinic-wide access and advanced features!"
                message="You've reached a feature that's beyond your current plan. Upgrade today to add more users and enjoy full access to our features!"
                buttonText="Upgrade plan"
                benefits={[
                    'Unlimited digital sharing of all handouts & anatomy diagrams',
                    'Bundling of handouts and diagrams for seamless sharing',
                    'Personalise with your clinic logo',
                ]}
                imageUrl={getCTAUpgradeImageUrl('clinic')}
            />

            <CTAUpgradeDialog
                open={upgradeCTAOpenProfessional}
                handleClose={() => {
                    setUpgradeCTAOpenProfessional(false);
                }}
                onConfirm={() => navigate('/settings/manage-subscription')}
                title="Unlock unlimited sharing and more with just one click!"
                message="You've reached a feature that's beyond your current plan. Upgrade today to enjoy full access!"
                buttonText="Upgrade plan"
                benefits={[
                    'Unlimited digital sharing of all handouts',
                    'Unlimited digital sharing of all anatomy diagrams',
                    'Personalise with your clinic details ',
                ]}
                imageUrl={getCTAUpgradeImageUrl('professional')}
            />
        </>
    );
});

export default Navigation;
