import React from 'react';
import { AppBar, Box, Tab } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cetraBlueLogo } from '../../../config';

const OnboardHeader = observer(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="relative" color="default" sx={{
      justifyContent: "center",
      top: isMobile ? 'auto' : 0,
      boxShadow: 'none',
      background: 'white',
      padding: isMobile ? '0 16px' : '0px 40px',
      position: 'relative',
      zIndex: 0,
      height: '72px',
      bottom: isMobile ? 0 : 'auto',
      '@media print': {
        display: 'none',
      },
    }}>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} sx={{ opacity: 1 }}>
        <Tab 
          disableRipple 
          sx={{ p: 0 }} 
          icon={<img src={ cetraBlueLogo } alt="Home" style={{ p: 0 }} width={120} />} 
          className={`app-logo`} 
        />
        <Box position="relative">
         
        </Box>
      </Box>
    </AppBar>
  );
});

export default OnboardHeader;
