import { useContext } from 'react';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import UserApi from '../../../rest-api/user-api';

export function useSendEmail() {
  const { authStore } = useContext(StoreContext);

  const sendEmail = async (emailAddress, contentName, linkId) => {
    try {
      const result = await UserApi.sendPatientEmail(authStore.idToken, emailAddress, contentName, linkId);
      
      if (!result) {
        throw new Error(`Failed to send email: ${result.statusText || 'Unknown error'}`);
      }

      return result;
    } catch (error) {
      console.error('Error sending email:', error);
      throw error;  // Re-throw the error to handle it in the caller
    }
  };

  return sendEmail;
}

// // useSendEmail.js
// export const useSendEmail = () => {
//   // This is a mock implementation. Replace it with your API call.
//   return async (emailAddress, contentName, linkId) => {
//     console.log(`Preparing to send email to ${emailAddress} with contentName: ${contentName} and linkId: ${linkId}`);
    
//     // Simulate a delay (e.g., network latency or processing time)
//     await new Promise(resolve => setTimeout(resolve, 2000));

//     throw new Error("This is a mock error. Replace this with your API call.");

//     console.log(`Sending email to ${emailAddress} with contentName: ${contentName} and linkId: ${linkId}`);
//     // Implement the API call here
//   };
// };
