import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Page, Text, StyleSheet, View, Image, Document } from '@react-pdf/renderer';
import HandoutPdfImageLoader from './handout-pdf-image-loader';
import PdfCallout from './pdf-callout';
import PdfIconStrip from './pdf-icon-strip';
import Inter from '../../../../assets/fonts/Inter-Variable.ttf';
import InterBold from '../../../../assets/fonts/Inter-Bold.ttf';
import InterExtraBold from '../../../../assets/fonts/Inter-ExtraBold.ttf';
import { Font, pdf } from '@react-pdf/renderer';
import StoreContext from "../../../../react-core-lib/components/app/store-context";
import { CircularProgress, Box, Typography } from '@mui/material';
import { toJS } from 'mobx';
import { landingPageUrl } from '../../../../config';

Font.register({ family: 'Inter', src: Inter, fontWeight: 'normal' });
Font.register({ family: 'Inter', src: Inter, fontWeight: '500' });
Font.register({ family: 'InterBold', src: InterBold, fontWeight: '600' });
Font.register({ family: 'InterExtraBold', src: InterExtraBold, fontWeight: '700' });
Font.registerHyphenationCallback(word => {
  // remove - entirely so words move to next line without being split
  return [word];
});

const Footer = ({ userDataStore }) => (
  <View fixed style={styles.footerContainer}>
    <Text style={styles.footerText}>
      Copyright Cetra Health. All rights reserved.
    </Text>
    <Text
      style={styles.footerText}
      src={landingPageUrl}
    >
      {landingPageUrl}
    </Text>
    {userDataStore?.userData?.clinicImageId && userDataStore?.userData?.showLogoInHandouts && (
      <Image src={userDataStore.userData.clinicImageId} style={styles.image} cache={false} />
    )}
  </View>
);

function HandoutPdf() {
  const { id: contentId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showLabels = queryParams.get('showLabels') === 'true';
  console.warn('handout values...', contentId, showLabels);
  const { authStore, contentStore, imageStore, userDataStore } = useContext(StoreContext);
  const [handoutData, setHandoutData] = useState(null);
  console.warn('handoutData, ', { handoutData, authStore, imageStore });
  const [loadedImages, setLoadedImages] = useState(null);
  const locale = 'en-UK';
  const localeData = handoutData?.contentData[locale];

  useEffect(() => {
    console.warn(toJS(userDataStore));
    const fetchData = async () => {
      // Fetching handout data for the provided contentId
      console.log(`Fetching content data for contentId: ${contentId}`);
      const fetchedData = contentStore.getContentFromIds([contentId]);
      if (fetchedData && fetchedData.length > 0) {
        console.log('fetchedData, this is the handout: ', fetchedData);
        setHandoutData(fetchedData[0]);
      } else {
        console.log('failed to fetch data');
      }
    };
    fetchData();
  }, [contentId, contentStore]);

  useEffect(() => {
    if (handoutData && loadedImages && userDataStore) {
      downloadPdfDocument();
    }
  }, [handoutData, loadedImages, userDataStore]);

  if (!handoutData) {
    return null;
  }

  if (!loadedImages) {
    console.log('loadedImages is null');
    return (
      <HandoutPdfImageLoader
        handoutData={handoutData}
        authStore={authStore}
        imageStore={imageStore}
        onImagesLoaded={(images) => {
          setLoadedImages(images);
        }}
      />
    );
  }

  console.log('number of loadedImages: ', loadedImages.length);
  console.log('loadedImages: ', loadedImages);

  const downloadPdfDocument = async () => {
    if (!handoutData || !loadedImages) {
      console.error("Handout data is not available.");
      return;
    }

    const doc = (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.contentContainer}>
            {localeData.map((section, index) => generateSection(section, index))}
          </View>
          <Footer userDataStore={userDataStore} />
        </Page>
      </Document>
    );

    try {
      const blob = await pdf(doc).toBlob("application/pdf");
      const url = window.URL.createObjectURL(blob);

      fetch(url).then(response => response.blob()).then(blob => {
        const newBlobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = newBlobUrl;
        a.download = `Cetra - ${handoutData.contentName}.pdf`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        window.URL.revokeObjectURL(newBlobUrl);
        a.remove();
        // Check if the browser is Safari and avoid calling window.close() if it is
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (!isSafari) {
          window.close(); // Close the window for non-Safari browsers
        }
      }).catch(console.error);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      flexDirection="column"
    >
      <CircularProgress />
      <Typography variant="h6">
        Generating printable PDF...
      </Typography>
    </Box>
  );

  function generateSection(jsonContent, index) {
    if (jsonContent.sectionName === "printPageBreak") {
      // Return a View component with a break to insert a page break
      return <View key={`page-break-${index}`} break />;
    } else {
      const blocks = jsonContent.sectionName === 'diagram'
        ? [jsonContent.blocks[showLabels ? 0 : 1]]
        : jsonContent.blocks;

      return (
        <View key={jsonContent.sectionName + index}>
          {blocks.map((block, blockIndex) => blockToComponentMapping(block, blockIndex))}
        </View>
      );
    }
  }

  function blockToComponentMapping(block) {
    switch (block.type) {
      case 'documentTitle':
        return <Text style={styles.title} key={block.value}>{block.value}</Text>;
      case 'sectionTitle':
        return <Text style={styles.sectionTitle} key={block.value}>{block.value}</Text>;
      case 'sectionSubtitle':
        return <Text style={styles.sectionSubtitle} key={block.value}>{block.value}</Text>;
      case 'calloutSmall':
        return <PdfCallout content={block.value} key={block.value} />;
      case 'calloutMedium':
        return <PdfCallout content={block.value} key={block.value} />;
      case 'paragraph':
        return <Text style={styles.paragraph} key={block.value}>{block.value}</Text>;
      case 'list':
        return block.values.map(item => <Text style={styles.paragraph} key={item}>{`\u2022 ${item}`}</Text>);
      case 'iconStrip':
        console.log('iconStrip: ', block.iconDetails);
        return <PdfIconStrip
          iconDetails={block.iconDetails}
          loadedImages={loadedImages}
        />;
      case 'illustration':
        const base64Image = loadedImages.find(img => img.url.includes(block.src));
        console.log('loadedImages:', loadedImages);
        console.log(`base64Image: ${base64Image}`);
        if (base64Image)
          return <Image src={base64Image.data.data} cache={false} />;
        else
          return <Text>*** ILLUSTRATION NOT FOUND ***</Text>;
      case 'printPageBreak':
        return <View break={true}></View>;
      default:
        return null;
    }
  }
}

const styles = StyleSheet.create({
  footerContainer: {
    position: 'absolute',
    bottom: 12,
    left: 10,
    right: 0,
  },
  footerText: {
    fontSize: 8,
  },
  page: {
    fontFamily: 'Inter',
    backgroundColor: '#ffffff',
    color: '#212121',
    padding: 20,
    paddingBottom: 60
  },
  contentContainer: {
    marginBottom: 60,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    fontFamily: 'InterExtraBold',
    color: '#000453',
  },
  sectionTitle: {
    fontSize: 20,
    marginBottom: 10,
    fontFamily: 'InterBold',
    color: '#000453'
  },
  sectionSubtitle: {
    fontSize: 16,
    marginBottom: 10,
    fontFamily: 'InterBold',
    color: '#000453'
  },
  paragraph: {
    fontSize: 12,
    marginBottom: 10,
    fontFamily: 'Inter',
    color: '#1d2129',  // grey[900]
  },
  image: {
    position: 'absolute',
    right: 10,
    bottom: -10,
    // width: 48, 
    height: 62,
    marginTop: 0,
  }
});

export default HandoutPdf;
