import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import SubscriptionCard from '../../UIControls/SubscriptionCard';

const SettingsSubscription = ({plan}) => {

  const formatPlanName = (plan) => {
    if (!plan) return '';
    // Capitalize the first letter and lowercase the rest
    return plan.charAt(0).toUpperCase() + plan.slice(1).toLowerCase();
  };

  const formattedPlan = formatPlanName(plan);
  return (
    <Box
      className="settings-block"
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: '16px 24px',
        my: 3,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 600, height: 48 }}>
          Subscription
        </Typography>
        <Typography variant="body1" sx={{ color: 'textSecondary' }}>
          Manage your current plan and explore the different plans we provide.
        </Typography>
        <Divider
          sx={{
            width: 'calc(100% + 48px)',
            position: 'absolute',
            bottom: 0,
            left: '-24px',
            backgroundColor: 'rgba(229, 229, 229, 1)',
          }}
        />
      </Box>

      <Box sx={{ mt: 3 }}>
        <SubscriptionCard plan={formattedPlan} isCurrentPlan={true} />
      </Box>
    </Box>
  );
};

export default SettingsSubscription;
