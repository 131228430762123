// consoleOverrides.js
(function () {
    const originalConsole = {
        log: console.log,
        error: console.error,
        warn: console.warn,
        info: console.info
    };

    const isProd = process.env.REACT_APP_USER_API_URL.includes('prod');  

    if (process.env.NODE_ENV === 'production' && isProd) {
        console.log = () => { };
        console.info = () => { };
        console.warn = () => { };
        // Keep console.error as it is or add custom logic if needed.
    }

    // Optionally, you can expose a method to restore the original console methods
    console.restore = () => {
        console.log = originalConsole.log;
        console.error = originalConsole.error;
        console.warn = originalConsole.warn;
        console.info = originalConsole.info;
    };
})();
