import React from 'react';
import { Box, Button } from '@mui/material';
import bodyAreaIcon from '../../../assets/icons/categories/bodyArea.svg';
import chronicConditionsIcon from '../../../assets/icons/categories/chronicConditions.svg';
import generalAnatomy from '../../../assets/icons/categories/generalAnatomy.svg';
import generalAnatomyAnimal from '../../../assets/icons/categories/generalAnatomyAnimal.svg';
import generalPrinciples from '../../../assets/icons/categories/generalPrinciples.svg';
import generalPrinciplesAnimal from '../../../assets/icons/categories/generalPrinciplesAnimal.svg';
import neurologyIcon from '../../../assets/icons/categories/neurology.svg';
import womensHealthIcon from '../../../assets/icons/categories/womensHealth.svg';
import mensHealthIcon from '../../../assets/icons/categories/mensHealth.svg';
import animalIcon from '../../../assets/icons/categories/animal.svg';
import behaviourIcon from '../../../assets/icons/categories/behaviour.svg';
import denistryIcon from '../../../assets/icons/categories/denistry.svg';
import dermatologyIcon from '../../../assets/icons/categories/dermatology.svg';
import emergencyIcon from '../../../assets/icons/categories/emergency.svg';
import farmIcon from '../../../assets/icons/categories/farm.svg';
import internalMedicineIcon from '../../../assets/icons/categories/internalMedicine.svg';
import medicalConditionsIcon from '../../../assets/icons/categories/medicalConditions.svg';
import musculoskeletalIcon from '../../../assets/icons/categories/musculoskeletal.svg';
import preventativeIcon from '../../../assets/icons/categories/preventative.svg';
import parasitesIcon from '../../../assets/icons/categories/parasites.svg';
import reproductionIcon from '../../../assets/icons/categories/reproduction.svg';
import surgeryIcon from '../../../assets/icons/categories/surgery.svg';
import orthopaedicIcon from '../../../assets/icons/categories/orthopaedic.svg';
import ophthalmologyIcon from '../../../assets/icons/categories/ophthalmology.svg';
import pulmonaryAndCardioIcon from '../../../assets/icons/categories/pulmonaryAndCardio.svg';
import paediatricsIcon from '../../../assets/icons/categories/paediatrics.svg';
import rheumatologyIcon from '../../../assets/icons/categories/rheumatology.svg';
import seriousPathologiesIcon from '../../../assets/icons/categories/seriousPathologies.svg';

import birdIcon from '../../../assets/icons/categories/bird.svg';
import canineIcon from '../../../assets/icons/categories/canine.svg';
import chickenIcon from '../../../assets/icons/categories/chicken.svg';
import felineIcon from '../../../assets/icons/categories/feline.svg';
import fishIcon from '../../../assets/icons/categories/fish.svg';

import equineIcon from '../../../assets/icons/categories/equine.svg';
import guineaPigIcon from '../../../assets/icons/categories/guineaPig.svg';
import hamsterIcon from '../../../assets/icons/categories/hamster.svg';
import rabbitIcon from '../../../assets/icons/categories/rabbit.svg';
import smallAnimalsIcon from '../../../assets/icons/categories/smallAnimals.svg';
import smallMammalsIcon from '../../../assets/icons/categories/smallMammals.svg';

import largeAnimalsIcon from '../../../assets/icons/categories/largeAnimals.svg';
import exoticAnimalsIcon from '../../../assets/icons/categories/exoticAnimals.svg';

import { observer } from 'mobx-react-lite';

const categoryIconMap = {
    Anatomy: process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalAnatomyAnimal : generalAnatomy,
    Animal: animalIcon,
    Behaviour: behaviourIcon,
    Bird: birdIcon,
    'Body Area': bodyAreaIcon,
    Canine: canineIcon,
    Cardiorespiratory: pulmonaryAndCardioIcon,
    Chicken: chickenIcon,
    'Chronic conditions': chronicConditionsIcon,
    Dentistry: denistryIcon,
    Dermatology: dermatologyIcon,
    Emergency: emergencyIcon,
    Equine: equineIcon,
    'Exotic Animals': exoticAnimalsIcon,
    Farm: farmIcon,
    Feline: felineIcon,
    Fish: fishIcon,
    'General principles':
        process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalPrinciplesAnimal : generalPrinciples,
    'Guinea pig': guineaPigIcon,
    Hamster: hamsterIcon,
    'Internal medicine': internalMedicineIcon,
    'Large Animals': largeAnimalsIcon,
    'Medical conditions': medicalConditionsIcon,
    "Men's health": mensHealthIcon,
    Musculoskeletal: musculoskeletalIcon,
    Neurology: neurologyIcon,
    Ophthalmology: ophthalmologyIcon,
    Orthopaedics: orthopaedicIcon,
    Paediatrics: paediatricsIcon,
    Parasites: parasitesIcon,
    Preventative: preventativeIcon,
    Rabbit: rabbitIcon,
    Reproduction: reproductionIcon,
    Rheumatology: rheumatologyIcon,
    'Serious pathologies': seriousPathologiesIcon,
    'Small Animals': smallAnimalsIcon,
    'Small mammals': smallMammalsIcon,
    Surgery: surgeryIcon,
    "Women's health": womensHealthIcon,
};

export const SearchCategories = observer(({ categories, activeCategory, onCategoryChange }) => {
    const handleCategoryClick = (category, event) => {
        event.preventDefault();
        if (activeCategory === category) {
            onCategoryChange(null);
        } else {
            onCategoryChange(category);
        }
    };
    return (
        <Box sx={{ marginTop: '8px!important' }}>
            {Object.entries(categories).map(([category, icon]) => (
                <Button
                    key={category}
                    className="category-btn"
                    sx={{
                        color: activeCategory === category ? 'white' : 'black',
                        backgroundColor: activeCategory === category ? '#FC96A2' : '#f7f7f7',
                        borderRadius: '16px',
                        marginRight: 1,
                        marginTop: 1,
                        height: 48,
                        fontSize: 12,
                        '&:hover': {
                            backgroundColor: activeCategory === category ? '#FC96A2' : '#f7f7f7',
                        },
                    }}
                    onClick={(e) => handleCategoryClick(category, e)}
                    onTouchEnd={(e) => handleCategoryClick(category, e)}
                >
                    <img
                        src={
                            categoryIconMap[category]
                                ? categoryIconMap[category]
                                : process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet'
                                  ? animalIcon
                                  : bodyAreaIcon
                        }
                        alt={`${category} Category Icon`}
                        width="24px"
                        height="24px"
                        style={{
                            marginRight: 4,
                            position: 'relative',
                            top: 0,
                            right: 0,
                            zIndex: 1,
                            filter: activeCategory === category ? 'invert(100%)' : 'initial',
                        }}
                    />
                    {category}
                </Button>
            ))}
        </Box>
    );
});
