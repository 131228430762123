import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../UIControls/ConfirmationDialog';
const UnsavedChangesContext = createContext();

export const UnsavedChangesProvider = ({ children }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [nextPath, setNextPath] = useState('');
  const navigate = useNavigate();

  const promptUnsavedChanges = (navigateTo) => {
    if (hasUnsavedChanges) {
      setNextPath(navigateTo);
      setShowPrompt(true);
    } else {
      navigate(navigateTo);
    }
  };

  // Close the modal and reset states
  const onConfirmNavigation = () => {
    setShowPrompt(false);
    navigate(nextPath);
    setHasUnsavedChanges(false);
  };

  const onCancelNavigation = () => {
    setShowPrompt(false);
  };

  return (
    <UnsavedChangesContext.Provider 
      value={{ 
        hasUnsavedChanges, 
        setHasUnsavedChanges, 
        promptUnsavedChanges, 
        onConfirmNavigation, 
        onCancelNavigation, 
        showPrompt 
      }}>
      {children}
      <ConfirmationDialog
        open={showPrompt}
        handleClose={onCancelNavigation}
        onConfirm={onConfirmNavigation}
        title="Are you sure you want to leave?"
        message="You have unsaved changes. If you leave, your changes will be lost."
      />
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChanges = () => useContext(UnsavedChangesContext);

export { UnsavedChangesContext };
