import React from 'react';
import { Tooltip, IconButton } from '@mui/material';

const CustomToolbarButton = ({ iconSrc, tooltipTitle, ...props }) => (
    <IconButton {...props}>
        <Tooltip title={tooltipTitle} arrow>
            <img src={iconSrc} alt={tooltipTitle} style={{ width: 24, height: 24 }} />
        </Tooltip>
    </IconButton>
);

export default CustomToolbarButton;
