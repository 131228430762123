import React, { useContext, useState, useMemo } from 'react';
import { Button, TextField, Typography, Stack, FormHelperText, List, ListItem, ListItemIcon, ListItemText, IconButton, InputAdornment, Link, Checkbox, FormControlLabel, Box } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import ApiButton from '../../UIControls/api-button/api-button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Auth } from 'aws-amplify';
import { inputStyle } from './inputStyle';
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';
import { tsCsUrl } from '../../../config';

const OnboardPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useContext(StoreContext);
  const [userData, setUserData] = useState(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
    return { ...storedUserData, ...location.state?.userData };
  });
  const queryParams = new URLSearchParams(location.search);
  const inviteId = queryParams.get('inviteId');
  const senderId = queryParams.get('senderId');
  const isLVS = queryParams.get('LVS') === 'true';
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  console.warn("/password", userData);

  const passwordRequirements = [
    { test: pwd => pwd.length >= 8, text: "At least 8 characters" },
    { test: pwd => /[A-Z]/.test(pwd), text: "An uppercase letter" },
    { test: pwd => /[a-z]/.test(pwd), text: "A lowercase letter" },
    { test: pwd => /\d/.test(pwd), text: "A number" }
  ];

  const passwordCriteria = passwordRequirements.map(req => ({
    ...req,
    meets: req.test(userData.password || '')
  }));

  const passwordsMatch = useMemo(() => userData.password && confirmPassword && userData.password === confirmPassword, [userData.password, confirmPassword]);
  const passwordValid = useMemo(() => passwordCriteria.every(criterion => criterion.meets), [passwordCriteria]);
  const canSubmit = useMemo(() => passwordValid && passwordsMatch && agreeToTerms, [passwordValid, passwordsMatch, agreeToTerms]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((userData) => ({ ...userData, [name]: value }));
  };

  const handleAgreeChange = (event) => {
    setAgreeToTerms(event.target.checked);
  };

  const handleBack = () => {
    const { accountType } = userData.subscriptionData || {};

    const backPath = inviteId && senderId
      ? `/onboard/name?inviteId=${inviteId}&senderId=${senderId}${isLVS ? '&LVS=true' : ''}`
      : (accountType === 'Student'
        ? `/onboard/name${isLVS ? '?LVS=true' : ''}`
        : `/onboard/clinic-name${isLVS ? '?LVS=true' : ''}`);

    navigate(backPath, { state: { userData } });
  };

  const signUp = async () => {
    if (!canSubmit) {
      setError('Please ensure all fields are filled correctly and agree to the terms and conditions.');
      return;
    }
    try {
      const result = await authStore.signUp(userData.emailAddress, userData.password);
      localStorage.setItem('userData', JSON.stringify(userData));
      if (result.success) {
        const nextPath = inviteId && senderId
          ? `/onboard/code?inviteId=${inviteId}&senderId=${senderId}${isLVS ? '&LVS=true' : ''}`
          : `/onboard/code${isLVS ? '?LVS=true' : ''}`;

        navigate(nextPath, { state: { userData } });
      } else {
        console.error('Error during authentication:', result.message);
        setError(result.message);
      }
    } catch (error) {
      console.error('Error during sign up:', error);
      if (error.message === "An account with the given email already exists." || error.message === "PreSignUp failed with error Internal Server Error.") {
        throw new Error("Sorry, we are unable to process your request at this time. Please contact us at hello@cetrahealth.com for further assistance.");
      } else if (error.message === "User already exists") {
        // User exists but isn't confirmed, so resend the confirmation code
        try {
          await Auth.resendSignUp(userData.emailAddress);
          authStore.setTempPassword(userData.password);
          console.log('OTP code resent successfully.');
          const codePath = inviteId && senderId
            ? `/onboard/code?inviteId=${inviteId}&senderId=${senderId}${isLVS ? '&LVS=true' : ''}`
            : `/onboard/code${isLVS ? '?LVS=true' : ''}`;
          navigate(codePath, { state: { userData } });
        } catch (resendError) {
          console.error('Error resending OTP:', resendError);
          if (resendError.message.includes("User is already confirmed")) {
            // User exists and is already confirmed - show confirmation dialog before redirecting to login
            setShowConfirmationDialog(true);
          } else {
            setError('Failed to resend verification code. Please try again or contact support.');
          }
        }        
      } else {
        throw error;
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleConfirmRedirect = () => {
    navigate('/onboard/login');
  };

  const renderInputChildren = () => (
    <Stack spacing={2}>
      <Typography variant="h6">Password</Typography>
      <TextField
        name="password"
        type={showPassword ? "text" : "password"}
        value={userData.password || ''}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        error={!passwordValid}
        helperText={!passwordValid && "Password does not meet the requirements."}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={inputStyle}
      />
      <Typography variant="h6">Confirm password</Typography>
      <TextField
        type={showConfirmPassword ? "text" : "password"}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        fullWidth
        margin="normal"
        error={!passwordsMatch}
        helperText={!passwordsMatch && "Passwords do not match."}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
        sx={inputStyle}
      />
      <List dense>
        {passwordCriteria.map((criterion, index) => (
          <ListItem key={index} disablePadding>
            <ListItemIcon>
              {criterion.meets ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
            </ListItemIcon>
            <ListItemText primary={criterion.text} />
          </ListItem>
        ))}
      </List>
      <FormControlLabel
        sx={{ marginLeft: '-8px !important' }}
        control={<Checkbox checked={agreeToTerms} onChange={handleAgreeChange} name="agreeToTerms" />}
        label={<Typography>I agree to the <Link href={tsCsUrl}>Terms and Conditions</Link>.</Typography>}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  );

  const renderButtonGroup = () => (
    <Box sx={{ display: 'flex' , alignItems: "center", justifyContent: "space-between", margin: "auto", padding: "24px 0"}}>
      <Box onClick={handleBack} sx={{display: "flex", alignItems: "center", width: 120}}>
        <KeyboardBackspaceIcon/>
        <Button
          sx={{
            color: '#000453',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 600,
          }}
        >
          Go back
        </Button>
      </Box>
      
      {renderNextButton()}
    </Box>
  );

  const renderNextButton = () => (
    <ApiButton
      apiCall={signUp}
      buttonLabel="Next"
      buttonVariant = 'pink'
      disabled={!canSubmit}
      sx={{
        color: 'white',
        borderRadius: '100px',
        margin: 0,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.01em',
        textTransform: 'none',
      }}
    />
  );

  return (
    <>
    <OnboardingLayout
        title="Set up your password"
        subtitle="Creating a strong password is essential to protect your account. Combine uppercase and lowercase letters, numbers, and special characters."
        inputChildren={renderInputChildren()}
        navChildren={renderButtonGroup()}
        currentStep={3}
        previousStepPath="/onboard/email"
        userData={userData}
    />
      <ConfirmationDialog
        open={showConfirmationDialog}
        handleClose={() => setShowConfirmationDialog(false)}
        onConfirm={handleConfirmRedirect}
        title="Account already exists!"
        message="An account with this email already exists. You will be redirected to the login screen. Please use the forgot password link to reset your password if necessary."
        confirmText="Proceed to log in"
        cancelTextReq={false}
      />
    </>
  );
};

export default OnboardPasswordScreen;
