import React, { useState } from 'react';
import { Box, CircularProgress } from "@mui/material";
import ErrorDialog from './ErrorDialog';
import SuccessDialog from './SuccessDialog';
import BlueButton from '../BlueButton';
import PinkGradientButton from '../PinkGradientButton';

const ApiButton = ({
  apiCall,
  onSuccess,
  onError,
  buttonLabel,
  buttonStyle,
  variant = "contained",
  color = "secondary",
  errorTitle,
  successTitle,
  successMessage,
  disabled = false,
  onErrorConfirm = () => { },
  onSuccessConfirm = () => { },
  fullWidth = false,
  sx = {},
  image,
  imageSize,
  buttonVariant = 'blue',
  showSuccessDialog = true,
}) => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('An error occurred.');
  const [isLoading, setIsLoading] = useState(false);

  const defaultErrorHandler = (error) => {
    setErrorMessage(error.message);
    setOpenErrorDialog(true);
  };

  const defaultSuccessHandler = () => {
    if (showSuccessDialog) { 
      setOpenSuccessDialog(true);
    }
  };

  const handleClick = async () => {
    setIsLoading(true);
    try {
      console.log("Operation started...");
      const result = await apiCall();
      console.log("Operation completed successfully!");
      if (onSuccess) {
        onSuccess(result);
      } else {
        defaultSuccessHandler();
      }
    } catch (error) {
      console.error("Operation failed:", error);
      if (onError) {
        onError(error);
      } else {
        defaultErrorHandler(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderButton = () => {
    const commonProps = {
      onClick: handleClick,
      disabled: isLoading || disabled,
      sx: {
        ...sx,
        ...buttonStyle,
        ...(isLoading || disabled ? { backgroundColor: '#F0EFF1!important', cursor: 'not-allowed!important', pointerEvents: 'none' } : {})
      },
    };

    switch (buttonVariant) {
      case 'pink':
        return (
          <PinkGradientButton {...commonProps}>
            {isLoading ? <CircularProgress size={24} /> : buttonLabel}
            {image && imageSize && <img src={image} width={imageSize} style={{ marginLeft: '8px' }} />}
          </PinkGradientButton>
        );
      case 'blue':
      default:
        return (
          <BlueButton {...commonProps}>
            {isLoading ? <CircularProgress size={24} /> : buttonLabel}
            {image && imageSize && <img src={image} width={imageSize} style={{ marginLeft: '8px' }} />}
          </BlueButton>
        );
    }
  };

  return (
    <>
      <Box sx={sx}>
        {renderButton()}
      </Box>

      <ErrorDialog
        open={openErrorDialog}
        handleClose={() => {
          setOpenErrorDialog(false);
          onErrorConfirm();
        }}
        title={errorTitle || "Error"}
        message={errorMessage}
      />

      <SuccessDialog
        open={openSuccessDialog}
        handleClose={() => {
          setOpenSuccessDialog(false);
          onSuccessConfirm();
        }}
        title={successTitle || "Success"}
        message={successMessage || "Your action was successful."}
      />
    </>
  );
};

export default ApiButton;
