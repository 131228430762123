import React, { useContext, useRef, useState, useEffect } from 'react';
import { TextField, Button, Box, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import { SearchResults } from './search-results';
import { CategoryButton } from '../../UIControls/category-button';
import { SearchFiltersVet } from './search-filters-vet';
import { SearchCategories } from './search-categories';
import { useTheme } from '@mui/material/styles';

export const SearchControls = observer(
    ({
        searchTerm,
        onSearchChange,
        activeFilter,
        onFilterChange,
        activeCategory,
        onCategoryChange,
        disableSearch = false,
    }) => {
        const navigate = useNavigate();
        const { contentStore, searchStore } = useContext(StoreContext);
        const searchInputRef = useRef(null);
        const [isDropdownVisible, setDropdownVisible] = useState(false);
        const [categories, setCategories] = useState({});

        const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE; // 'physio' or 'vet'
        const theme = useTheme();

        useEffect(() => {
            // Fetch categories once the store is initialized
            if (contentStore.isInitialized) {
                const filteredData = Object.fromEntries(
                    Object.entries(contentStore.getCategoriesData()).filter(
                        ([key, _]) =>
                            key !== 'Body Area' && key !== 'Animal' && key !== 'Diagrams' && key !== '3D Models',
                    ),
                );
                setCategories(filteredData);
            }
        }, [contentStore]);

        useEffect(() => {
            const handleClickOutside = (event) => {
                setTimeout(() => {
                    if (!searchInputRef.current?.contains(document.activeElement)) {
                        setDropdownVisible(false);
                    }
                }, 200);
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        useEffect(() => {
            const timerId = setTimeout(() => {
                if (searchTerm.trim()) {
                    searchStore.setSearchTerm(searchTerm.trim());
                    const results = contentStore.searchContentMeta(searchTerm.trim());
                    searchStore.setSearchResults(results);
                    setDropdownVisible(true);
                } else {
                    searchStore.setSearchTerm('');
                    searchStore.setSearchResults([]);
                    setDropdownVisible(false);
                }
            }, 200);

            return () => clearTimeout(timerId);
        }, [searchTerm, contentStore, searchStore]);

        const handleSearchInputChange = (e) => {
            onSearchChange(e.target.value);
        };

        const handleBlur = () => {
            setTimeout(() => {
                if (!searchInputRef.current?.contains(document.activeElement)) {
                    setDropdownVisible(false);
                }
            }, 200);
        };

        const handleBrowseByChange = (filterType) => {
            if (filterType === 'Condition') {
                navigate('/search', { state: { filter: 'Condition' } });
            } else if (filterType === 'Body area' || filterType === 'Animal') {
                navigate('/search/categories', { state: { filter: filterType } });
            } else if (filterType === 'Diagrams') {
                navigate('/search/diagrams', { state: { category: filterType } });
            } else if (filterType === '3D Models') {
                navigate('/search/3dmodels', { state: { category: filterType } });
            }
        };

        const handleSearchIconClick = () => {
            setDropdownVisible(true);
            setTimeout(() => {
                if (searchInputRef.current) {
                    searchInputRef.current.focus();
                }
            }, 0);
        };

        return (
            <Stack spacing={2} width="100%" position={'relative'}>
                <Box display="flex" alignItems="center" width="100%">
                    <TextField
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        onBlur={handleBlur}
                        onFocus={() => searchTerm && searchStore.searchResults.length && setDropdownVisible(true)}
                        variant="outlined"
                        placeholder="Search for a condition..."
                        InputProps={{
                            endAdornment: (
                                <Button onClick={() => handleSearchIconClick()}>
                                    <SearchIcon />
                                </Button>
                            ),
                        }}
                        sx={{
                            ...(disableSearch
                                ? {
                                      backgroundColor: '#f0f0f0',
                                      '.MuiOutlinedInput-notchedOutline': { borderColor: '#d0d0d0' },
                                      '&.Mui-disabled': { WebkitTextFillColor: '#a0a0a0', color: '#a0a0a0' },
                                  }
                                : {}),
                            borderRadius: searchTerm ? '12px' : '0px',
                        }}
                        inputRef={searchInputRef}
                    />
                    {isDropdownVisible && (
                        <SearchResults breadcrumbs={['SEARCH']} results={searchStore.searchResults} popup={true} />
                    )}
                </Box>
                <Box display="flex" alignItems="center" width="100%">
                    Browse by
                </Box>
                <Box
                    display="flex"
                    flexWrap={clinicType === 'vet' ? 'wrap' : 'nowrap'}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    width="100%"
                    sx={{
                        marginTop: '8px',
                    }}
                >
                    <CategoryButton
                        category={'Condition'}
                        onClick={() => handleBrowseByChange('Condition')}
                        sx={{
                            flex: clinicType === 'vet' ? '0 0 calc(50% - 8px)' : '0 0 calc(33.333% - 8px)',
                            marginRight: '8px',
                            marginBottom: clinicType === 'vet' ? '16px' : '0px',
                        }}
                    />
                    <CategoryButton
                        category={clinicType === 'vet' ? 'Animal' : 'Body area'}
                        onClick={() => handleBrowseByChange(clinicType === 'vet' ? 'Animal' : 'Body area')}
                        sx={{
                            flex: clinicType === 'vet' ? '0 0 calc(50% - 8px)' : '0 0 calc(33.333% - 8px)',
                            marginRight: '8px',
                            marginBottom: clinicType === 'vet' ? '16px' : '0px',
                        }}
                    />
                    <CategoryButton
                        category={'Diagrams'}
                        onClick={() => handleBrowseByChange('Diagrams')}
                        sx={{
                            flex: clinicType === 'vet' ? '0 0 calc(50% - 8px)' : '0 0 calc(33.333% - 8px)',
                            marginRight: '8px',
                            marginBottom: clinicType === 'vet' ? '16px' : '0px',
                            // marginTop: clinicType === 'physio' ? '16px' : '0px', // For Physio, move the "Diagrams" button slightly lower
                        }}
                    />
                    {clinicType === 'vet' && (
                        <CategoryButton
                            category={'3D Models'}
                            onClick={() => handleBrowseByChange('3D Models')}
                            sx={{
                                flex: '0 0 calc(50% - 8px)',
                                marginRight: '8px',
                                marginBottom: '0px',
                            }}
                        />
                    )}
                </Box>
                {activeFilter !== 'Animal' && activeFilter !== 'Body area' && activeFilter !== 'Diagrams' && (
                    <>
                        {clinicType === 'vet' && (
                            <>
                                <Box display="flex" alignItems="center" width="100%">
                                    Filter by
                                </Box>
                                <SearchFiltersVet activeFilter={activeFilter} onFilterChange={onFilterChange} />
                            </>
                        )}
                        {categories && Object.keys(categories).length > 0 && activeCategory !== 'Diagrams' && (
                            <>
                                <Box display="flex" alignItems="center" width="100%">
                                    Categories
                                </Box>
                                <SearchCategories
                                    categories={categories}
                                    activeCategory={activeCategory}
                                    onCategoryChange={onCategoryChange}
                                />
                            </>
                        )}
                    </>
                )}
            </Stack>
        );
    },
);
