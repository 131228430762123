import React, { useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import config from '../../../config';

const accountTypes = config.accountTypes;

const OnboardType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isLVS = searchParams.get('LVS') === 'true';
  const [userData, setUserData] = useState({ ...location.state?.userData });

  const handleBack = async () => {
    localStorage.clear();
    if (isLVS) {navigate('/onboard/offer-lvs');} else {navigate('/');}
  };

  const handleAccountTypeChange = (type) => {
    setUserData(userData => ({
      ...userData,
      subscriptionData: {
        accountType: type,
        trialActive: false,
        trialEndDate: null,
        cancelled: false,
        subscriptionEndDate: null,
        stripeCustomerId: null
      }
    }));
  };

  const handleNext = () => {
    const nextStep = '/onboard/name';
    const nextPath = isLVS ? `${nextStep}?LVS=true` : nextStep;
    navigate(nextPath, { state: { userData } });
  };

  const isNextButtonDisabled = !userData.subscriptionData || !userData.subscriptionData?.accountType;

  const inputChildren = (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {accountTypes.map((account) => {
        const isSelected = userData.subscriptionData?.accountType === account.type;
        return (
          <Box
            key={account.type}
            onClick={() => handleAccountTypeChange(account.type)}
            sx={{
              p: isSelected ? '5px' : '0',
              borderRadius: '16px',
              border: isSelected ? 'none' : '1px solid #E0E0E0',
              background: isSelected ? 'linear-gradient(to right, #ff4081, #ff80ab)' : 'transparent',
              cursor: 'pointer',
              transition: 'border-color 0.3s, background-color 0.3s',
              '&:hover': {
                borderColor: 'primary.main',
              },
            }}
          >
            <Box
              className="onboarding-block"
              sx={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold'}}>
                {account.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.description}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );

  const renderButtonGroup = () => (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "auto", padding: "24px 0" }}>
      <Box onClick={handleBack} sx={{ display: 'flex', alignItems: 'center', width: 120 }}>
        <KeyboardBackspaceIcon />
        <Button
          sx={{
            color: '#000453',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 600,
          }}
        >
          Go back
        </Button>
      </Box>
      <PinkGradientButton
        variant="contained"
        color="primary"
        onClick={handleNext}
        disabled={isNextButtonDisabled}
        sx={{ flexGrow: 1, marginLeft: 1, position: 'relative', maxWidth: 200, m: 0,}}
      >
        Next
      </PinkGradientButton>
    </Box>
  );

  return (
    <OnboardingLayout
      title="What describes you best?"
      subtitle="Tell us more about your situation, this will help us to recommend the right plan for you."
      previousStepPath="/onboard/login"
      inputChildren={inputChildren}
      navChildren={renderButtonGroup()}
      currentStep={0}
      userData={userData}
      backgroundColor={false}
    />
  );
};

export default OnboardType;
