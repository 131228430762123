import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Container, useMediaQuery, useTheme } from '@mui/material';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import DisplayHandout from './display-handout';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useHandoutData } from './handout-data-context';
import HandoutActions from './handout-actions';
import HandoutBreadcrumb from './handout-breadcrumb';
import AuthorsAndReferences from './authors-and-references';
// Import necessary components
import { Switch, FormControlLabel, FormGroup } from '@mui/material';

function HandoutViewer() {
    const location = useLocation();
    const breadcrumbs = location.state?.breadcrumbs;
    const { id: contentId } = useParams();
    const navigate = useNavigate();
    const { contentStore, userDataStore } = useContext(StoreContext);
    const { handoutData, setHandoutData } = useHandoutData();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [showAuthorsAndReferences, setShowAuthorsAndReferences] = useState(false);
    const [showLabels, setShowLabels] = useState(true);
    // set if they should SHOW
    const [show3DModel, setShow3DModel] = useState(true);
    const [showDiagram, setShowDiagram] = useState(true);
    const [showTutorialVideo, setShowTutorialVideo] = useState(true);

    // State variables to check if the handout contains the sections like 3D, or diagram
    const [has3DModel, setHas3DModel] = useState(false);
    const [hasDiagram, setHasDiagram] = useState(false);
    const [hasTutorialVideo, setHasTutorialVideo] = useState(false);
    const MAX_RECENT_HANDOUTS = 5;

    // TODO: not sure if we need this?
    const formatDate = (timestamp) => {
        if (!timestamp) return 'Date not available'; // Handle null timestamp
        const date = new Date(timestamp);
        return date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    useEffect(() => {
        if (!handoutData) {
            console.log(`Fetching content data for contentId: ${contentId}`);
            const fetchedData = contentStore.getContentFromIds([contentId]);
            if (fetchedData && fetchedData.length > 0) {
                console.warn('fetchedData: ', fetchedData);
                setHandoutData(fetchedData[0]);
            } else {
                console.log('failed to fetch data');
            }
        } else {
            const locale = 'en-UK'; // TODO: this might be put higher (will be changed later anyway)
            const localeData = handoutData.contentData[locale] || [];
            console.log('Locale Data:', localeData);

            // Check for 3D model
            const has3D = localeData.some((section) => section.blocks.some((block) => block.type === '3dModel'));
            console.log(`Has 3D Model: ${has3D}`);
            setHas3DModel(has3D);

            // Check for Diagram (now looking for 'illustration' blocks)
            const hasDiag = localeData.some((section) => section.blocks.some((block) => block.type === 'illustration'));
            console.log(`Has Diagram (Illustration): ${hasDiag}`);
            setHasDiagram(hasDiag);

            // Check for Tutorial Video - THIS IS ONLY MOCK UP - as we do not have it yet
            const hasVideo = localeData.some(
                (section) => section.blocks.some((block) => block.type === 'tutorialVideo'), // this will need to be changed
            );
            setHasTutorialVideo(hasVideo);
        }
        updateRecentHandouts(contentId);
    }, [contentId, contentStore, handoutData, setHandoutData]);

    const updateRecentHandouts = (newContentId) => {
        const recentHandouts = JSON.parse(localStorage.getItem('recentHandouts')) || [];
        const updatedRecentHandouts = [newContentId, ...recentHandouts.filter((id) => id !== newContentId)].slice(
            0,
            MAX_RECENT_HANDOUTS,
        );
        localStorage.setItem('recentHandouts', JSON.stringify(updatedRecentHandouts));
        console.log(`Updated recent handouts: ${updatedRecentHandouts}`);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const toggleAuthorsAndReferences = () => {
        setShowAuthorsAndReferences(!showAuthorsAndReferences);
        console.warn('change!', showAuthorsAndReferences);
    };

    if (!handoutData) {
        return (
            <Box sx={{ my: 2 }}>
                <Typography>Loading...</Typography>
            </Box>
        );
    }

    return (
        <>
            <HandoutBreadcrumb breadcrumbs={breadcrumbs} handoutTitle={handoutData.contentName} />
            <HandoutActions
                handoutData={handoutData}
                onToggleAuthorsAndReferences={toggleAuthorsAndReferences}
                showLabels={showLabels}
                show3DModel={show3DModel}
                showDiagram={showDiagram}
                showTutorialVideo={showTutorialVideo} // we do not have this yet
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: isTablet ? 0 : 4 }}>
                {/* Left Spacer */}
                {!isTablet && (
                    <Box
                        sx={{
                            width: '250px', // Same width as Handout Add-ons
                            mr: 2,
                            // Left spacer to balance the Handout Add-ons on the right
                        }}
                    />
                )}
                {/* Handout Content */}
                <Container
                    component={'section'}
                    maxWidth="md"
                    sx={{
                        width: '100%',
                        overflowY: 'auto',
                        backgroundColor: 'white',
                        // Restore original margins
                        // px: 3,
                        // py: 2,
                    }}
                    className="handout-container"
                >
                    <Box sx={{ my: 2 }} component={'section'}>
                        {showAuthorsAndReferences ? (
                            <AuthorsAndReferences
                                handoutData={handoutData}
                                onToggleAuthorsAndReferences={toggleAuthorsAndReferences}
                            />
                        ) : (
                            <DisplayHandout
                                handoutData={handoutData}
                                showLabels={showLabels}
                                setShowLabels={setShowLabels}
                                show3DModel={show3DModel}
                                showDiagram={showDiagram}
                                showTutorialVideo={showTutorialVideo}
                            />
                        )}
                    </Box>
                </Container>
                {/* Handout Add-ons Section */}
                {!isTablet && (
                    <Box
                        sx={{
                            width: '250px',
                            ml: 2,
                            padding: 2,
                            // backgroundColor: 'transparent',
                        }}
                    >
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                whiteSpace: 'nowrap', // Prevent text from wrapping
                            }}
                        >
                            Handout Add-ons
                        </Typography>
                        <FormGroup>
                            {has3DModel && userDataStore?.subscriptionData?.accountType != 'Student' && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={show3DModel}
                                            onChange={(e) => setShow3DModel(e.target.checked)}
                                        />
                                    }
                                    label="3D model"
                                />
                            )}
                            {hasDiagram && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showDiagram}
                                            onChange={(e) => setShowDiagram(e.target.checked)}
                                        />
                                    }
                                    label="Diagram"
                                />
                            )}
                            {hasTutorialVideo && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showTutorialVideo}
                                            onChange={(e) => setShowTutorialVideo(e.target.checked)}
                                        />
                                    }
                                    label="Tutorial video"
                                />
                            )}
                        </FormGroup>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default HandoutViewer;
