import React, { useState } from 'react';
import { Box, CircularProgress } from "@mui/material";
import ErrorDialog from './ErrorDialog';
import SuccessDialog from './SuccessDialog';

/* Example usage

onErrorConfirm is useful - do some actions when the user clicks OK on the error dialog
override onSuccess to prevent the default success dialog

<ApiIconButton
          apiCall={handleConfirm}          
          onSuccess={() => {}}          
          buttonLabel="Confirm"
          disabled={isConfirmDisabled} // Disable the button based on validation
          errorTitle="Email sending error"
          errorMessage="There was a problem sending the email. Please try again."
          onErrorConfirm={resetAndCancel}
        />

*/

const ApiIconButton = ({
  apiCall,
  onSuccess,
  onError,
  icon,
  buttonStyle,
  errorTitle,
  errorMessage,
  successTitle,
  successMessage,
  disabled = false,
  onErrorConfirm = () => { },
  onSuccessConfirm = () => { },
  showSuccessDialog = true,
  ariaLabel
}) => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

  const defaultErrorHandler = (error) => {
    setErrorDetails(error.toString());
    setOpenErrorDialog(true);
  };

  const defaultSuccessHandler = () => {
    setOpenSuccessDialog(true);
  };

  const handleClick = async () => {
    setIsLoading(true);  
    try {
      console.log("Operation started...");
      const result = await apiCall();
      console.log("Operation completed successfully!");

      if (onSuccess) {
        onSuccess(result);
      }

      if (showSuccessDialog) {
        defaultSuccessHandler();
      }
    } catch (error) {
      console.error("Operation failed:", error);
      if (onError) {
        onError(error);
      } else {
        defaultErrorHandler(error);
      }
    } finally {
      setIsLoading(false);  
    }
  };

  return (
    <>
      <Box
        sx={{...buttonStyle, display: 'inline-block'}}
        onClick={handleClick}
        disabled={isLoading || disabled}
        aria-label={ariaLabel}  
      >
        {isLoading ? <CircularProgress sx={{marginBottom: '-6px'}} size={24} /> : icon}
      </Box>

      <ErrorDialog
        open={openErrorDialog}
        handleClose={() => {
          setErrorDetails(null);
          setOpenErrorDialog(false);
          onErrorConfirm();
        }}
        title={errorTitle || "Error"}
        message={errorMessage || "An error occurred."}
        details={errorDetails}
      />

      <SuccessDialog
        open={openSuccessDialog}
        handleClose={() => {
          setOpenSuccessDialog(false);
          onSuccessConfirm();
        }}
        title={successTitle || "Success"}
        message={successMessage || "Your action was successful."}
      />
    </>
  );
};

export default ApiIconButton;