import React, { useState, useEffect, useContext } from 'react';
import { Box, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import ImageCacheContext from './image-cache-context';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import DownloadUtils from '../../../utils/download-utils';
import UserApi from '../../../rest-api/user-api';
import Model3DIcon from '../../../assets/icons/3D-Preview.png'; // Fallback image for 3D models

function HandoutImageLoader({ src, alt, imageStore, handoutData, ...props }) {
    const [loadedSrc, setLoadedSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const recentlyCachedUrls = useContext(ImageCacheContext);
    const { authStore } = useContext(StoreContext);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        async function loadImage() {
            try {
                console.log(`Trying to load image from IndexedDB: ${src}`);

                const imageObject = await imageStore.getImageFromIndexedDB(src);

                if (imageObject && imageObject.data) {
                    console.log(`Image found in IndexedDB: ${src}`);
                    setLoadedSrc(imageObject.data);
                    setLoading(false);
                } else {
                    console.log(`Image not found in IndexedDB, fetching: ${src}`);
                    await fetchAndStoreImage();
                }
            } catch (error) {
                console.error('Error loading image from IndexedDB:', error);
                setLoading(false);
            }
        }

        async function fetchAndStoreImage() {
            try {
                const locale = 'en-UK';
                const idToken = authStore.idToken;
                const localeData = handoutData.contentData[locale] || [];

                // Add logging to check the structure of localeData and blocks
                console.log('Locale Data:', localeData);

                // Checking if this is a 3D model by looking for a block with type '3dModel'
                const is3DModel = localeData.every((section) => {
                    return section.blocks.every((block) => block.type === '3dModel' || block.type === 'documentTitle');
                });

                console.log(`Is 3D Model: ${is3DModel}`);

                // 3D model image logic
                if (is3DModel) {
                    const modelBlock = localeData
                        .flatMap((section) => section.blocks)
                        .find((block) => block.type === '3dModel');

                    console.log('Model Block:', modelBlock);

                    if (modelBlock && modelBlock.imageSrc) {
                        const imageSrc = modelBlock.imageSrc;

                        console.log(`Fetching image for 3D model from URL: ${imageSrc}`);

                        // Fetch the image for the 3D model
                        const payload = { files: [{ method: 'get', key: imageSrc }], contentCategory: 'image' };
                        const presignedUrl = await UserApi.generatedPresignedUrls(idToken, payload);

                        const downloadResult = await DownloadUtils.downloadImageFromPresignedUrl(
                            presignedUrl.getUrls[0]['presignedUrl'],
                        );

                        if (downloadResult && downloadResult.base64data) {
                            const base64data = downloadResult.base64data;
                            const etag = downloadResult.etag;

                            console.log(`Saving 3D model image to IndexedDB: ${imageSrc}`);
                            await imageStore.saveImageToIndexedDB(imageSrc, base64data, etag);

                            setLoadedSrc(base64data);
                        } else {
                            console.error('Failed to download 3D model image', downloadResult.reason);
                            setLoadedSrc(Model3DIcon); // Use fallback image
                        }
                    } else {
                        console.log('No imageSrc found for 3D model, using fallback');
                        setLoadedSrc(Model3DIcon); // Use fallback image if imageSrc is missing
                    }
                } else {
                    // Handle regular handout images (as before)
                    const introductionSection = localeData.find(
                        (section) => section.sectionName === 'introduction' || section.sectionName === 'diagram',
                    ) || { blocks: [] };

                    const firstIllustration = introductionSection.blocks.find((block) => block.type === 'illustration');

                    if (firstIllustration) {
                        const imageSrc = firstIllustration.src;

                        console.log(`Fetching regular handout image from URL: ${imageSrc}`);

                        const payload = { files: [{ method: 'get', key: imageSrc }], contentCategory: 'image' };
                        const presignedUrl = await UserApi.generatedPresignedUrls(idToken, payload);

                        const downloadResult = await DownloadUtils.downloadImageFromPresignedUrl(
                            presignedUrl.getUrls[0]['presignedUrl'],
                        );

                        if (downloadResult && downloadResult.base64data) {
                            const base64data = downloadResult.base64data;
                            const etag = downloadResult.etag;

                            console.log(`Saving regular handout image to IndexedDB: ${imageSrc}`);
                            await imageStore.saveImageToIndexedDB(imageSrc, base64data, etag);

                            setLoadedSrc(base64data);
                        } else {
                            console.error('Failed to download image', downloadResult.reason);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching and saving image:', error);
            } finally {
                setLoading(false);
            }
        }
        loadImage();
    }, [src, imageStore, handoutData, authStore]);

    if (loading || !loadedSrc) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <img
            src={loadedSrc}
            alt={alt}
            style={isMobile ? { width: '100%', height: 'auto' } : { maxWidth: '100%', height: 'auto' }}
            {...props}
        />
    );
}

export default HandoutImageLoader;
