import React, { useContext, useEffect, useState, useRef } from 'react';
import { Box, Button, Typography, CircularProgress, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Divider, useMediaQuery } from '@mui/material';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import DownloadUtils from '../../../utils/download-utils';
import UserApi from '../../../rest-api/user-api';
import { useNavigate } from 'react-router-dom';
import profilePlaceholder from '../../../assets/logos/profile_placeholder.svg';
import { useUnsavedChanges } from '../UnsavedChangesContext';
import SettingsUser from './settings-user';
import SettingsClinic from './settings-clinic';
import UnsavedChangesModal from '../../UIControls/UnsavedChangesModal';
import SettingsPassword from './settings-password';
import SettingsDeleteAccount from './settings-delete-account';

export function SettingsMultiUserScreen() {
  const appStateStore = useContext(StoreContext);
  const { authStore, userDataStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const idToken = authStore.idToken;
  const [clinicianData, setClinicianData] = useState(null);
  const [clinicImage, setClinicImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [passwordChangeError, setPasswordChangeError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isDeleteLogoDialogOpen, setIsDeleteLogoDialogOpen] = useState(false);
  const [isVerificationDialogOpen, setIsVerificationDialogOpen] = useState(false);
  const [pendingClinicDataUpdate, setPendingClinicDataUpdate] = useState(null);
  const [trialExpiryTime, setTrialExpiryTime] = useState(null);

  const { hasUnsavedChanges, setHasUnsavedChanges, onConfirmNavigation, onCancelNavigation, showPrompt} = useUnsavedChanges();
  const isMobile = useMediaQuery('(max-width:490px)');

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    if (hasUnsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  
  useEffect(() => {
    fetchClinicianData();
  }, []); 

  const fetchClinicianData = async () => {
    setLoading(true);
    try {
      const clinicianDataResponse = await UserApi.getClinicianData(authStore.idToken);
      if (clinicianDataResponse) {
        if (clinicianDataResponse.multiuserData && clinicianDataResponse.multiuserData.multiuserUsers) {
          const foundUser = clinicianDataResponse.multiuserData.multiuserUsers.find(user => user.userId === userDataStore?.userId);
          if (foundUser) {
            console.warn(foundUser, formattedData);
            clinicianDataResponse.userData.firstName = foundUser.firstName;
            clinicianDataResponse.userData.lastName = foundUser.lastName;
            clinicianDataResponse.userData.emailAddress = foundUser.emailAddress;
            clinicianDataResponse.userData.jobTitle = foundUser.jobTitle;
            clinicianDataResponse.userData.mobile = foundUser.mobile;
            clinicianDataResponse.userData.yearsExperience = foundUser.yearsExperience;
          }
        }
        setClinicianData(clinicianDataResponse);
        console.warn(formattedData);
        clinicianDataResponse.clinicData = clinicianDataResponse.clinicData || {};
        console.warn("Clinician data fetched and component state updated successfully.", clinicianDataResponse);
  
        // Placeholder end date in epoch to find days until expiry
        const { subscriptionEndDate } = clinicianDataResponse.subscriptionData || {};
  
        if (subscriptionEndDate) {
          const currentTime = Date.now();
          const subscriptionEndTimeInMilliseconds = subscriptionEndDate;
          const differenceInMilliseconds = subscriptionEndTimeInMilliseconds - currentTime;
          const millisecondsInOneDay = 24 * 60 * 60 * 1000;
          const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;
  
          setTrialExpiryTime(Math.floor(differenceInDays));
          console.warn('Expiry in days:', Math.floor(differenceInDays));
        }
  
        // Initialize formattedData with properties, ensuring clinicData defaults to {}
        const clinicData = clinicianDataResponse.clinicData || {};
  
        var formattedData = {
          firstName: clinicianDataResponse.userData.firstName,
          lastName: clinicianDataResponse.userData.lastName,
          emailAddress: clinicianDataResponse.userData.emailAddress,
          mobile: clinicianDataResponse.userData.mobile || '',
          jobTitle: clinicianDataResponse.userData.jobTitle || '',
          yearsExperience: clinicianDataResponse.userData.yearsExperience || '',
          clinicName: clinicData.clinicName || '',
          clinicNumber: clinicData.clinicNumber || '',
          clinicSize: clinicData.clinicSize || '',
          clinicType: clinicData.clinicType || '',
          clinicImageId: '',
          showLogoInHandouts: clinicianDataResponse.showLogoInHandouts,
          handouts: JSON.stringify(clinicianDataResponse.handouts),
          subscriptionData: clinicianDataResponse.subscriptionData || {}
        };
  
        if (clinicData.clinicImageId) {
          const payload = {
            files: [{ method: "get", key: clinicData.clinicImageId }],
            contentCategory: "clinic_image"
          };
  
          const clinicImageURL = await UserApi.generatedPresignedUrls(authStore.idToken, payload);
          if (clinicImageURL && clinicImageURL.getUrls) {
            console.warn('Clinic image found:', clinicImageURL);
            setClinicImage(clinicImageURL.getUrls[0].presignedUrl);
            let clinicImageBase64 = await DownloadUtils.downloadImageFromPresignedUrl(clinicImageURL.getUrls[0].presignedUrl);
            formattedData.clinicImageId = clinicImageBase64.base64data;
          }
        } else {
          setClinicImage('');
        }
      }
    } catch (error) {
      console.error("Failed to fetch clinician data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSignout = async () => {
    await authStore.logout();
    await appStateStore.clearUserStores();
    localStorage.clear();
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    
    // Unique case: handling combined Username field
    if (name === 'username') {
      const names = value.split(' ');
      const firstName = names[0] || '';
      const lastName = names.slice(1).join(' ') || '';
  
      setClinicianData((prevState) => ({
        ...prevState,
        userData: {
          ...prevState.userData,
          firstName,
          lastName,
        },
      }));
    } else {
      const [parentKey, childKey] = name.split('.');
  
      setClinicianData((prevState) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value,
        },
      }));
    }
    setHasUnsavedChanges(true);  
  };

  const updateClinicDataMultiuser = async () => {  
    const clinicDataPayload = {
      firstName: clinicianData.userData.firstName,
      lastName: clinicianData.userData.lastName,
      emailAddress: clinicianData.userData.emailAddress,
      mobile: clinicianData.userData.mobile || '',
      jobTitle: clinicianData.userData.jobTitle || '',
      yearsExperience: clinicianData.userData.yearsExperience || '',
      inviteId: userDataStore.userData.inviteId,
      action: "updateMultiuser",
    };
    console.warn("payload", clinicDataPayload);
    await performClinicDataMultiuserUpdate(clinicDataPayload);
    setHasUnsavedChanges(false);
  }

  const performClinicDataMultiuserUpdate = async (clinicDataPayload) => {
    try {
      const updatedData = await UserApi.updateClinicianDataMultiuser(authStore.idToken, clinicDataPayload);
      console.warn('Clinic data multiuser update API response:', updatedData);
      fetchClinicianData(); 
    } catch (error) {
      console.error('Failed to update clinic data:', error);
    }
  };
    
  return (
    <CenteredWrapper component="main" sx={{zIndex: 1, position: 'relative', overflowX: 'hidden'}}>
      {
        loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) 
        : 
        (
        <Box sx={{ gap: 0, width: '100%', maxWidth: 638}}>
          <Box>               
            <Box
              style={{
                height: '108px', 
                overflow: 'hidden',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundImage: `url(${clinicImage})`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                margin: '0 auto',
                marginBottom: '10px'
              }}
            >
              {
                !clinicImage && <img src={profilePlaceholder} alt='Logo Placeholder'/>
              }
            </Box>
            <Box style={{textAlign: 'center'}}>
              <Typography variant="h6">{clinicianData?.clinicData?.clinicName}</Typography>
              <Typography variant="subtitle2">{clinicianData?.userData?.emailAddress}</Typography>
            </Box>

            <Box style={{ textAlign: 'left', marginTop: '24px'}}>
              <Typography variant="h4" sx={{marginBottom: 5, fontSize: 32}}>Manage your account</Typography>
              <Divider sx={{marginBottom: 4}}/>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {
              trialExpiryTime <= 30 && 
                <Typography variant="subtitle" style={{ marginBottom: '0' }}>
                  You have <strong> {trialExpiryTime} days</strong> left of your {clinicianData?.subscriptionData?.accountType} {clinicianData?.subscriptionData?.trialActive ? "free trial" : ""} plan.
                </Typography>
              }
              </Box>

              <SettingsClinic
                setClinicianData={setClinicianData}
                clinicianData={clinicianData}
                clinicImage={clinicImage}
                handleChangeInput={handleChangeInput}
                handleSignout={handleSignout}
                fileInputRef={fileInputRef}
                setIsDeleteLogoDialogOpen={setIsDeleteLogoDialogOpen}
                pendingClinicDataUpdate={pendingClinicDataUpdate}
                setPendingClinicDataUpdate={setPendingClinicDataUpdate}
                setHasUnsavedChanges={setHasUnsavedChanges}
                idToken={idToken}
                isMobile={isMobile}
                passwordChangeError={passwordChangeError}
                setPasswordChangeError={setPasswordChangeError}
                isSuccessDialogOpen={isSuccessDialogOpen}
                setIsSuccessDialogOpen={setIsSuccessDialogOpen}
                isDeleteLogoDialogOpen={isDeleteLogoDialogOpen}
                isVerificationDialogOpen={isVerificationDialogOpen}
                setIsVerificationDialogOpen={setIsVerificationDialogOpen}
                showPrompt={showPrompt}
                onConfirmNavigation={onConfirmNavigation}
                onCancelNavigation={onCancelNavigation}
                hasUnsavedChanges={hasUnsavedChanges}
                loading={loading}
                setLoading={setLoading}
                readOnly={true}
              />

              
              <SettingsUser
                clinicianData={clinicianData}
                handleChangeInput={handleChangeInput}
                isMultiUser={true}
                updateClinicDataMultiuser={updateClinicDataMultiuser}
              />

              <SettingsPassword isChangePasswordDialogOpen={isChangePasswordDialogOpen} setIsChangePasswordDialogOpen={setIsChangePasswordDialogOpen} />

              <Divider sx={{marginTop: 5, marginBottom: 5}} />

              <SettingsDeleteAccount />
                                
            </Box>
          </Box>
          
          <Button className='logout-btn' variant="outlined" color="primary" onClick={handleSignout} sx={{ borderRadius: '36px', width: '200px', height: 48, borderColor: '#616063', color:'#616063', textAlign: 'center'}}>
            Logout
          </Button>
        </Box>
        )
      }
      <UnsavedChangesModal
        open={showPrompt}
        onDiscardChanges={onConfirmNavigation}
        onCancel={onCancelNavigation}
      />
    </CenteredWrapper>
  );
}
