import React, { useEffect } from 'react';
import { Container, Box, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import OnboardHeader from './onboard-header';
import config from '../../../config';
import subscriptionPlans from '../../authenticated/settings/subscriptionPlans';
import SubscriptionCardVertical from '../../UIControls/SubscriptionCardVertical';
import bgMarque from '../../../assets/logos/cetra_marque.png';

const OnboardOfferLVS = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    // TEMP: Due to wrong link used for QR code, redirect to production URL if currently on the dev environment
    console.warn(window.location)
    if (window.location.hostname === 'login-dev.cetravets.com') {
      window.location.href = 'https://login.cetravets.com/lvs';
      return;
    }

    // Hide AccessibilityWidget when this component mounts
    const widget = document.getElementById('accessibility-menu');
    if (widget) widget.style.display = 'none';

    return () => {
      if (widget) widget.style.display = 'block';
    };
  }, []);

  return (
    <>
      <OnboardHeader />
      <Box component="main">
        <Container
          component={'section'}
          sx={{
            flex: 1,
            height: '100%',
            minHeight: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'normal',
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <Box sx={{ height: '100%' }}>
            <Box sx={{ fontWeight: 600, mt: 2, mb: 0, fontSize: isSmallScreen ? 36 : 60, color: '#000453' }}>
              Exclusive London Vet Show Offers
            </Box>
            <Box sx={{ mb: 3, fontWeight: 'normal', fontSize: isSmallScreen ? 18 : 32, color: '#000453' }}>
              Tailored plans for every stage of your veterinary career
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: isSmallScreen ? 'center' : 'space-between', flexWrap: 'wrap' }}>
              {Object.keys(subscriptionPlans).filter(plan => !plan.includes('trial')).map((planKey) => (
                <SubscriptionCardVertical key={planKey} plan={planKey} />
              ))}
            </Box>

            <PinkGradientButton
              variant="contained"
              color="primary"
              onClick={() => navigate(`/onboard/type?LVS=true`)}
              sx={{ mt: 3 }}
            >
              Get started
            </PinkGradientButton>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default OnboardOfferLVS;
