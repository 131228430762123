import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import gradientImage from '../../assets/images/cetra-gradient_720.png';

const PinkGradientButton = ({ onClick, children, sx, disabled, className = 'pinkGradient-btn' }) => {
    const defaultSx = {
        width: '200px',
        marginY: 1.5,
        backgroundImage: `url(${gradientImage})`,
        borderRadius: '36px',
        height: 48,
        position: 'relative',
        color: 'white',
        textAlign: 'center',
        ...(disabled && {
            backgroundImage: 'none',
            backgroundColor: '#F0EFF1',
            color: '#979797',
            cursor: 'not-allowed',
            pointerEvents: 'none',
        }),
    };

    return (
        <Button className={className} onClick={onClick} sx={{ ...defaultSx, ...sx }} disabled={disabled}>
            {children}
        </Button>
    );
};

PinkGradientButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    sx: PropTypes.object,
    disabled: PropTypes.bool,
};

PinkGradientButton.defaultProps = {
    onClick: () => {},
    sx: {},
    disabled: false,
};

export default PinkGradientButton;
