import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import PinkGradientButton from './PinkGradientButton';

const NotificationBar = observer(({ message, buttonLabel, onButtonClick }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        background: '#000453',
        color: 'white',
        padding: "0 40px",
        position: 'relative',
        zIndex: 1,
        width: "100%",
      }}
    >
      <Typography>
        {message}
      </Typography>
      <PinkGradientButton
        onClick={onButtonClick}
        sx={{height: 32, width: 170}}
      >
        {buttonLabel}
      </PinkGradientButton>
    </Box>
  );
});

export default NotificationBar;
