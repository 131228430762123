import React, { useState, useEffect, useContext } from 'react';
import { Box, CircularProgress } from '@mui/material';
import UserApi from '../../../rest-api/user-api';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import DownloadUtils from '../../../utils/download-utils';
import ImageCacheContext from './image-cache-context';

function HandoutImageManager({ handoutData, children }) {
  const { authStore, imageStore } = useContext(StoreContext);
  const [recentlyCachedUrls, setRecentlyCachedUrls] = useState(new Set());
  const [loading, setLoading] = useState(true);  

  useEffect(() => {
    loadOrFetchImages();
  }, []);

  function extractImageUrls() {
    console.log('extractImageUrls');
    const extractedUrls = [];
    handoutData.contentData['en-UK'].forEach(section => {
      section.blocks.forEach(block => {
        if (block.type === 'iconStrip') {
          block.iconDetails.forEach(icon => extractedUrls.push(icon.img));
        } else if (block.type === 'illustration') {
          extractedUrls.push(block.src);
        }
      });
    });
    return extractedUrls;
  }

  async function loadOrFetchImages() {
    try {
      if (!handoutData) {
        throw new Error('No handout data found');
      }

      const imageUrls = extractImageUrls(handoutData);
      console.log('loadOrFetchImages imageUrls: ', imageUrls);
      const idToken = authStore.idToken;

      const imageObjects = await Promise.all(
        imageUrls.map(url => imageStore.getImageFromIndexedDB(url))
      );

      const cachedImages = imageObjects.filter(obj => obj !== null);
      console.log(`number of cached images: ${cachedImages.length}`);
      console.log('Cached images:', cachedImages);

      // Extract URLs for which the images were not found in IndexedDB
      const uncachedUrls = imageUrls.filter((url, index) => !imageObjects[index]);
      console.log('loadOrFetchImages uncachedUrls: ', uncachedUrls);

      if (uncachedUrls.length) {
        const payload = {
          files: uncachedUrls.map(url => ({ method: "get", key: url })),
          contentCategory: "image",
        };

        const result = await UserApi.generatedPresignedUrls(idToken, payload);
        const presignedUrls = result.getUrls;

        const downloadResults = await Promise.allSettled(
          presignedUrls.map(result => DownloadUtils.downloadImageFromPresignedUrl(result.presignedUrl))
        );

        const successfulDownloads = downloadResults
          .filter(result => result.status === 'fulfilled')
          .map(result => result.value);

        // Save successfully downloaded images and their eTags to IndexedDB
        await Promise.all(successfulDownloads.map((downloadResult, index) => {
          const url = uncachedUrls[index];
          return imageStore.saveImageToIndexedDB(url, downloadResult.base64data, downloadResult.eTag);
        }));

        const successfulUrls = successfulDownloads.map((_, index) => uncachedUrls[index]);
        setRecentlyCachedUrls(prevUrls => new Set([...prevUrls, ...successfulUrls]));
      }
      setLoading(false);
    } catch (error) {
      console.error('Error in loadOrFetchImages:', error);
      setLoading(false);  
    }
  }

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" width={ "100%" } height={118}><CircularProgress /></Box>
  }

  return (
    <ImageCacheContext.Provider value={recentlyCachedUrls}>
      {children}
    </ImageCacheContext.Provider>
  );
}

export default HandoutImageManager;
