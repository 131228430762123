const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE || 'vet';
const isProd = process.env.REACT_APP_USER_API_URL.includes('prod');

const config = {
    clinicType,
    isProd,
    mixpanelToken: isProd
        ? clinicType === 'vet'
            ? 'cf4c559bb22f7ba78ba1667adc6bdbe4' // vet prod token
            : '189e32a152f429590258281c65ec1492' // physio prod token
        : clinicType === 'vet'
          ? 'b9a4fc257db382b75fb35c9011ff6f98' // vet dev/staging token
          : '3670bd50f368dec66e70e31dd0090f8e', // physio dev/staging token

    loginBanner:
        clinicType === 'vet'
            ? require('./assets/banners/loginPageBanner.png')
            : require('./assets/banners/loginPageBannerPhysio.png'),

    cetraBlueLogo:
        clinicType === 'vet'
            ? require('./assets/logos/cetra_blue.png')
            : require('./assets/logos/cetra_blue_physio.png'),

    gtagId: process.env.REACT_APP_GTAG_ID || '',

    accountTypes:
        clinicType === 'vet'
            ? [
                  {
                      type: 'Student',
                      title: "I'm a student",
                      description:
                          'I am pursuing a degree/course in veterinary science or a related field involving animals.',
                  },
                  {
                      type: 'Professional',
                      title: "I'm a professional",
                      description: 'I will use Cetra to enhance my work as an individual.',
                  },
                  {
                      type: 'Clinic',
                      title: "I'm the admin of a practice",
                      description: 'I want to use Cetra to support and improve the care my practice delivers.',
                  },
              ]
            : [
                  {
                      type: 'Student',
                      title: "I'm a student",
                      description: 'I am pursuing a degree/course relating to MSK.',
                  },
                  {
                      type: 'Professional',
                      title: "I'm a professional",
                      description: 'I will use Cetra to enhance my work as an individual.',
                  },
                  {
                      type: 'Clinic',
                      title: "I'm the admin of a clinic",
                      description: 'I want to use Cetra to support and improve the care my clinic delivers.',
                  },
              ],

    feedbackText: {
        vet: 'We highly appreciate detailed information, as it plays a crucial role in fulfilling our mission of improving animal welfare through comprehensive veterinary knowledge.',
        physio: 'We highly appreciate detailed information, as it plays a crucial role in fulfilling our mission of offering comprehensive medical knowledge.',
    },

    landingPageUrl: {
        vet: 'https://www.cetravets.com',
        physio: 'https://www.cetrahealth.com',
    },

    surveyUrl: {
        vet: 'https://forms.office.com/e/10XN2xgEJm',
        physio: 'https://forms.office.com/Pages/ResponsePage.aspx?id=lb2tEXZJYkGdQ4v5VcXGoz5L90c76qVHgH-kiF05Vt1UMFo5TjRRSE9VWUM4WkgyOFg0T0ZXVFhDSy4u',
    },

    tsCsUrl: {
        vet: 'https://www.cetravets.com/legal/terms-of-service',
        physio: 'https://www.cetrahealth.com/legal/terms-of-service',
    },

    upgradeImages: {
        paywall: {
            clinic: {
                vet: require('./assets/banners/ctaUpgradeClinicPaywall.png'),
                physio: require('./assets/banners/ctaUpgradeClinicPaywallPhysio.png'),
            },
            professional: {
                vet: require('./assets/banners/ctaUpgradeProfessionalPaywall.png'),
                physio: require('./assets/banners/ctaUpgradeProfessionalPaywallPhysio.png'),
            },
        },
        settings: {
            clinic: {
                desktop: {
                    vet: require('./assets/banners/ctaUpgradeClinic.png'),
                    physio: require('./assets/banners/ctaUpgradeClinicPhysio.png'),
                },
                mobile: {
                    vet: require('./assets/banners/ctaUpgradeClinicMobile.png'),
                    physio: require('./assets/banners/ctaUpgradeClinicMobilePhysio.png'),
                },
            },
            professional: {
                desktop: {
                    vet: require('./assets/banners/ctaUpgradeProfessional.png'),
                    physio: require('./assets/banners/ctaUpgradeProfessionalPhysio.png'),
                },
                mobile: {
                    vet: require('./assets/banners/ctaUpgradeProfessionalMobile.png'),
                    physio: require('./assets/banners/ctaUpgradeProfessionalMobilePhysio.png'),
                },
            },
            trial: {
                desktop: {
                    vet: require('./assets/banners/ctaUpgradeTrial.png'),
                    physio: require('./assets/banners/ctaUpgradeTrialPhysio.png'),
                },
                mobile: {
                    vet: require('./assets/banners/ctaUpgradeTrialMobile.png'),
                    physio: require('./assets/banners/ctaUpgradeTrialMobilePhysio.png'),
                },
            },
        },
    },

    practiceOrClinicText: clinicType === 'vet' ? 'practice' : 'clinic',
};

export const getCTAUpgradeImageUrl = (type) => {
    return config.upgradeImages.paywall[type][clinicType];
};

export const getCTAUpgradeSettingsImageUrl = (planType, isMobile = false) => {
    const viewPort = isMobile ? 'mobile' : 'desktop';
    return config.upgradeImages.settings[planType][viewPort][config.clinicType];
};

export const cetraBlueLogo = config.cetraBlueLogo;
export const feedbackMessage = config.feedbackText[clinicType];
export const landingPageUrl = config.landingPageUrl[clinicType];
export const surveyUrl = config.surveyUrl[clinicType];
export const tsCsUrl = config.tsCsUrl[clinicType];
export const practiceOrClinicText = config.practiceOrClinicText;

export default config;
