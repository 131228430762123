import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, useMediaQuery, useTheme, Box, Typography, Snackbar, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CustomToolbarButton from './toolbar/custom-toolbar-button';
import ASCopyIcon from '../../../assets/icons/Copy.svg';
import ASPrintIcon from '../../../assets/icons/Print.svg';
import ASReferencesIcon from '../../../assets/icons/References.svg';
import ApiIconButton from '../../UIControls/api-button/api-icon-button';
import ShareButton from './share-button';
import HandoutEmailButton from './handout-email-button';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { useGenerateLink } from './use-generated-link';
import AddToBundleButton from '../../UIControls/AddToBundleButton';
import { toJS } from 'mobx';
import UserApi from '../../../rest-api/user-api';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import CTAUpgradeDialog from '../../UIControls/CTAUpgradeDialog';
import ctaUpgradeProfessionalPaywall from '../../../assets/banners/ctaUpgradeProfessionalPaywall.png';
import { getCTAUpgradeImageUrl } from '../../../config';

function HandoutActions({
    handoutData,
    onToggleAuthorsAndReferences,
    bundledHandoutsView = false,
    showLabels,
    show3DModel,
    showDiagram,
    showTutorialVideo,
    is3DViewerOpen = false,
}) {
    const { authStore, userDataStore } = useContext(StoreContext);
    const { generateLinkOnly, createShareData } = useGenerateLink(handoutData, bundledHandoutsView);
    const navigate = useNavigate();
    const theme = useTheme();
    const [currentUser, setCurrentUser] = useState(null);
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarStatus, setSnackbarStatus] = useState('success');
    const [upgradeCTAOpen, setUpgradeCTAOpen] = useState(false);
    const [upgradeCTAClinicOpen, setUpgradeCTAClinicOpen] = useState(false);
    const toggleStates = { show3DModel, showDiagram, showTutorialVideo };

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setCurrentUser(user);
            } catch (error) {
                console.error('Error fetching current user: ', error);
            }
        };
        fetchCurrentUser();
    }, []);

    const handleCopyLink = async () => {
        if (userDataStore?.subscriptionData?.accountType === 'Student') {
            setUpgradeCTAOpen(true);
            return;
        }

        // toggle states
        const link = await generateLinkOnly(toggleStates);

        try {
            // Attempt to copy using Clipboard API
            await navigator.clipboard.writeText(link);
            trackHandoutShared('Copy handout');
        } catch (error) {
            console.warn('Clipboard API failed, using fallback method.', error);
            // Fallback for Safari
            const textArea = document.createElement('textarea');
            textArea.value = link;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                if (!successful) {
                    throw new Error('Fallback copy command failed');
                }
                trackHandoutShared('Copy handout');
            } catch (error) {
                console.error('Fallback method failed: ', error);
                alert(
                    `Safari browser has limitations for accessing the clipboard. Please copy the following link manually to share with your patient: \n\n${link}`,
                );
            }
            document.body.removeChild(textArea);
        }
    };

    const handlePrint = () => {
        trackHandoutShared('Print Handout');
        handleViewHandoutPDF();
    };

    const handleViewHandoutPDF = () => {
        trackHandoutShared('View Handout PDF');
        const url = new URL(window.location.origin + `/handout/${handoutData.contentId}/pdf`);
        url.searchParams.append('showLabels', showLabels);
        window.open(url.toString(), '_blank');
    };

    const trackHandoutShared = (shareMethod) => {
        mixpanel.track('HandoutShared', {
            email: currentUser?.attributes?.email,
            shareMethod: shareMethod,
            handoutTitle: handoutData.contentName,
        });
    };

    const flattenCategories = (categories) => {
        let result = [];
        function traverse(currentObject) {
            if (typeof currentObject === 'string') {
                result.push(currentObject);
            } else if (Array.isArray(currentObject)) {
                currentObject.forEach((item) => {
                    if (typeof item === 'object') {
                        traverse(item);
                    } else {
                        result.push(item);
                    }
                });
            } else {
                for (const key in currentObject) {
                    result.push(key);
                    traverse(currentObject[key]);
                }
            }
        }
        traverse(categories);
        return result.join(', ');
    };

    const handleAddToBundledHandouts = async () => {
        let isMultiUser = userDataStore?.subscriptionData?.accountType === 'Clinic-multiuser' ? true : false;
        if (
            userDataStore?.subscriptionData?.accountType === 'Student' ||
            userDataStore?.subscriptionData?.accountType === 'Professional'
        ) {
            setUpgradeCTAClinicOpen(true);
            return;
        }
        console.warn(toJS(handoutData));
        console.warn(toJS(userDataStore));

        // Handle case where handouts may be undefined
        const userHandoutsJson = userDataStore.userData.handouts || '[]';
        let userHandouts = JSON.parse(userHandoutsJson);
        let formattedCategories = flattenCategories(handoutData.categories);
        let existingContentIds = userHandouts.map((handout) => handout.contentId);

        // Check if contentId already exists to prevent duplicates
        if (existingContentIds.includes(handoutData.contentId)) {
            setSnackbarMessage('Handout already exists in the bundle');
            setSnackbarStatus('error');
            setSnackbarOpen(true);
            return;
        }

        if (userHandouts.length >= 5) {
            setSnackbarMessage('Cannot add more than 5 handouts to the bundle');
            setSnackbarStatus('error');
            setSnackbarOpen(true);
            return;
        }

        userHandouts.push({
            contentId: handoutData.contentId,
            contentName: handoutData.contentName,
            categories: formattedCategories,
        });

        let payload = {
            handouts: userHandouts,
        };

        console.warn(payload);

        try {
            if (isMultiUser) {
                payload.action = 'updateMultiuser';
                payload.emailAddress = userDataStore.userData.emailAddress;
                payload.inviteId = userDataStore.userData.inviteId;
                await UserApi.updateClinicianDataMultiuser(authStore.idToken, payload);
            } else {
                await UserApi.updateClinicianData(authStore.idToken, payload);
            }

            // After successful API call, update the local user handouts
            userDataStore.updateUserHandouts(userHandouts);
            setSnackbarMessage('Handout added to bundle');
            setSnackbarStatus('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error adding handout to bundle: ', error);
            setSnackbarMessage('Failed to add handout to bundle');
            setSnackbarStatus('error');
            setSnackbarOpen(true);
        }
    };

    const handleShowUpgradePrompt = () => {
        if (userDataStore?.subscriptionData?.accountType === 'Student') {
            setUpgradeCTAOpen(true);
        }
    };

    const getSnackbarIcon = () => {
        return snackbarStatus === 'success' ? (
            <CheckIcon sx={{ ml: 1, color: 'white' }} />
        ) : (
            <CloseIcon sx={{ ml: 1, color: 'red' }} />
        );
    };

    return (
        <>
            <CTAUpgradeDialog
                open={upgradeCTAOpen}
                handleClose={() => {
                    setUpgradeCTAOpen(false);
                }}
                onConfirm={() => navigate('/settings/manage-subscription')}
                title="Unlock unlimited sharing and more with just one click!"
                message="You've reached a feature that's beyond your current plan. Upgrade today to enjoy full access!"
                buttonText="Upgrade plan"
                benefits={[
                    'Unlimited digital sharing of all handouts',
                    'Unlimited digital sharing of all anatomy diagrams',
                    'Personalise with your clinic details ',
                ]}
                imageUrl={ctaUpgradeProfessionalPaywall}
            />
            <CTAUpgradeDialog
                open={upgradeCTAClinicOpen}
                handleClose={() => setUpgradeCTAClinicOpen(false)}
                onConfirm={() => navigate('/settings/manage-subscription')}
                title="Upgrade now for clinic-wide access and advanced features!"
                message="You've reached a feature that's beyond your current plan. Upgrade today to add more users and enjoy full access to our features!"
                buttonText="Upgrade plan"
                benefits={[
                    'Unlimited digital sharing of all handouts & anatomy diagrams',
                    'Bundling of handouts and diagrams for seamless sharing',
                    'Personalise with your clinic logo',
                ]}
                imageUrl={getCTAUpgradeImageUrl('clinic')}
            />
            {!isTablet && (
                <Box
                    sx={{
                        width: '100%',
                        padding: bundledHandoutsView ? '0' : '0 40px',
                        height: 88,
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '@media print': {
                            display: 'none',
                        },
                        backgroundColor: 'white',
                    }}
                    className="handout-actions-container"
                >
                    {!bundledHandoutsView && (
                        <Tooltip title="Add handout to bundle" arrow>
                            <Box sx={{ cursor: 'pointer' }}>
                                <AddToBundleButton onClick={handleAddToBundledHandouts} />
                                {/* <img
                                    onClick={handleAddToBundledHandouts}
                                    src={buttonPlusIcon}
                                    alt="Add to bundled handout button"
                                /> */}
                            </Box>
                        </Tooltip>
                    )}
                    <Box sx={{ display: 'flex', gap: 3 }}>
                        {/* {!bundledHandoutsView && (
              <Tooltip title="Print - Unavailable during Beta" arrow onClick={()=>handleShowUpgradePrompt()}>
                <Box sx={{ display: 'inline-block'}}>
                <CustomToolbarButton
                  iconSrc={ASPrintIcon}
                  tooltipTitle="Print"
                  onClick={handlePrint}
                  disabled={true}
                  sx={{
                    cursor: 'not-allowed',
                    opacity: 0.3,
                  }}
                />
                </Box>
              </Tooltip>

              // <Tooltip title="Print" arrow>
              // <Box sx={{ display: 'inline-block'}}>
              // <CustomToolbarButton
              //   iconSrc={ASPrintIcon}
              //   tooltipTitle="Print"
              //   onClick={handlePrint}
              // />
              // </Box>
              // </Tooltip>
            )} */}
                        <Box
                            textAlign="center"
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}
                        >
                            {/* variant="body2" changing to smaller size */}
                            <Typography variant="body2" sx={{ color: '#E86E7B', fontSize: 8 }}>
                                Copy
                            </Typography>
                            <ApiIconButton
                                showSuccessDialog={
                                    userDataStore?.subscriptionData?.accountType === 'Student' ? false : true
                                }
                                apiCall={handleCopyLink}
                                icon={
                                    <CustomToolbarButton
                                        iconSrc={ASCopyIcon}
                                        tooltipTitle={bundledHandoutsView ? 'Copy handouts' : 'Copy handout'}
                                    />
                                }
                                successTitle="Copy to clipboard successful!"
                                successMessage="The link was copied to the clipboard successfully."
                                errorTitle="Copy to clipboard error"
                                errorMessage="There was an error copying to the clipboard. Please try again."
                                ariaLabel="Copy handout"
                            />
                        </Box>
                        <Box
                            textAlign="center"
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}
                        >
                            <Typography variant="body2" sx={{ color: '#E86E7B', fontSize: 8 }}>
                                Email
                            </Typography>
                            <HandoutEmailButton
                                handoutData={handoutData}
                                bundledHandoutsView={bundledHandoutsView}
                                currentUser={currentUser}
                                accountType={userDataStore?.subscriptionData?.accountType}
                                onUpgradeRequired={handleShowUpgradePrompt}
                                toggleStates={toggleStates} // Pass toggle states here
                            />
                        </Box>
                        <Box
                            textAlign="center"
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}
                        >
                            <Typography variant="body2" sx={{ color: '#E86E7B', fontSize: 8 }}>
                                Share
                            </Typography>
                            <ShareButton
                                generateLink={() => createShareData(toggleStates)}
                                bundledHandoutsView={bundledHandoutsView}
                                currentUser={currentUser}
                                accountType={userDataStore?.subscriptionData?.accountType}
                                onUpgradeRequired={handleShowUpgradePrompt}
                            />
                        </Box>
                        {!is3DViewerOpen && (
                            <Box
                                textAlign="center"
                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}
                            >
                                <Typography variant="body2" sx={{ color: '#E86E7B', fontSize: 8 }}>
                                    Authors
                                </Typography>
                                <CustomToolbarButton
                                    iconSrc={ASReferencesIcon}
                                    tooltipTitle="Authors and references"
                                    onClick={onToggleAuthorsAndReferences}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            {isTablet && (
                <AppBar
                    position="fixed"
                    color="default"
                    sx={{
                        top: 'auto',
                        bottom: 0,
                        backgroundColor: 'white',
                        pr: 7,
                        '@media print': { display: 'none' },
                    }}
                >
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: 88 }}>
                        {!bundledHandoutsView && (
                            <Box sx={{ cursor: 'pointer' }}>
                                <AddToBundleButton onClick={handleAddToBundledHandouts} />
                                {/* <img
                                    onClick={handleAddToBundledHandouts}
                                    src={buttonPlusIcon}
                                    alt="Add to bundled handout button"
                                /> */}
                            </Box>
                        )}
                        <Box>
                            <ShareButton generateLink={() => createShareData(toggleStates)} currentUser={currentUser} />
                            {!bundledHandoutsView &&
                                !handoutData?.categories?.Diagrams &&
                                !handoutData?.categories?.['3D Models'] && (
                                    <>
                                        {/* <Tooltip title="Print - Unavailable during Beta" arrow>
                    <Box sx={{ display: 'inline-block' }}>
                    <CustomToolbarButton
                      iconSrc={ASPrintIcon}
                      tooltipTitle="Print"
                      onClick={handlePrint}
                      disabled={true}
                      sx={{
                        cursor: 'not-allowed',
                        opacity: 0.3,
                      }}
                    />
                    </Box>
                  </Tooltip> */}
                                        <CustomToolbarButton
                                            iconSrc={ASReferencesIcon}
                                            tooltipTitle="Authors and references"
                                            onClick={onToggleAuthorsAndReferences}
                                        />
                                    </>
                                )}
                        </Box>
                    </Toolbar>
                </AppBar>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => {
                    setSnackbarOpen(false);
                }}
                message={
                    <Box display="flex" alignItems="center">
                        {snackbarMessage}
                        {getSnackbarIcon()}
                    </Box>
                }
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundImage: 'linear-gradient(132deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: 2,
                    },
                }}
            />
        </>
    );
}

export default HandoutActions;
