const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE || 'vet';
const isProd = process.env.REACT_APP_USER_API_URL.includes('prod');

const pricingDetails = {
    vet: {
        Student: {
            accountType: 'Student',
            productId: isProd ? 'prod_QjtOG8QIilyVNr' : 'prod_QXpfPHjuwthNx7',
            priceMonthlyId: isProd ? 'price_1PsPbbL2Uy8HcDMu2YcrAe2P' : 'price_1Pgk0ML2Uy8HcDMuiYWZqzBi',
            priceMonthly: 19.98,
            priceAnnually: 219.78,
            priceAnnuallyId: isProd ? 'price_1PsPbbL2Uy8HcDMuW5gTVXUW' : 'price_1PifbOL2Uy8HcDMudySkON6S',
            benefits: [
                'Single user access',
                'View all information handouts',
                'View all anatomy diagrams',
                'Basic customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'R2gDZ3',
        },
        Professional: {
            accountType: 'Professional',
            productId: isProd ? 'prod_QjtJiFdck0KupJ' : 'prod_QXpfh4Pw3WYGiz',
            priceMonthlyId: isProd ? 'price_1PsPWvL2Uy8HcDMuSzmtCgVY' : 'price_1Pgk05L2Uy8HcDMuSbHmn3nU',
            priceMonthly: 39.57,
            priceAnnually: 435.27,
            priceAnnuallyId: isProd ? 'price_1PsPWvL2Uy8HcDMuUjbHrnYL' : 'price_1PifZAL2Uy8HcDMu4rTDtuqR',
            benefits: [
                'Single user access',
                'View all information handouts',
                'View all anatomy diagrams',
                'Unlimited digital sharing',
                'Clinic name on all handouts',
                'Interactive 3D models',
                'Basic customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'Y7kVFz',
        },
        'Professional - 14 day FREE trial': {
            accountType: 'Professional',
            productId: 'trial',
            priceMonthlyId: 'trial',
            priceMonthly: 0,
            priceAnnually: 0,
            priceAnnuallyId: 'trial',
            benefits: [
                'Single user access',
                'View all information handouts',
                'View all anatomy diagrams',
                'Unlimited digital sharing',
                'Clinic name on all handouts',
                'Interactive 3D models',
                'Basic customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'XJsU8z',
        },
        Clinic: {
            accountType: 'Clinic',
            productId: isProd ? 'prod_QjtOpkXwFHjj9x' : 'prod_QXpg8C1N3zC1Xg',
            priceMonthlyId: isProd ? 'price_1PsPbiL2Uy8HcDMu40gjkd1L' : 'price_1Pgk0kL2Uy8HcDMu3VrjfPcI',
            priceMonthly: 199.49,
            priceAnnually: 2194.39,
            priceAnnuallyId: isProd ? 'price_1PsPbiL2Uy8HcDMu813gDmun' : 'price_1PifbtL2Uy8HcDMu2rrKoPmr',
            benefits: [
                'Multi user access - up to 5 seats',
                'View all information handouts',
                'View all anatomy diagrams',
                'Unlimited digital sharing',
                'Ability to bundle handouts',
                'Custom branding on handouts',
                'Interactive 3D models',
                'Premium customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'SDs2NJ',
        },
    },
    physio: {
        Student: {
            accountType: 'Student',
            productId: isProd ? 'prod_QzePKP8z4oBihu' : 'prod_QzdOB5hKnkniqg',
            priceMonthlyId: isProd ? 'price_1Q7f6eL2Uy8HcDMusDBBFH2e' : 'price_1Q7eCqL2Uy8HcDMu18bSgg6S',
            priceMonthly: 9.98,
            priceAnnually: 99.8,
            priceAnnuallyId: isProd ? 'price_1Q7f6eL2Uy8HcDMu8LzsxymC' : 'price_1Q7e7sL2Uy8HcDMukBGBDCKN',
            benefits: [
                'Single user access',
                'View all information handouts',
                'View all anatomy diagrams',
                'Basic customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'RMpw83',
        },
        Professional: {
            accountType: 'Professional',
            productId: isProd ? 'prod_QzePOrvA7KzYi1' : 'prod_QzdKp7VSGHf2ps',
            priceMonthlyId: isProd ? 'price_1Q7f6gL2Uy8HcDMuhR015god' : 'price_1Q7e7GL2Uy8HcDMuJdapLq5t',
            priceMonthly: 14.98,
            priceAnnually: 149.8,
            priceAnnuallyId: isProd ? 'price_1Q7f6gL2Uy8HcDMubsFC4ZtR' : 'price_1Q7e4EL2Uy8HcDMusEvacBsg',
            benefits: [
                'Single user access',
                'View all information handouts',
                'View all anatomy diagrams',
                'Unlimited digital sharing',
                'Basic customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'RsheG7',
        },
        'Professional - 14 day FREE trial': {
            accountType: 'Professional',
            productId: 'trial',
            priceMonthlyId: 'trial',
            priceMonthly: 0,
            priceAnnually: 0,
            priceAnnuallyId: 'trial',
            benefits: [
                'Single user access',
                'View all information handouts',
                'View all anatomy diagrams',
                'Unlimited digital sharing',
                'Basic customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'XJsU8z',
        },
        Clinic: {
            accountType: 'Clinic',
            productId: isProd ? 'prod_QzePeFEV48e8ue' : 'prod_QzdGnx6z4XIdKu',
            priceMonthlyId: isProd ? 'price_1Q7f6jL2Uy8HcDMuDQKxrsRC' : 'price_1Q7e0BL2Uy8HcDMukWGWrmgU',
            priceMonthly: 65.98,
            priceAnnually: 659.8,
            priceAnnuallyId: isProd ? 'price_1Q7f6jL2Uy8HcDMu9LzVANbJ' : 'price_1Q7dzhL2Uy8HcDMul4WkN77G',
            benefits: [
                'Multi user access - up to 5 seats',
                'View all information handouts',
                'View all anatomy diagrams',
                'Unlimited digital sharing',
                'Ability to bundle handouts',
                'Custom branding on handouts',
                'Interactive 3D models*',
                'Premium customer support',
            ],
            couponId: isProd ? 'LdsUb5Xv' : 'bD3OzeZP',
            klaviyoListId: 'R2xGUM',
        },
    },
};

module.exports = pricingDetails[clinicType];
