import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

export function CenteredBox({ children, sx }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={"100%"}
      maxWidth={"800px"}
      gap={4}
      sx={{...sx}}
    >
      {children}
    </Box>
  );
}
