import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PinkGradientButton from './PinkGradientButton';

const ChangePlanModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="change-plan-dialog" PaperProps={{
        className:'dialog-container',
        sx: {
          p: 4, 
          maxWidth: '711px',
          borderRadius: 4
        },
      }}>
      <DialogTitle variant="h4" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', p: 0, fontWeight: 600 }}>
          Are you sure you want to change your plan?
        <IconButton onClick={onClose} sx={{ color: '#0b0c26' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p:0, mt: 3, mb: 4 }}>
        <Typography variant="body1" sx={{ color: '#616063' }}>
          Please confirm that you want to proceed with updating your subscription plan. <br/>Please note immediate payment will occur using the billing details you provided before.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', p: 0, alignItems: { xs: 'stretch', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
        <PinkGradientButton
          onClick={onConfirm}
          variant="contained"
          sx={{m:0}}
        >
          Yes, update
        </PinkGradientButton>
        <Button
          onClick={onClose}
          className='black-btn'
          sx={{
            color: '#0b0c26',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 600,
            justifyContent: 'flex-start'
          }}
        >
          No, go back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePlanModal;
