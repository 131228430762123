import React, { useEffect } from 'react';

// Dynamically load the Sketchfab script when the component is mounted
const loadSketchfabScript = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://static.sketchfab.com/api/sketchfab-viewer-1.12.1.js';
        script.onload = resolve;
        document.body.appendChild(script);
    });
};

//viewer
const Model3DViewer = ({ uid, alt, author, authorWeb }) => {
    useEffect(() => {
        let sketchfabClient;

        const initSketchfabViewer = async () => {
            await loadSketchfabScript(); // Load Sketchfab script
            const iframe = document.getElementById(`api-frame-${uid}`);
            sketchfabClient = new window.Sketchfab(iframe);

            sketchfabClient.init(uid, {
                success: function (api) {
                    api.start();
                    api.addEventListener('viewerready', function () {
                        console.log('Viewer is ready');
                    });
                },
                error: function () {
                    console.log('Viewer error');
                },
                autostart: 1,
                autospin: 1,
                ui_general_controls: 0, // Disable general controls for now (all the icons I did not want to have there) - depends on creator config
            });
        };

        initSketchfabViewer();

        return () => {
            // Clean up the Sketchfab client when the component is unmounted
            if (sketchfabClient) {
                sketchfabClient = null;
            }
        };
    }, [uid]);

    return (
        <div style={{ width: '100%' }}>
            <div className="iframe-container" style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                <iframe
                    id={`api-frame-${uid}`}
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                    title={alt}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                ></iframe>
            </div>

            {author && (
                <div style={{ marginTop: '16px', textAlign: 'center' }}>
                    <p style={{ fontSize: '14px', color: '#555' }}>
                        Model created by{' '}
                        {authorWeb ? (
                            <a
                                href={`${authorWeb}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#0073e6', textDecoration: 'underline' }}
                            >
                                {author}
                            </a>
                        ) : (
                            author
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};

export default Model3DViewer;
